
<form [formGroup]="contactForm" class="emergency-contact-info" (ngSubmit)="onSubmit()">
    <mat-form-field class="phone-number">
      <mat-label>My (This) Phone Number</mat-label>
      <input type="tel" matInput formControlName="myPhoneNumber" placeholder="+15555551234">
      <mat-icon matSuffix>mode_edit</mat-icon>
    </mat-form-field>

    <mat-form-field class="phone-number">
      <mat-label>My Emergency Contact</mat-label>
      <input type="tel" matInput formControlName="myEmergencyContact" placeholder="+15555551234">
      <mat-icon matSuffix>mode_edit</mat-icon>
    </mat-form-field>

    <button type="submit" color="primary" mat-raised-button [disabled]="!contactForm.valid">Submit</button>
</form>