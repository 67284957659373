import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResourceDialogData } from '@app/resource-dialog-data';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-ssh-cred-dialog',
  templateUrl: './ssh-cred-dialog.component.html',
  styleUrls: ['./ssh-cred-dialog.component.scss'],
})
export class SshCredDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  private username: string;
  private password: string;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SshCredDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResourceDialogData
  ) {}

  public ngOnInit(): void {
    if (this.data.dialogType == 'cred') {
      this.form = this.fb.group({
        username: this.username,
        password: this.password,
      });
    }
  }

  public save() {
    if (
      this.form.get('username')?.value &&
      this.form.get('password')?.value &&
      this.form.get('username').value.trim().length > 0 &&
      this.form.get('password').value.trim().length > 0
    ) {
      this.dialogRef.close(this.form.value);
    }
  }

  public reconnect() {
    this.dialogRef.close('reconnect');
  }

  public closeDialog() {
    this.dialogRef.close('cancelled');
  }

  public handleEnterKeyUp(e) {
    if (e.keyCode === 13) {
      this.save();
    }
  }
}
