<h2 mat-dialog-title>Settings</h2>
<app-loading-data *ngIf="!userPreferencesForm"></app-loading-data>

<div *ngIf="!!userPreferencesForm" class="dialog-container">
  <div mat-dialog-content class="main-content">
    <form class="user-preferences-options-form" [formGroup]="userPreferencesForm">
      <mat-checkbox
        formControlName="vncTab"
        aria-label="Checkbox that toggles whether to open VNC Desktops in a new tab"
      >
        Open VNC desktop in new tab
      </mat-checkbox>
    </form>
  </div>

  <div mat-dialog-actions align="end">
    <button
      type="button"
      mat-raised-button
      color="warn"
      [mat-dialog-close]="undefined"
    >
    CANCEL
    </button>
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="onSubmit()"
      [disabled]="!userPreferencesForm.dirty"
    >
    SAVE
    </button>
  </div>
</div>
