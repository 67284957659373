export function convertFragmentToChallenge(fragment: string): string {
  let b64Challenge: string | undefined = undefined;
  if (fragment.indexOf('challenge_b64') >= 0) {
    const asParam = new URLSearchParams(fragment);
    b64Challenge = asParam.get('challenge_b64');
  } else {
    b64Challenge = fragment;
  }
  return atob(b64Challenge);
}
