<div *ngIf="state$ | async as state; else loading">
    <div *ngIf="state.error; else main">
        {{state.error.message}}
    <mat-expansion-panel *ngIf="state.error.details as details">
        <mat-expansion-panel-header>
            <mat-panel-title>
            Error Details
            </mat-panel-title>
            <mat-panel-description>
            Click to view error details
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p>{{ details }}</p>
    </mat-expansion-panel>
    </div>

    <ng-template #main>
        <div *ngIf="state.challengeSuccess">Challenge succeeded! </div>
        <div *ngIf="!state.challengeSuccess">Challenge failed! </div>
    </ng-template>    
</div>
<ng-template #loading>
    <div>Answering challenge... </div>
</ng-template>    
