import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { retryWhen, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { genericRetryStrategy } from '@app/rxjs-utils';
import { getIgnoreErrorsKey } from './http-interceptor-utils';
import { MatDialog } from '@angular/material/dialog';
import { createDialogData } from '@app/dialog-utils';
import { ConfirmationDialogComponent } from '@app/confirmation-dialog/confirmation-dialog.component';

/** Passes HttpErrorResponse to application-wide error handler */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private errorDialogIsOpen = false;

  constructor(private injector: Injector, private errorDialog: MatDialog) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignoreErrors = request.headers.get(getIgnoreErrorsKey());
    let updatedRequest = request;
    if (!!ignoreErrors) {
      // We need to remove the header to avoid CORS errors.
      updatedRequest = request.clone({ headers: request.headers.delete(getIgnoreErrorsKey()) });
    }
    return next.handle(updatedRequest).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse && error.status === 409 && request.method === 'POST') {
          // For a POST, we will return the existing object and handle accordingly.
          // Therefore, this error message does not need to be displayed to the user.
          return throwError(() => error);
        }
        if (error instanceof HttpErrorResponse && (error.status === 504 || error.status === 0)) {
          if (!this.errorDialogIsOpen) {
            const message =
              'Your browser encountered a problem communicating with Agilicus. Please check your network and firewall settings.';
            const showMoreErrorMessage = error.message;
            this.openNetworkErrorNotificationDialog(message, showMoreErrorMessage);
          }
          return throwError(() => error);
        }
        if (!!ignoreErrors) {
          // We do not want to notify the user of these errors.
          return throwError(() => error);
        }
        const appErrorHandler = this.injector.get(ErrorHandler);
        appErrorHandler.handleError(error);
        return throwError(() => error);
      })
    );
  }

  public openNetworkErrorNotificationDialog(message: string, showMoreErrorMessage: string): void {
    this.errorDialogIsOpen = true;
    const messagePrefix = `Network Error`;
    const dialogData = createDialogData(messagePrefix, message);
    const dialogRef = this.errorDialog.open(ConfirmationDialogComponent, {
      data: { ...dialogData, buttonText: { confirm: 'Close', cancel: '' }, showMoreText: showMoreErrorMessage },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.errorDialogIsOpen = false;
    });
  }
}
