import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@app/confirmation-dialog/confirmation-dialog.component';
import { createDialogData } from '@app/dialog-utils';
import { NotificationService } from '@app/notifications/notification.service';
import { getProductLinkViaLocalStorage } from '@app/product-guide-link/product-guide-link.utils';
import { environment } from '@env/environment';

const DEFAULT_ACTION_MSG = 'log out then log in again';

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler extends ErrorHandler {
  constructor(private notificationService: NotificationService, public errorDialog: MatDialog) {
    super();
  }

  private getConflictMessage(actionMsg: string = DEFAULT_ACTION_MSG): string {
    let message = 'There was a conflict when updating an object in the API. Somebody else likely modified it before you. ';
    if (actionMsg !== null) {
      message += `Please ${actionMsg} to refresh the data. `;
    }
    return message;
  }

  private postMessageHome(displayMessage: any, error: Error | HttpErrorResponse) {
    //
    // This posts errors back home.
    try {
      var stack = '';
      if (error instanceof HttpErrorResponse) {
        stack = error.error.stack;
      } else {
        stack = error.stack;
      }
      var org_id = undefined;
      try {
        org_id = window.localStorage.org_id;
      } catch (e) {}
      const url = '/log/';
      const data = {
        org_id: org_id,
        user: window.localStorage.email,
        version: environment.versions.app,
        origin: window.origin,
        severity: 'error',
        agent: 'profile',
        msg: displayMessage,
        error: {
          message: error.message,
          name: error.name,
          stack: stack,
        },
      };
      const config = {
        method: 'POST',
        mode: 'no-cors' as RequestMode,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };
      fetch(url, config);
    } catch (er) {}
  }

  public handleError(error: Error | HttpErrorResponse): void {
    let displayMessage = 'An error occurred: ' + error.message;

    if (!environment.production) {
      displayMessage += '. See console for details.';
    }

    if (error instanceof HttpErrorResponse && error.status === 409) {
      displayMessage = this.getConflictMessage();
    } else if (error.message === 'Request failed with status code 403') {
      displayMessage = 'You do not have sufficient permissions to view this page';
    }

    this.postMessageHome(displayMessage, error);

    if (
      displayMessage.includes(
        'iat is in the future' || 'iat was not provided' || 'nbf is in the future' || 'exp was not provided' || 'exp is in the past'
      )
    ) {
      const messagePrefix = 'Failed To Login';
      const message = `ERROR: Your local machine time is not properly setup or synced. This will cause incorrect behaviour in encryption and sign-in. Please enable your time sync service before continuing. You can check your browser time against real time at <a href="https://time.is/" target="_blank">https://time.is/</a>. For more information, see <a href="${getProductLinkViaLocalStorage(
        'https://www.agilicus.com/product-guide/time-synchronisation/'
      )}" target="_blank">${getProductLinkViaLocalStorage('https://www.agilicus.com/product-guide/time-synchronisation/')}</a>.`;
      const dialogData = createDialogData(messagePrefix, message);
      dialogData.informationDialog = true;
      dialogData.buttonText = { confirm: '', cancel: 'Close' };
      this.errorDialog.open(ConfirmationDialogComponent, {
        data: dialogData,
      });
    } else {
      this.notificationService.error(displayMessage);
      super.handleError(error);
    }
  }
}
