import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-launcher-download-button',
  templateUrl: './launcher-download-button.component.html',
  styleUrls: ['./launcher-download-button.component.scss'],
})
export class LauncherDownloadButtonComponent {
  @Input() public disable = false;
  @Input() public descriptiveText = 'Download the Agilicus launcher:';
  @Input() public disabledTooltipText = '';
  @Output() private downloadFile = new EventEmitter<any>();

  constructor() {}

  public onDownloadFile(): void {
    this.downloadFile.emit();
  }
}
