<div
  class="page-info-container"
  [ngClass]="{
    'standard-width': pageInfoWidth === pageInfoWidthEnum.standard,
    'full-width': pageInfoWidth === pageInfoWidthEnum.full
  }"
>
  <div *ngIf="!!pageTitle" class="title-container">
    <H1 *ngIf="titleSize === titleSizeEnum.standard">{{ pageTitle }}</H1>
    <H4 *ngIf="titleSize === titleSizeEnum.small">{{ pageTitle }}</H4>
  </div>
  
  <div class="descriptive-text-container">
    <p style="white-space: pre-line;" *ngIf="!!pageDescriptiveTextWithImageWrap">
      <img *ngIf="!!pageDescriptiveHelpImageWithTextWrap"
        class="bordered-img margined-img"
        [src]="pageDescriptiveHelpImageWithTextWrap"
        height=300
      >
      {{pageDescriptiveTextWithImageWrap}}
    </p>
    <p *ngIf="!!pageDescriptiveText">{{ pageDescriptiveText }}</p>
    <ul *ngIf="!!pageSubDescriptiveList && pageSubDescriptiveList.length !== 0">
      <li *ngFor="let item of pageSubDescriptiveList">{{ item }}</li>
    </ul>

    <app-product-guide-link
      [productGuidePreText]="productGuidePreText"
      [productGuideLink]="productGuideLink"
      [productGuideLinkText]="productGuideLinkText"
      [productGuidePostText]="productGuidePostText"
    >
    </app-product-guide-link>
  </div>

  <div *ngIf="!!helpImageFilePath" class="instruction-help-img">
    <img
      class="bordered-img"
      [src]="helpImageFilePath"
      height=400
    >
  </div>
</div>
