import {
  CreateUserMetadataRequestParams,
  ListUserMetadataRequestParams,
  ReplaceUserMetadataRequestParams,
  UserMetadata,
  UserMetadataSpec,
  UsersService,
} from '@agilicus/angular';
import { map, Observable } from 'rxjs';

export interface PreferenceData {
  openVncInNewTab?: boolean;
}

export interface ProfileData {
  preferences?: PreferenceData;
}

export function getUserMetadata$(
  users: UsersService,
  userId: string,
  orgId: string,
  options: ListUserMetadataRequestParams = {}
): Observable<Array<UserMetadata>> {
  const requestParameters: ListUserMetadataRequestParams = {
    user_id: userId,
    org_id: orgId,
    data_type: 'json',
    ...options,
  };
  return users.listUserMetadata(requestParameters).pipe(map((resp) => resp.user_metadata));
}

export function createUserMetadata$(usersService: UsersService, userMetadata: UserMetadata): Observable<UserMetadata> {
  const createUserMetadataRequestParams: CreateUserMetadataRequestParams = {
    UserMetadata: userMetadata,
  };
  return usersService.createUserMetadata(createUserMetadataRequestParams);
}

export function updateUserMetadata$(
  usersService: UsersService,
  metadataId: string,
  updatedUserMetadata: UserMetadata
): Observable<UserMetadata> {
  const replaceUserMetadataRequestParams: ReplaceUserMetadataRequestParams = {
    metadata_id: metadataId,
    UserMetadata: updatedUserMetadata,
  };
  return usersService.replaceUserMetadata(replaceUserMetadataRequestParams);
}

export function getProfileAppId(): string {
  return 'urn-agilicus-builtin-app-profile';
}

export function getUserPreferencesSessionStorageKey(): string {
  return 'userPreferences';
}

export function getNewDefaultUserProfileUserMetadata(userId: string, orgId: string): UserMetadata {
  return {
    spec: getNewDefaultUserProfileUserMetadataSpec(userId, orgId),
  };
}

export function getNewDefaultUserProfileUserMetadataSpec(userId: string, orgId: string): UserMetadataSpec {
  const profileData: ProfileData = {
    preferences: {
      openVncInNewTab: false,
    },
  };
  return {
    user_id: userId,
    org_id: orgId,
    app_id: getProfileAppId(),
    data_type: UserMetadataSpec.DataTypeEnum.json,
    data: JSON.stringify(profileData),
  };
}

export function getUserPreferenceState(): PreferenceData | undefined {
  const userPreferenceState = sessionStorage.getItem(getUserPreferencesSessionStorageKey());
  return !!userPreferenceState ? JSON.parse(userPreferenceState) : undefined;
}

export function setUserPreferenceState(userProfileMetaDataResp: Array<UserMetadata>): void {
  const profileDataAsString = userProfileMetaDataResp[0].spec.data;
  const profileData: ProfileData = JSON.parse(profileDataAsString);
  sessionStorage.setItem(getUserPreferencesSessionStorageKey(), JSON.stringify(profileData.preferences));
}

export function getUserPreferencesFromMetadata(userProfileMetaDataResp: Array<UserMetadata>): PreferenceData | undefined {
  if (!userProfileMetaDataResp || userProfileMetaDataResp.length === 0) {
    return undefined;
  }
  const profileDataAsString = userProfileMetaDataResp[0].spec.data;
  const profileData: ProfileData = JSON.parse(profileDataAsString);
  return !!profileData?.preferences ? profileData.preferences : undefined;
}
