import { UserMetadata, UsersService } from '@agilicus/angular';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationService } from '@app/notifications/notification.service';
import { Subject, takeUntil } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  createUserMetadata$,
  getNewDefaultUserProfileUserMetadata,
  getProfileAppId,
  getUserMetadata$,
  getUserPreferencesFromMetadata,
  ProfileData,
  setUserPreferenceState,
  updateUserMetadata$,
} from '@app/utils/user-metadata.utils';

export interface UserPreferencesDialogData {
  userId: string;
  orgId: string;
}
@Component({
  selector: 'app-user-preferences-dialog',
  templateUrl: './user-preferences-dialog.component.html',
  styleUrls: ['./user-preferences-dialog.component.scss'],
})
export class UserPreferencesDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private userId: string;
  private orgId: string;
  public userPreferencesForm: FormGroup;
  private userMetadata: UserMetadata;

  constructor(
    private usersService: UsersService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) data: UserPreferencesDialogData,
    private dialogRef: MatDialogRef<UserPreferencesDialogComponent>,
    private fb: FormBuilder
  ) {
    this.userId = data.userId;
    this.orgId = data.orgId;
  }

  public ngOnInit(): void {
    const userProfileMetaData$ = getUserMetadata$(this.usersService, this.userId, this.orgId, { app_id: getProfileAppId() });
    userProfileMetaData$.pipe(takeUntil(this.unsubscribe$)).subscribe((userProfileMetaDataResp) => {
      this.userMetadata =
        userProfileMetaDataResp.length === 0 ? getNewDefaultUserProfileUserMetadata(this.userId, this.orgId) : userProfileMetaDataResp[0];
      this.initializeForm(userProfileMetaDataResp);
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public initializeForm(userProfileMetaData: Array<UserMetadata>): void {
    const userPreferences = getUserPreferencesFromMetadata(userProfileMetaData);
    this.userPreferencesForm = this.fb.group({
      vncTab: [!!userPreferences?.openVncInNewTab],
    });
  }

  private updateUserMetadataFromForm(): void {
    const vncTabFormValue = this.userPreferencesForm.get('vncTab').value;
    const userMetadataAsJson: ProfileData = JSON.parse(this.userMetadata.spec.data);
    if (!userMetadataAsJson.preferences) {
      userMetadataAsJson.preferences = {
        openVncInNewTab: vncTabFormValue,
      };
    } else {
      userMetadataAsJson.preferences.openVncInNewTab = vncTabFormValue;
    }
    this.userMetadata.spec.data = JSON.stringify(userMetadataAsJson);
  }

  private saveData(): void {
    if (!!this.userMetadata.metadata) {
      updateUserMetadata$(this.usersService, this.userMetadata.metadata.id, this.userMetadata)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            setUserPreferenceState([res]);
            this.notificationService.success('User preferences successfully updated');
            this.closeDialog();
          },
          (err) => {
            this.notificationService.warn('ERROR: Unable to update user preferences');
          }
        );
    } else {
      createUserMetadata$(this.usersService, this.userMetadata)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            setUserPreferenceState([res]);
            this.notificationService.success('User preferences successfully updated');
            this.closeDialog();
          },
          (err) => {
            this.notificationService.warn('ERROR: Unable to update user preferences');
          }
        );
    }
  }

  public onSubmit(): void {
    this.updateUserMetadataFromForm();
    this.saveData();
  }

  private closeDialog(): void {
    this.dialogRef.close();
  }
}
