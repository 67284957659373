import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable, catchError, concatMap, of, throwError } from 'rxjs';
import { ResourceAccess } from './resource-access';
import { ResourceDialogData } from './resource-dialog-data';
import { WebSshComponent } from './web-ssh/web-ssh.component';
import { getDefaultDialogConfig } from './dialog-utils';
import { MatDialog } from '@angular/material/dialog';
import { CredData, SSHResourceInfo } from './services/ssh-info/ssh-info.service';
import { SessionChallenge, TokensService, UserSSHAccessInfo } from '@agilicus/angular';
import { MFAChallengeDialogData, MfaChallengeDialogComponent } from './mfa-challenge-dialog/mfa-challenge-dialog.component';
import { getIgnoreErrorsKey, getIgnoreErrorsValue } from './http-interceptors/http-interceptor-utils';

export function getPath(id: string, apiKey: string, gateway_uri: string, username: string): string {
  const socketGateWayPath = gateway_uri.replace('https', 'wss');
  const path = socketGateWayPath + 'ws?id=' + id + '&api_key=' + apiKey + '&username=' + username;
  return path;
}

export function updateSshResourceAccess(resourceAccess: ResourceAccess, resp: UserSSHAccessInfo): ResourceAccess {
  const updatedResourceAccess = { ...resourceAccess };

  if (!!resp.status.gateway_uri) {
    updatedResourceAccess.gateway_uri = resp.status.gateway_uri;
  }

  if (!!resp.status.resource_uri) {
    updatedResourceAccess.resource_url = resp.status.resource_uri;
  }

  return updatedResourceAccess;
}

export interface AuthResult {
  id?: string;
  status?: string;
}

function createSessionChallenge$(
  credential: string,
  username: string,
  tokensService: TokensService,
  authType?: string
): Observable<SessionChallenge> {
  const headers = generateAuthHeaders(username, credential, authType);
  return tokensService.createSessionChallenge({ SessionChallenge: {} }, 'body', headers);
}

function updateSessionChallenge$(
  credential: string,
  username: string,
  tokensService: TokensService,
  authType?: string
): Observable<SessionChallenge> {
  const headers = generateAuthHeaders(username, credential, authType);
  return tokensService.updateSessionChallenge({ SessionChallenge: {} }, 'body', headers);
}

function generateAuthHeaders(username: string, credential: string, authType?: string): HttpHeaders {
  let authorizationValue;

  if (authType === 'bearer') {
    authorizationValue = 'Bearer ' + credential;
  } else {
    authorizationValue = 'Basic ' + btoa(username + ':' + credential);
  }
  return new HttpHeaders({
    Authorization: authorizationValue,
  });
}

export function performMFA(
  credential: string,
  username: string,
  tokensService: TokensService,
  challengeDialog: MatDialog,
  authType?: string
): Observable<SessionChallenge> {
  return createSessionChallenge$(credential, username, tokensService, authType).pipe(
    catchError((error) => {
      if (error.status === 409) {
        return of(error.error);
      } else {
        return throwError(() => error);
      }
    }),
    concatMap((value: SessionChallenge) => {
      const data: MFAChallengeDialogData = {
        challenge: value.status.challenge,
        webauthnEnrollmentList: value.status.webauthn_enrollments,
        challengeDescription: value.status.description,
      };
      const dialogRef = challengeDialog.open(
        MfaChallengeDialogComponent,
        getDefaultDialogConfig({
          data,
          disableClose: false,
          panelClass: 'mfa-dialog',
        })
      );

      return dialogRef.afterClosed().pipe(
        concatMap((status: string) => {
          if (status === 'success') {
            return updateSessionChallenge$(credential, username, tokensService, authType);
          } else {
            return EMPTY;
          }
        })
      );
    })
  );
}

export function authenticate$(
  apiKeyResp: string,
  resourceInfo: SSHResourceInfo,
  username: string,
  credData: CredData,
  http: HttpClient
): Observable<AuthResult> {
  const formData: FormData = new FormData();

  const httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(username + ':' + apiKeyResp),
    }),
  };

  const modifiedHeaders = httpOptions.headers.append(getIgnoreErrorsKey(), getIgnoreErrorsValue());
  httpOptions.headers = modifiedHeaders;

  formData.append('wscat_uri', resourceInfo.resource_url);
  formData.append('wscat_user', username);
  formData.append('wscat_apikey', apiKeyResp);
  if (credData.provideCredentials?.username) {
    formData.append('username', credData.provideCredentials.username);
  }
  if (credData.provideCredentials?.password) {
    formData.append('password', credData.provideCredentials.password);
  }
  if (credData.shouldStuff) {
    formData.append('stuff_auth', 'true');
  }
  formData.append('hostname', resourceInfo.name);
  formData.append('port', '');
  formData.append('resource_id', resourceInfo.resourceId);
  formData.append('org_id', resourceInfo.orgId);

  if (resourceInfo.gateway_uri === undefined) {
    resourceInfo.gateway_uri = 'https://ssh-gateway.ca-1.agilicus.ca/';
  }

  return http.post(resourceInfo.gateway_uri, formData, httpOptions);
}

export function openWebSsh(resource: ResourceAccess, dialog: MatDialog): void {
  const data: ResourceDialogData = {
    resourceInfo: resource,
  };
  dialog.open(
    WebSshComponent,
    getDefaultDialogConfig({
      maxWidth: 'unset',
      height: '98%',
      width: '100vw',
      data,
      panelClass: 'vnc-dialog',
    })
  );
}
export const baseTheme = {
  foreground: '#F8F8F8',
  background: '#2D2E2C',
  selection: '#5DA5D533',
  black: '#1E1E1D',
  brightBlack: '#262625',
  red: '#CE5C5C',
  brightRed: '#FF7272',
  green: '#5BCC5B',
  brightGreen: '#72FF72',
  yellow: '#CCCC5B',
  brightYellow: '#FFFF72',
  blue: '#5D5DD3',
  brightBlue: '#7279FF',
  magenta: '#BC5ED1',
  brightMagenta: '#E572FF',
  cyan: '#5DA5D5',
  brightCyan: '#72F0FF',
  white: '#F8F8F8',
  brightWhite: '#FFFFFF',
  border: '#85858a',
};
