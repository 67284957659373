import { TokensService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../notifications/notification.service';
import { URLGenerator } from '@app/url-generator';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { performMFA } from '@app/web-ssh-utils';
import { AuthService } from '../auth-service/auth-service.service';
import { getIgnoreErrorsKey, getIgnoreErrorsValue } from '@app/http-interceptors/http-interceptor-utils';

@Injectable({
  providedIn: 'root',
})
export class VncMfaService {
  constructor(
    private tokensService: TokensService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private http: HttpClient,
    private challengeDialog: MatDialog
  ) {}

  async checkMFA(urlGenerator: URLGenerator, UI: any, disconnectVNC?: () => void): Promise<void> {
    let url = await urlGenerator.getURLWithValidToken();
    url = url.replace('wss://', 'https://');

    let headers = new HttpHeaders();
    headers = headers.append(getIgnoreErrorsKey(), getIgnoreErrorsValue());

    this.http.get(url, { headers }).subscribe(
      (data) => {
        UI.start();
      },
      (error) => {
        if (error && error.status === 401 && error.headers.get('x-challenge-required') === 'true') {
          performMFA(urlGenerator.currentToken, this.authService.getAuth().email(), this.tokensService, this.challengeDialog).subscribe(
            (result) => {
              if (result !== undefined) {
                UI.start();
              }
            },
            (mfaError) => {
              this.notificationService.error(`MFA process failed: ${mfaError}`);
              if (disconnectVNC) {
                disconnectVNC();
              }
            }
          );
        } else {
          UI.start();
        }
      }
    );
  }
}
