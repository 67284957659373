<mat-card class="inbox-card">
  <mat-card-header>
    <div
      *ngIf="inboxItem.status.message.icon"
      style="background-image: url(inboxItem.status.message.icon);"
      mat-card-avatar
      class="inbox-header-image"
    >
    </div>
    <mat-card-title>{{inboxItem.status.message.title}}</mat-card-title>
    <mat-card-subtitle>{{inboxItem.status.message.sub_header}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>
      {{inboxItem.status.message.text}}
    </p>

    <a
      *ngIf="inboxItem?.status?.message?.uri"
      class="inbox-message-url" 
      [href]="inboxItem?.status?.message?.uri"
      (click)="openMessageUri($event)">
        go to event
    </a>
  </mat-card-content>
  <img
    *ngIf="inboxItem?.status?.message?.image" mat-card-image src="{{inboxItem.status.message.image}}">
  <mat-card-actions>
    <div *ngIf="showCustomActionButtons(inboxItem)">
      <button
        *ngFor="let action of inboxItem.status.message.actions"
        type="button"
        mat-raised-button
        color="primary"
        (click)="fetchActionUri(action)"
        mat-button
      >
        {{action.title}}
      </button>
    </div>
    
    <div *ngIf="!showCustomActionButtons(inboxItem)">
      <button mat-raised-button (click)="onCloseClick()">CLOSE</button>
    </div>
  </mat-card-actions>
</mat-card>