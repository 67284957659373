import { TokensService, UserDesktopAccessInfoStatus } from '@agilicus/angular';
import { getFutureDateFromSeconds, subtractSecondsFromDate } from './date-utils';
import { getApiKey } from './utils';
import { AuthService } from './services/auth-service/auth-service.service';

export class URLGenerator {
  currentToken: string;
  tokenExpiry?: Date;
  desktopInfo: UserDesktopAccessInfoStatus; // Define the DesktopInfo type
  tokensService: TokensService;
  authService: AuthService;
  expiryTimeSeconds = 600;
  expiryThresholdSeconds: number = 60;

  constructor(desktopInfo: UserDesktopAccessInfoStatus, tokensService: TokensService, authService: AuthService) {
    this.desktopInfo = desktopInfo;
    this.tokensService = tokensService;
    this.authService = authService;
  }

  public async getURLWithValidToken(): Promise<string> {
    if (!this.tokenValidWithinTheshold()) {
      const expiry = getFutureDateFromSeconds(this.expiryTimeSeconds);
      this.tokenExpiry = expiry;
      this.currentToken = await getApiKey({
        userId: this.desktopInfo.user_id,
        orgId: this.desktopInfo.org_id,
        resourceId: this.desktopInfo.resource_id,
        resourceName: this.desktopInfo.resource_name,
        resourceType: this.desktopInfo.resource_type,
        tokensService: this.tokensService,
        expiry: expiry,
      }).toPromise();
    }
    return this.makeURLWithToken(this.currentToken);
  }

  private tokenValidWithinTheshold(): boolean {
    if (!this.tokenExpiry) {
      return false;
    }
    const currentTime = Date.now();
    const tokenExpiryTime = subtractSecondsFromDate(this.expiryThresholdSeconds, this.tokenExpiry).getTime();
    return currentTime < tokenExpiryTime;
  }

  public makeURLWithToken(apiKeyResp: string): string {
    const auth = this.authService.getAuth();
    const path = this.desktopInfo.resource_uri + '?api_key=' + apiKeyResp + '&username=' + auth.email();
    return path;
  }
}
