import { ApiModule, Configuration, ApiConfiguration, apiConfigFactory } from '@agilicus/angular';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders, Injectable, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';
import { AppInitService } from './app.init';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { PromptComponent } from './prompt/prompt.component';
import { PwaService } from './services/pwa.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MfaEnrollComponent } from './mfa-enroll/mfa-enroll.component';
import { AppRoutingModule } from './app-routing.module';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { GravatarModule } from 'ngx-gravatar';
import { NotificationService } from './notifications/notification.service';
import { AppLauncherComponent } from './app-launcher/app-launcher.component';
import { HammerModule } from '@angular/platform-browser';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { AppIconComponent } from './app-icon/app-icon.component';
import { AppRequestDialogComponent } from './app-request-dialog/app-request-dialog.component';
import { SharedModule } from './shared/shared.module';
import { AppIconFolderComponent } from './app-icon-folder/app-icon-folder.component';
import { AppIconFolderStructureComponent } from './app-icon-folder-structure/app-icon-folder-structure.component';
import { ResourceLauncherComponent } from './resource-launcher/resource-launcher.component';
import { ShareMountInstructionsDialogComponent } from './share-mount-instructions-dialog/share-mount-instructions-dialog.component';
import { ClipboardModule } from 'ngx-clipboard';
import { FileManangerComponent } from './file-mananger/file-mananger.component';
import { SubmenuComponent } from './submenu/submenu/submenu.component';
import { NgxFilemanagerClientModule } from '@agilicus/ngx-filemanager-client';
import { InternalApplicationDialogComponent } from './internal-application-dialog/internal-application-dialog.component';
import { EmergencyContactInfoComponent } from './emergency-contact-info/emergency-contact-info.component';
import { WA_POSITION_OPTIONS } from '@ng-web-apis/geolocation';
import { DesktopConfigDownloadDialogComponent } from './desktop-config-download-dialog/desktop-config-download-dialog.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { LauncherInstallComponent } from './launcher-install/launcher-install.component';
import { VncDesktopDialogComponent } from './vnc-desktop-dialog/vnc-desktop-dialog.component';
import { VncDesktopComponent } from './vnc-desktop/vnc-desktop.component';
import { AuthzErrorService } from './services/interceptors/authz-interceptor.service';
import { NgTerminalModule } from 'ng-terminal';
import { WebSshComponent } from './web-ssh/web-ssh.component';
import { WebSshTabViewComponent } from './web-ssh-tab-view/web-ssh-tab-view.component';
import { SshCredDialogComponent } from './ssh-cred-dialog/ssh-cred-dialog.component';
import { ProgressSpinnerDialogComponent } from './progress-spinner-dialog/progress-spinner-dialog.component';
import { RdpOptionsDialogComponent } from './rdp-options-dialog/rdp-options-dialog.component';
import { ExtensionMessengerService, initializeExtensionMessengerService } from '@agilicus/extension-messenger';
import { LauncherInstructionsDialogComponent } from './launcher-instructions-dialog/launcher-instructions-dialog.component';
import { environment } from '@env/environment';
import { LauncherDownloadButtonComponent } from './launcher-download-button/launcher-download-button.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { InboxMessageDialogComponent } from './inbox-message-dialog/inbox-message-dialog.component';
import { AppLabelFolderComponent } from './app-label-folder/app-label-folder.component';
import { httpInterceptorProviders } from './http-interceptors';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { PageInfoComponent } from './page-info/page-info.component';
import { ProductGuideLinkComponent } from './product-guide-link/product-guide-link.component';
import { AppErrorHandler } from './error-handler/app-error-handler.service';
import { MfaChallengeModule } from './mfa-challenge/mfa-challenge.module';
import { WebPushMfaChallengeComponent } from './webpush-mfa-challenge/webpush-mfa-challenge.component';
import { CheckImageDirective } from './check-image.directive';
import { UserPreferencesDialogComponent } from './user-preferences-dialog/user-preferences-dialog.component';
import { LoadingDataComponent } from './loading-data/loading-data.component';

const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 750,
  hideDelay: 0,
  touchendHideDelay: 1500,
};

export function envOverrideConfigFactory(env: AppInitService): ApiConfiguration {
  const baseConfig = apiConfigFactory();
  // This comes from the uri, so it's safe to do right away
  const token = env.overrideToken;
  if (token) {
    baseConfig.accessToken = token;
    baseConfig.credentials = {
      Bearer: token,
    };
  }
  return new ApiConfiguration(baseConfig);
}

export function init_app(env: AppInitService, cfg: ApiConfiguration): () => Promise<void> {
  return async () => {
    await env.init();
    // Here we update the singleton api configuration with the newly fetched
    // api domain. We need to do this here, as opposed to depending on the dynamic service
    // in config factory because the app init is the only place to do async initializers, and
    // it does not fire until after all the dependencies have been resolved.
    // This relies on the configuration being passed as a pointer to the API services.
    const apiDomain = env.environment.apiDomain;
    if (apiDomain) {
      cfg.basePath = apiDomain;
    }
  };
}

const apiProvider: ModuleWithProviders<ApiModule> = {
  ngModule: ApiModule,
  providers: [
    {
      provide: Configuration,
      useFactory: envOverrideConfigFactory,
      deps: [AppInitService],
    },
  ],
};

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement): HammerManager {
    return new Hammer.Manager(element, {
      touchAction: 'auto',
      inputClass: Hammer.TouchInput,
      recognizers: [
        [
          Hammer.Swipe,
          {
            direction: Hammer.DIRECTION_HORIZONTAL,
          },
        ],
      ],
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    MfaEnrollComponent,
    LauncherInstallComponent,
    AppLauncherComponent,
    AppIconComponent,
    AppRequestDialogComponent,
    AppIconFolderComponent,
    AppIconFolderStructureComponent,
    ResourceLauncherComponent,
    ShareMountInstructionsDialogComponent,
    FileManangerComponent,
    SubmenuComponent,
    PromptComponent,
    InternalApplicationDialogComponent,
    EmergencyContactInfoComponent,
    DesktopConfigDownloadDialogComponent,
    UserInfoComponent,
    LauncherInstallComponent,
    VncDesktopDialogComponent,
    VncDesktopComponent,
    WebSshComponent,
    WebSshTabViewComponent,
    SshCredDialogComponent,
    ProgressSpinnerDialogComponent,
    RdpOptionsDialogComponent,
    LauncherInstructionsDialogComponent,
    LauncherDownloadButtonComponent,
    NotificationsComponent,
    InboxMessageDialogComponent,
    AppLabelFolderComponent,
    ConfirmationDialogComponent,
    PageInfoComponent,
    ProductGuideLinkComponent,
    WebPushMfaChallengeComponent,
    CheckImageDirective,
    UserPreferencesDialogComponent,
    LoadingDataComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    //    ServiceWorkerModule.register('service-worker.js', { enabled: environment.production }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000',
    }),
    FormsModule,
    MatToolbarModule,
    MatBottomSheetModule,
    MatSidenavModule,
    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    QRCodeModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatSnackBarModule,
    MatSelectModule,
    MatGridListModule,
    MatTabsModule,
    GravatarModule,
    AppRoutingModule,
    apiProvider,
    HammerModule,
    MatDialogModule,
    SharedModule,
    ClipboardModule,
    NgxFilemanagerClientModule,
    NgxJsonViewerModule,
    NgTerminalModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MfaChallengeModule,
    MatExpansionModule,
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService, Configuration],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [PwaService],
      multi: true,
    },
    {
      provide: WA_POSITION_OPTIONS,
      useValue: { enableHighAccuracy: true, timeout: 3000, maximumAge: 90000 },
    },
    NotificationService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      deps: [],
      useClass: MyHammerConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthzErrorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeExtensionMessengerService,
      deps: [ExtensionMessengerService],
      multi: true,
    },
    httpInterceptorProviders,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
