import { Component, OnInit, HostListener, ViewChild, OnDestroy, Optional, Inject } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { detect } from 'detect-browser';
import * as caniuse from 'caniuse-lite';

import {
  ChallengesService,
  MessagesService,
  UpdateMessageEndpointRequestParams,
  CreateChallengeMethodRequestParams,
  CreateTotpEnrollmentRequestParams,
  UpdateTotpEnrollmentRequestParams,
  UsersService,
  TOTPEnrollment,
  MFAChallengeMethod,
  CreateWebauthnEnrollmentRequestParams,
  UpdateWebauthnEnrollmentRequestParams,
  CreateChallengeRequestParams,
  GetAnswerRequestParams,
  Challenge,
  WebAuthNEnrollment,
  ListWebauthnEnrollmentsRequestParams,
  IssuersService,
  ListWellknownIssuerInfoRequestParams,
  ListWellKnownIssuerInfo,
  MessageEndpoint,
  Message,
  ChallengeAnswer,
  OrganisationsService,
} from '@agilicus/angular';
import { SwPush } from '@angular/service-worker';
import { MatAccordion } from '@angular/material/expansion';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subject, Observable, of, from, forkJoin, NEVER } from 'rxjs';
import { takeUntil, map, catchError, concatMap, filter } from 'rxjs/operators';
import { NotificationService } from '../notifications/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ChallengeParams,
  ChallengeResult,
  HttpWebauthnService,
  RegistrationParams,
  RegistrationResult,
} from '@app/services/http-webauthn/http-webauthn.service';
import { arrayBufferToStr, getOrgIdFromRouter, getStoredOrgId, stringToArrayBuffer, stringToArrayBufferU8 } from '@app/utils';
import { timeStamp } from 'console';

export interface DeviceDataElement {
  created: string;
  id: string;
  endpoint: string;
  enabled?: boolean;
  nickname?: string;
  origin?: string;
}

interface WebauthnRegistrationDetails {
  nickname: string;
  origin: string;
}

interface WebauthnRegistrationState {
  details: WebauthnRegistrationDetails;
  enrollment: WebAuthNEnrollment;
}

interface WebauthnChallengeState {
  details: WebauthnRegistrationDetails;
  challenge: Challenge;
}

interface AuthenticatorAttestationResponseType extends AuthenticatorAttestationResponse {
  getTransports(): Array<string>;
}

export enum ChallengeType {
  web_push = 'web_push',
  totp = 'totp',
  webauthn = 'webauthn',
  sms = 'sms',
}

export enum AuthenticatorType {
  AUTO = 'Auto (recommended)',
  USE_THIS_DEVICE = 'Use this device',
  USE_EXTERNAL_DEVICE = 'Use an external device',
  USE_EXTERNAL_HTTP = 'Use an external HTTP Server',
}
@Component({
  selector: 'app-mfa-enroll',
  templateUrl: './mfa-enroll.component.html',
  styleUrls: ['./mfa-enroll.component.scss'],
})
export class MfaEnrollComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public accessToken = '';
  public userId = '';
  public forceEnroll = false;
  private vapidPublicKey = '';
  public webPushEnabled: boolean;
  public webAuthNEnabled: boolean;
  public webAuthNDisabledReason = 'Error: this browser or platform does not support the webauthn standard';
  public webPushSubscribed: boolean;
  public smsEnabled = false;
  public registeredSecurityKeyEnabled = false;
  public subtoken = '';
  private redirectUri = '';
  public bannerImg = '/assets/img/mfa.jpg';
  public totpKey = '';
  public totpUrl = '';
  private totpId = '';
  public totpResponseKey = '';
  private browser = detect();
  public isSmallScreen = false;
  private smallScreenSizeBreakpoint = 900;
  private routerQueryParams$: Observable<Params>;
  public webPushDeviceTableData: Array<DeviceDataElement> = [];
  public webPushDataSource: MatTableDataSource<DeviceDataElement> = new MatTableDataSource();
  public webPushDisplayedColumns: Array<string> = ['created', 'enabled', 'actions'];
  public webPushTooltipText =
    'Using a trusted (non-shared) device you own, you  will get a notification when someone is logging in as you, to accept or deny.';
  public smsTooltipText =
    'If no other option is available, use SMS with a trusted SIM card. We recommend this method only as a last resort.';
  public authAppTooltipText =
    'Use an authenticator app (Authy, Google Authenticator, Microsoft Authenticator, ...). These free applications work with many sites (Google, Apple, Facebook, Linkedin, Amazon, ...).';
  public registeredSecurityKeysTooltipText =
    'Use a physical key (i.e Yubikey) to authenticate. You may also be able to use your phone as a key.';
  public enrollMessageSent = false;
  private messageEndpointId = '';
  private totpDeviceList: Array<MFAChallengeMethod> = [];
  private urlWithoutParams: string;
  public notifRx: boolean;
  public webauthnDeviceTableData: Array<DeviceDataElement> = [];
  public webauthnDataSource: MatTableDataSource<DeviceDataElement> = new MatTableDataSource();
  public webauthnDisplayedColumns: Array<string> = ['nickname', 'origin', 'created', 'enabled', 'actions'];
  public addingWebauthnDevice = false;
  public currentAuthenticatorType = AuthenticatorType.AUTO;
  public authenticatorTypeOptions: Array<AuthenticatorType> = [
    AuthenticatorType.AUTO,
    AuthenticatorType.USE_EXTERNAL_DEVICE,
    AuthenticatorType.USE_THIS_DEVICE,
  ];
  private externalHTTPURL: URL | undefined;
  public authenticatorHelpContent = '';
  public currentWebauthnDeviceName = '';
  private webauthnEnrollmentList: Array<WebAuthNEnrollment> | undefined = [];
  // Hiding the SMS panel since not yet supported.
  public showSMSPanel = false;
  public supportedMfaMethods: Array<string> = [];
  public restrictedMethodDisabledPanelTooltipText = 'Your organisation has not enabled this method';

  // This is required in order to reference the enums in the html template.
  public challengeType = ChallengeType;

  public isDialogView: boolean = false;

  @ViewChild(MatAccordion, { static: true })
  public accordion: MatAccordion = new MatAccordion();
  @ViewChild('webPushTableSort', { static: true }) public webPushTableSort: MatSort = new MatSort();
  @ViewChild('webauthnTableSort', { static: true }) public webauthnTableSort: MatSort = new MatSort();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messages: MessagesService,
    private users: UsersService,
    private challenges: ChallengesService,
    private issuersService: IssuersService,
    private organisationsService: OrganisationsService,
    private swPush: SwPush,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer,
    private httpWebAuthn: HttpWebauthnService,
    @Optional() public dialogRef: MatDialogRef<MfaEnrollComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      showDialog: boolean;
    }
  ) {
    this.urlWithoutParams = window.location.href.split('?')[0];
    this.webPushEnabled = this.swPush.isEnabled;
    this.webPushSubscribed = this.swPush.subscription !== null;
    this.webAuthNEnabled = this.shouldWebauthnBeEnabled();
    this.bannerImg = 'assets/img/mfa.jpg';
    this.routerQueryParams$ = this.route.queryParams;
    this.notifRx = false;
  }
  private doResize(): void {
    if (window.innerWidth < this.smallScreenSizeBreakpoint) {
      this.bannerImg = 'assets/img/mfa-narrow.jpg';
      this.isSmallScreen = true;
    } else {
      this.bannerImg = 'assets/img/mfa.jpg';
      this.isSmallScreen = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.doResize();
  }

  public ngOnInit(): void {
    if (this.data && this.data.showDialog) {
      this.isDialogView = true;
    }
    this.isSmallScreen = window.innerWidth < this.smallScreenSizeBreakpoint;
    this.doResize();
    this.routerQueryParams$
      .pipe(
        filter((routerParamsResp) => {
          return !!this.getUserIdFromRouter(routerParamsResp);
        }),
        concatMap((routerParamsResp) => {
          const accessToken = routerParamsResp.access_token || localStorage.getItem('access_token');
          const userId = this.getUserIdFromRouter(routerParamsResp);
          if (accessToken && userId) {
            const allDevicesList$ = this.getAllDevicesList$(userId);
            return forkJoin([of(routerParamsResp), allDevicesList$]);
          }
          return forkJoin([of(routerParamsResp), of(undefined)]);
        }),
        concatMap(([routerParamsResp, allDevicesListResp]) => {
          if (!!allDevicesListResp) {
            const webauthnEnrollmentsList$ = this.getWebauthnEnrollmentsList$(this.getUserIdFromRouter(routerParamsResp));
            return forkJoin([of(routerParamsResp), of(allDevicesListResp), webauthnEnrollmentsList$]);
          }
          return forkJoin([of(routerParamsResp), of(allDevicesListResp), of(undefined)]);
        }),
        concatMap(([routerParamsResp, allDevicesListResp, webauthnEnrollmentsListResp]) => {
          const issuerId$ = this.getIssuerIdFromOrgId$(routerParamsResp);
          return forkJoin([of(routerParamsResp), of(allDevicesListResp), of(webauthnEnrollmentsListResp), issuerId$]);
        }),
        concatMap(([routerParamsResp, allDevicesListResp, webauthnEnrollmentsListResp, issuerIdResp]) => {
          if (!!allDevicesListResp && !!webauthnEnrollmentsListResp && !!issuerIdResp) {
            const supportedMfaMethods$ = this.getSupportedMfaMethods$(issuerIdResp);
            return forkJoin([of(routerParamsResp), of(allDevicesListResp), of(webauthnEnrollmentsListResp), supportedMfaMethods$]);
          }
          return forkJoin([of(routerParamsResp), of(allDevicesListResp), of(webauthnEnrollmentsListResp), of(undefined)]);
        }),
        concatMap(([routerParamsResp, allDevicesListResp, webauthnEnrollmentsListResp, supportedMfaMethodsResp]) => {
          const notifRx = routerParamsResp.notifRx || false;
          const messageEndpointId = routerParamsResp.context || '';
          if (notifRx && !!messageEndpointId) {
            const createWebPushChallengeMethod$ = this.createWebPushChallengeMethod$(
              this.getUserIdFromRouter(routerParamsResp),
              messageEndpointId
            );
            return forkJoin([
              of(routerParamsResp),
              of(allDevicesListResp),
              of(webauthnEnrollmentsListResp),
              of(supportedMfaMethodsResp),
              createWebPushChallengeMethod$,
            ]);
          }
          return forkJoin([
            of(routerParamsResp),
            of(allDevicesListResp),
            of(webauthnEnrollmentsListResp),
            of(supportedMfaMethodsResp),
            of(undefined),
          ]);
        }),
        concatMap(
          ([
            routerParamsResp,
            allDevicesListResp,
            webauthnEnrollmentsListResp,
            supportedMfaMethodsResp,
            createWebPushChallengeMethodResp,
          ]) => {
            const getURL$ = this.httpWebAuthn.getURL$(getOrgIdFromRouter(routerParamsResp));
            if (createWebPushChallengeMethodResp) {
              const webPushDeviceList$ = this.getDeviceListByType$(this.getUserIdFromRouter(routerParamsResp), ChallengeType.web_push);
              return forkJoin([
                of(routerParamsResp),
                of(allDevicesListResp),
                of(webauthnEnrollmentsListResp),
                of(supportedMfaMethodsResp),
                of(createWebPushChallengeMethodResp),
                webPushDeviceList$,
                getURL$,
              ]);
            }
            return forkJoin([
              of(routerParamsResp),
              of(allDevicesListResp),
              of(webauthnEnrollmentsListResp),
              of(supportedMfaMethodsResp),
              of(createWebPushChallengeMethodResp),
              of(undefined),
              getURL$,
            ]);
          }
        )
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          routerParamsResp,
          allDevicesListResp,
          webauthnEnrollmentsListResp,
          supportedMfaMethodsResp,
          createWebPushChallengeMethodResp,
          webPushDeviceListResp,
          httpWebAuthnURL,
        ]) => {
          const nParams = Object.keys(routerParamsResp).length;
          this.notifRx = routerParamsResp.notifRx || false;
          this.messageEndpointId = routerParamsResp.context || '';
          this.accessToken = routerParamsResp.access_token || localStorage.getItem('access_token');
          this.webauthnEnrollmentList = webauthnEnrollmentsListResp;
          if (httpWebAuthnURL) {
            this.externalHTTPURL = new URL(httpWebAuthnURL);
            this.authenticatorTypeOptions.push(AuthenticatorType.USE_EXTERNAL_HTTP);
          }
          localStorage.setItem('access_token', this.accessToken);
          const fe = routerParamsResp.force_enroll || localStorage.getItem('force_enroll');
          if (fe) {
            this.forceEnroll = fe === '1' || fe === 'true' || fe === 'True';
            localStorage.setItem('force_enroll', this.forceEnroll.toString());
          }
          this.setStoredOrgIfNotSet(routerParamsResp);
          this.userId = this.getUserIdFromRouter(routerParamsResp);

          if (this.route.snapshot.data.http_registration) {
            this.completeHttpRegistration(routerParamsResp);
            return;
          }
          if (this.route.snapshot.data.http_challenge) {
            this.completeHttpChallenge(routerParamsResp);
            return;
          }

          this.redirectUri = routerParamsResp.redirect_uri || localStorage.getItem('redirect_uri');
          localStorage.setItem('redirect_uri', this.redirectUri);
          if (this.accessToken) {
            this.messages.configuration.accessToken = this.accessToken;
            this.users.configuration.accessToken = this.accessToken;
            this.challenges.configuration.accessToken = this.accessToken;
            this.updateDeviceListData(allDevicesListResp);
            if (!!supportedMfaMethodsResp?.well_known_info && supportedMfaMethodsResp.well_known_info.length !== 0) {
              this.supportedMfaMethods = supportedMfaMethodsResp.well_known_info[0].supported_mfa_methods;
            }
          }
          if (this.notifRx) {
            this.enrollMessageSent = false;
            if (createWebPushChallengeMethodResp) {
              this.redirectIfRedirectUriSet();
            }
            if (webPushDeviceListResp) {
              this.setDeviceLists(ChallengeType.web_push, webPushDeviceListResp);
            }
          }
          // We clear the parameters from the URI to prevent the user
          // from inadvertently bookmarking them, or having them in the
          // auto-complete history (since the access token times out)
          if (nParams && !this.isDialogView) {
            this.router
              .navigate(['.'], {
                relativeTo: this.route,
                skipLocationChange: true,
                replaceUrl: true,
                queryParams: {},
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      );

    this.messages
      .listMessagesConfig()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (cfg) => {
          if (cfg.web_push && cfg.web_push.public_key) {
            this.vapidPublicKey = cfg.web_push.public_key;
          }
        },
        (err) => {
          this.notificationService.error(
            'A network error prevented fetching the messaging keys. You will be unable to add a new web push endpoint. You may browse or change the existing methods, or refresh the browser to retry.'
          );
        }
      );
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setStoredOrgIfNotSet(routerParamsResp: Params): void {
    const storedOrgId = getStoredOrgId();
    const currentOrgId = getOrgIdFromRouter(routerParamsResp);
    if (!storedOrgId && !!currentOrgId) {
      localStorage.setItem('org_id', currentOrgId);
    }
  }

  private getUserIdFromRouter(routerParams: Params): string {
    return routerParams.user_id || localStorage.getItem('user_id');
  }

  private getIssuerIdFromOrgId$(routerParams: Params): Observable<string | undefined> {
    return this.organisationsService.getOrg({ org_id: getOrgIdFromRouter(routerParams) }).pipe(
      catchError((err: any) => {
        console.log(`failed to fetch user organisation: ${err}`);
        return of(undefined);
      }),
      map((orgResp) => {
        return orgResp.issuer_id;
      })
    );
  }

  private shouldWebauthnBeEnabled(): boolean {
    const retval = typeof window.PublicKeyCredential !== 'undefined';
    if (!this.browser) {
      return retval;
    }
    /* SEEMS TO BE SUPPORTED NOW
    if (this.browser.os === 'Mac OS' || this.browser.os === 'iOS') {
      if (this.browser.name !== 'safari' && this.browser.name !== 'ios' && this.browser.name !== 'ios-webview') {
        this.webAuthNDisabledReason =
          'Error: Apple does not support security keys using a third party browser (' +
          this.browser.name +
          '). Please use Safari to access this feature.';
        return false;
      }
      if (parseInt(this.browser.version, 10) < 14) {
        this.webAuthNDisabledReason = 'Error: Security keys are only supported in iOS version 14 or greater.';
        return false;
      }
    }
    */
    return retval;
  }

  private getAllDevicesList$(userId: string): Observable<Array<MFAChallengeMethod>> {
    return this.users
      .listChallengeMethods({
        user_id: userId,
      })
      .pipe(
        map((challengeMethodsResp) => {
          return !!challengeMethodsResp?.mfa_challenge_methods ? challengeMethodsResp.mfa_challenge_methods : [];
        }),
        catchError((_) => {
          this.notificationService.error('Failed to retrieve the list of enrolled devices.');
          return of([]);
        })
      );
  }

  private updateDeviceListData(challengeMethods: Array<MFAChallengeMethod>): void {
    if (!challengeMethods) {
      return;
    }
    const webPushDeviceList = challengeMethods.filter((challengeMethod) => challengeMethod.spec.challenge_type === ChallengeType.web_push);
    const totpDeviceList = challengeMethods.filter((challengeMethod) => challengeMethod.spec.challenge_type === ChallengeType.totp);
    const webauthnDeviceList = challengeMethods.filter((challengeMethod) => challengeMethod.spec.challenge_type === ChallengeType.webauthn);
    this.updateWebPushDeviceTable(webPushDeviceList);
    this.totpDeviceList = totpDeviceList;
    this.updateWebauthnDeviceTable(webauthnDeviceList);
  }

  private setDeviceLists(challengeType: ChallengeType, list: Array<MFAChallengeMethod>): void {
    if (challengeType === ChallengeType.web_push) {
      this.updateWebPushDeviceTable(list);
    }
    if (challengeType === ChallengeType.totp) {
      this.totpDeviceList = list;
    }
    if (challengeType === ChallengeType.webauthn) {
      this.updateWebauthnDeviceTable(list);
    }
  }

  private getDeviceListByType$(userId: string, challengeType: ChallengeType): Observable<Array<MFAChallengeMethod>> {
    return this.users
      .listChallengeMethods({
        user_id: userId,
        challenge_type: challengeType,
      })
      .pipe(
        map((challengeMethodsResp) => {
          const result = challengeMethodsResp?.mfa_challenge_methods ? challengeMethodsResp.mfa_challenge_methods : [];
          return result;
        }),
        catchError((_) => {
          this.notificationService.error('Failed to retrieve the list of enrolled devices.');
          return of([]);
        })
      );
  }

  public isTotpEnrolled(): boolean {
    return this.totpDeviceList.length !== 0;
  }

  private redirectIfRedirectUriSet(): void {
    if (this.redirectUri && this.redirectUri !== '' && this.redirectUri !== 'null') {
      const redir = decodeURI(this.redirectUri);
      window.location.replace(redir);
    }
  }

  private updateTotpEnrollment$(): Observable<TOTPEnrollment | undefined> {
    const totpm: UpdateTotpEnrollmentRequestParams = {
      totp_id: this.totpId,
      TOTPEnrollmentAnswer: { answer: this.totpResponseKey, user_id: this.userId },
    };
    return this.challenges.updateTotpEnrollment(totpm).pipe(
      map((resp) => {
        if (!resp?.metadata?.id) {
          this.notificationService.error('Something went wrong. Try again.');
          return undefined;
        }
        return resp;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.notificationService.error('The provided code was not correct. Try again');
        } else {
          this.notificationService.error('Something went wrong. Try again.');
        }
        return of(undefined);
      })
    );
  }

  private createTotpChallengeMethod$(totpEnrollmentId: string): Observable<MFAChallengeMethod | undefined> {
    const ucm: CreateChallengeMethodRequestParams = {
      user_id: this.userId,
      MFAChallengeMethod: { spec: { challenge_type: ChallengeType.totp, endpoint: totpEnrollmentId, priority: 1 } },
    };
    return this.users.createChallengeMethod(ucm).pipe(
      map((resp) => {
        return resp;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.notificationService.error('This device is already enrolled with an authenticator app.');
        } else {
          this.notificationService.error('Something went wrong. Try again.');
        }
        return of(undefined);
      })
    );
  }

  private enrollDeviceWithTotp$(): Observable<[TOTPEnrollment, MFAChallengeMethod, Array<MFAChallengeMethod>]> {
    return this.updateTotpEnrollment$().pipe(
      concatMap((updateTotpEnrollmentResp) => {
        if (updateTotpEnrollmentResp?.metadata?.id) {
          const createTotpChallengeMethod$ = this.createTotpChallengeMethod$(updateTotpEnrollmentResp.metadata.id);
          return forkJoin([of(updateTotpEnrollmentResp), createTotpChallengeMethod$]);
        }
        return forkJoin([of(updateTotpEnrollmentResp), of(undefined)]);
      }),
      concatMap(([updateTotpEnrollmentResp, createTotpChallengeMethodResp]) => {
        if (createTotpChallengeMethodResp) {
          const totpDeviceList$ = this.getDeviceListByType$(this.userId, ChallengeType.totp);
          return forkJoin([of(updateTotpEnrollmentResp), of(createTotpChallengeMethodResp), totpDeviceList$]);
        }
        return forkJoin([of(updateTotpEnrollmentResp), of(createTotpChallengeMethodResp), of(undefined)]);
      })
    );
  }

  public enrollDeviceWithTotp(): void {
    this.enrollDeviceWithTotp$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([updateTotpEnrollmentResp, createTotpChallengeMethodResp, totpDeviceListResp]) => {
        if (createTotpChallengeMethodResp) {
          this.redirectIfRedirectUriSet();
        }
        if (totpDeviceListResp) {
          this.setDeviceLists(ChallengeType.totp, totpDeviceListResp);
        }
      });
  }

  private formatTotpUrl(key: string): string {
    return `otpauth://totp/Agilicus:${this.userId}?secret=${key}&issuer=Agilicus&algorithm=SHA1&digits=6&period=30&type=totp`;
  }

  private sanitizeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public getTotpUrl(): SafeUrl {
    return this.sanitizeUrl(this.formatTotpUrl(this.totpKey));
  }

  public addTotp(): void {
    const totpm: CreateTotpEnrollmentRequestParams = {
      TOTPEnrollment: {
        spec: {
          user_id: this.userId,
        },
      },
    };
    this.challenges
      .createTotpEnrollment(totpm)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r1: TOTPEnrollment) => {
        if (r1.status && r1.status.key && r1.metadata && r1.metadata.id) {
          this.totpKey = r1.status.key;
          this.totpId = r1.metadata.id;
          this.totpUrl = this.formatTotpUrl(this.totpKey);
        }
      });
  }

  private subscribeToNotifications$(): Observable<[PushSubscription, [MessageEndpoint, Message]] | undefined> {
    const requestSubscription$ = from(
      this.swPush.requestSubscription({
        serverPublicKey: this.vapidPublicKey,
      })
    );
    return requestSubscription$.pipe(
      concatMap((requestSubscriptionResp) => {
        const addSubscription$ = this.addSubscription$(requestSubscriptionResp);
        return forkJoin([of(requestSubscriptionResp), addSubscription$]);
      }),
      catchError((_) => {
        this.notificationService.error('Could not subscribe to notifications. Please do not use incognito mode.');
        return of(undefined);
      })
    );
  }

  public subscribeToNotifications(): void {
    this.subscribeToNotifications$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (!resp) {
          this.webPushEnabled = false;
        }
        const requestSubscriptionResp = resp[0];
        const updateMessageEndpointResp = resp[1][0];
        const testMessageResp = resp[1][1];
        if (testMessageResp) {
          this.messageEndpointId = updateMessageEndpointResp.metadata.message_endpoint_id;
          this.enrollMessageSent = true;
        }
      });
  }

  private updateWebPushMessageEndpoint$(sub: PushSubscription): Observable<MessageEndpoint> {
    const sub1 = JSON.stringify(sub);
    this.subtoken = sub1;
    const mpa: UpdateMessageEndpointRequestParams = {
      user_id: this.userId,
      MessageEndpoint: {
        spec: {
          endpoint_type: ChallengeType.web_push,
          nickname: this.browser?.name + '-' + this.browser?.os + '-' + this.browser?.version,
          address: sub1,
        },
      },
    };
    return this.messages.updateMessageEndpoint(mpa).pipe(
      map((resp) => {
        return resp;
      }),
      catchError((_) => {
        this.notificationService.error('Failed to retrieve the message endpoint id.');
        return of(undefined);
      })
    );
  }

  private addSubscription$(sub: PushSubscription): Observable<[MessageEndpoint, Message]> {
    return this.updateWebPushMessageEndpoint$(sub).pipe(
      concatMap((updateMessageEndpointResp) => {
        if (updateMessageEndpointResp?.metadata?.message_endpoint_id) {
          const testMessage$ = this.sendTestMessage$(updateMessageEndpointResp.metadata.message_endpoint_id);
          return forkJoin([of(updateMessageEndpointResp), testMessage$]);
        }
        return forkJoin([of(updateMessageEndpointResp), of(undefined)]);
      })
    );
  }

  private createWebPushChallengeMethod$(userId: string, messageEndpointId: string): Observable<MFAChallengeMethod | undefined> {
    const ucm: CreateChallengeMethodRequestParams = {
      user_id: userId,
      MFAChallengeMethod: {
        spec: {
          challenge_type: ChallengeType.web_push,
          endpoint: messageEndpointId,
          priority: 1,
        },
      },
    };
    return this.users.createChallengeMethod(ucm).pipe(
      map((resp: MFAChallengeMethod) => {
        return resp;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.notificationService.error('This device is already enrolled with web push notifications.');
        } else {
          this.notificationService.error('Something went wrong. Try again.');
        }
        return of(undefined);
      })
    );
  }

  private enrollDeviceWithWebPush$(): Observable<[MFAChallengeMethod, Array<MFAChallengeMethod>]> {
    return this.createWebPushChallengeMethod$(this.userId, this.messageEndpointId).pipe(
      concatMap((createWebPushChallengeMethodResp) => {
        if (createWebPushChallengeMethodResp) {
          const webPushDeviceList$ = this.getDeviceListByType$(this.userId, ChallengeType.web_push);
          return forkJoin([of(createWebPushChallengeMethodResp), webPushDeviceList$]);
        }
        return forkJoin([of(createWebPushChallengeMethodResp), of(undefined)]);
      })
    );
  }

  private enrollDeviceWithWebPush(): void {
    this.enrollDeviceWithWebPush$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([createWebPushChallengeMethodResp, webPushDeviceListResp]) => {
        if (createWebPushChallengeMethodResp) {
          this.redirectIfRedirectUriSet();
        }
        if (webPushDeviceListResp) {
          this.setDeviceLists(ChallengeType.web_push, webPushDeviceListResp);
        }
      });
  }

  private convertDateToReadableFormat(targetDate: Date): string {
    const convertedDate = new Date(targetDate);
    return convertedDate.toLocaleString('sv', { timeZoneName: 'short' });
  }

  private updateWebPushDeviceTable(mfaChallengeMethods: Array<MFAChallengeMethod>): void {
    this.resetDeviceTable(this.webPushDeviceTableData);
    mfaChallengeMethods.forEach((method) => {
      if (method.metadata && method.metadata.created && method.metadata.id) {
        this.webPushDeviceTableData.push({
          created: this.convertDateToReadableFormat(method.metadata.created),
          id: method.metadata.id,
          endpoint: method.spec.endpoint,
          enabled: method.spec.enabled,
        });
      }
    });
    this.webPushDataSource = new MatTableDataSource(this.webPushDeviceTableData);
    this.webPushDataSource.sort = this.webPushTableSort;
  }

  public deleteWebPushChallengeMethodFromTable(challengeMethodToDelete: DeviceDataElement): void {
    this.deleteChallengeMethodAndGetUpdatedList(challengeMethodToDelete.id, ChallengeType.web_push);
  }

  private getTotpEnrolledDeviceId(): string | undefined {
    if (this.totpDeviceList.length !== 0) {
      return this.totpDeviceList[0].metadata?.id;
    }
    return undefined;
  }

  public deleteTotpChallengeMethod(): void {
    const id = this.getTotpEnrolledDeviceId();
    if (id) {
      this.deleteChallengeMethodAndGetUpdatedList(id, ChallengeType.totp);
    }
  }

  private deleteChallengeMethod$(challengeMethodIdToDelete: string): Observable<any | undefined> {
    return this.users
      .deleteChallengeMethod({
        user_id: this.userId,
        challenge_method_id: challengeMethodIdToDelete,
      })
      .pipe(
        catchError((_) => {
          this.notificationService.error('Failed to delete the device.');
          return of(undefined);
        })
      );
  }

  private deleteChallengeMethodAndGetUpdatedList$(
    challengeMethodIdToDelete: string,
    challengeType: ChallengeType
  ): Observable<Array<MFAChallengeMethod>> {
    return this.deleteChallengeMethod$(challengeMethodIdToDelete).pipe(
      concatMap((_) => {
        return this.getDeviceListByType$(this.userId, challengeType);
      })
    );
  }

  private deleteChallengeMethodAndGetUpdatedList(challengeMethodIdToDelete: string, challengeType: ChallengeType): void {
    this.deleteChallengeMethodAndGetUpdatedList$(challengeMethodIdToDelete, challengeType)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((updatedDeviceListResp) => {
        this.setDeviceLists(challengeType, updatedDeviceListResp);
        if (challengeType === ChallengeType.totp) {
          this.resetTotpData();
        }
      });
  }

  private resetTotpData(): void {
    this.totpKey = '';
    this.totpUrl = '';
    this.totpId = '';
    this.totpResponseKey = '';
  }

  public testWebPushChallengeMethodFromTable(challengeMethodToTest: DeviceDataElement): void {
    this.sendTestMessage(challengeMethodToTest.endpoint);
  }

  private webParam(paramName: string, param: string): string {
    const x = `&${paramName}=${param ? encodeURIComponent(param) : ''}`;
    return x;
  }

  private sendTestMessage$(messageEndpointId: string): Observable<Message | undefined> {
    return this.messages
      .createMessage({
        message_endpoint_id: messageEndpointId,
        Message: {
          title: 'Test Web Push Message',
          icon: 'https://storage.googleapis.com/agilicus/www/img/Agilicus-logo-512x512.white.png',
          image: '',
          text: 'This is a test message. Click it if you received it.',
          uri:
            this.urlWithoutParams +
            '?notifRx=1' +
            this.webParam('user_id', this.userId) +
            this.webParam('access_token', this.accessToken) +
            this.webParam('redirect_uri', this.redirectUri),
          context: this.messageEndpointId,
          actions: [],
        },
      })
      .pipe(
        catchError((_) => {
          this.notificationService.error('Failed to send the test message.');
          return of(undefined);
        })
      );
  }

  private sendTestMessage(messageEndpointId: string): void {
    this.sendTestMessage$(messageEndpointId).pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  public isEnrolled(data: Array<DeviceDataElement>): boolean {
    let i = 0;
    for (i = 0; i < data.length; i++) {
      if (data[i].enabled) {
        return true;
      }
    }
    return false;
  }

  public isAuthAppEnrolled(): boolean {
    return this.totpDeviceList.length !== 0;
  }

  private async isUsingIncognitoMode(): Promise<boolean> {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const { quota } = await navigator.storage.estimate();
      if (quota && quota < 120000000) {
        return true;
      }
    }
    return false;
  }

  public canUseWebAuthn(): boolean {
    return this.webAuthNEnabled;
  }

  public canUseWebPush(): boolean {
    // TODO: add check for incognito mode and other possible cases.
    if (this.webPushEnabled) {
      return true;
    }
    return false;
  }

  public onYesClick(): void {
    this.enrollMessageSent = false;
    if (this.messageEndpointId) {
      this.enrollDeviceWithWebPush();
    }
  }

  public onNoClick(): void {
    this.enrollMessageSent = true;
    this.sendTestMessage(this.messageEndpointId);
    this.notificationService.warn('Attempting to resend message.');
  }

  public getActiveWebPushEnrollButtonText(): string {
    if (!this.isEnrolled(this.webPushDeviceTableData)) {
      return 'SET UP THIS DEVICE';
    }
    // TODO: update this when solution found for identifying if device should be reset.
    /*
    if (this.webPushSubscribed) {
      return 'RESET THIS DEVICE';
    }
    */
    return 'ADD THIS DEVICE';
  }

  /**
   * Need to reset the table to an empty list so that changes are reflected in the DOM.
   */
  private resetDeviceTable(data: Array<DeviceDataElement>): void {
    data.length = 0;
  }

  private getCreateWebauthParams(authAttachment: string): CreateWebauthnEnrollmentRequestParams {
    const result: CreateWebauthnEnrollmentRequestParams = {
      WebAuthNEnrollment: {
        spec: {
          user_id: this.userId,
          attestation_format: authAttachment,
          attestation_conveyance: 'direct',
          relying_party_id: this.removeProfileFromHostname(window.location.hostname),
        },
      },
    };
    if (this.currentAuthenticatorType === AuthenticatorType.USE_EXTERNAL_HTTP) {
      result.WebAuthNEnrollment.spec.http_endpoint = this.externalHTTPURL.toString();
    }

    return result;
  }

  private getPublicKeyCredentialCreationOptions(
    authAttachment: string,
    webAuthNEnrollment: WebAuthNEnrollment
  ): PublicKeyCredentialCreationOptions {
    return {
      rp: { name: webAuthNEnrollment.spec.relying_party_id, id: webAuthNEnrollment.spec.relying_party_id },
      user: {
        id: stringToArrayBuffer(webAuthNEnrollment.spec.user_id),
        name: webAuthNEnrollment.spec.user_id,
        displayName: webAuthNEnrollment.spec.user_id,
      },
      pubKeyCredParams: [{ type: 'public-key', alg: -7 }],
      authenticatorSelection: {
        authenticatorAttachment: authAttachment !== undefined ? (authAttachment as AuthenticatorAttachment) : undefined,
      },
      challenge: stringToArrayBufferU8(webAuthNEnrollment.status.challenge),
      timeout: 60000,
    };
  }

  private getUpdateWebauthnEnrollmentRequestParams(
    webauthnId: string,
    cred: PublicKeyCredential,
    pkResponse: AuthenticatorAttestationResponseType,
    transportListAsEnum: Array<AuthenticatorTransport>
  ): UpdateWebauthnEnrollmentRequestParams {
    return {
      webauthn_id: webauthnId,
      WebAuthNEnrollmentAnswer: {
        user_id: this.userId,
        credential_id: cred.id,
        client_data: arrayBufferToStr(pkResponse.clientDataJSON),
        authenticator_data: arrayBufferToStr(pkResponse.attestationObject),
        transports: transportListAsEnum,
      },
    };
  }

  private getWebauthnCreateChallengeMethodRequestParams(
    webAuthNEnrollment: WebAuthNEnrollment,
    details: WebauthnRegistrationDetails
  ): CreateChallengeMethodRequestParams {
    return {
      user_id: this.userId,
      MFAChallengeMethod: {
        spec: {
          challenge_type: ChallengeType.webauthn,
          endpoint: webAuthNEnrollment.metadata.id,
          priority: 1,
          nickname: details.nickname,
          origin: details.origin,
        },
      },
    };
  }

  private createWebauthnEnrollment$(): Observable<WebAuthNEnrollment> {
    const authAttachment = this.getAttestationFormatFromAuthenticatorType(this.currentAuthenticatorType);
    const webauthParams = this.getCreateWebauthParams(authAttachment);
    return this.challenges.createWebauthnEnrollment(webauthParams).pipe(
      map((createWebauthnResp) => {
        if (
          !createWebauthnResp ||
          !createWebauthnResp.status ||
          !createWebauthnResp.spec ||
          !createWebauthnResp.metadata ||
          !createWebauthnResp.metadata.id ||
          !createWebauthnResp.spec.user_id ||
          !createWebauthnResp.status.challenge
        ) {
          this.notificationService.error('Something went wrong. Try again.');
          return undefined;
        }
        return createWebauthnResp;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.notificationService.error('This device is already enrolled with webauthn.');
        } else {
          this.notificationService.error('Something went wrong. Try again.');
        }
        return of(undefined);
      })
    );
  }

  private createCredInterface$(authAttachment: string, webAuthNEnrollment: WebAuthNEnrollment): Observable<Credential> {
    const createCredentialDefaultArgs = this.getPublicKeyCredentialCreationOptions(authAttachment, webAuthNEnrollment);
    return from(navigator.credentials.create({ publicKey: createCredentialDefaultArgs })).pipe(
      catchError((err) => {
        this.notificationService.error(err);
        return of(undefined);
      })
    );
  }

  private updateWebauthnEnrollment$(credInterface: Credential, webAuthNEnrollment: WebAuthNEnrollment): Observable<WebAuthNEnrollment> {
    const webauthnId = webAuthNEnrollment.metadata.id;
    const cred = credInterface as PublicKeyCredential;
    const pkResponse = cred.response as AuthenticatorAttestationResponseType;
    const transportListAsEnum = new Array<AuthenticatorTransport>();
    if (typeof pkResponse.getTransports === 'function') {
      const transportList = pkResponse.getTransports();
      transportList.forEach((element) => transportListAsEnum.push(element as AuthenticatorTransport));
    }
    const updateParams = this.getUpdateWebauthnEnrollmentRequestParams(webauthnId, cred, pkResponse, transportListAsEnum);
    return this.challenges.updateWebauthnEnrollment(updateParams).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.notificationService.error('The provided code was not correct. Try again');
        } else {
          this.notificationService.error('Something went wrong. Try again.');
        }
        return of(undefined);
      })
    );
  }

  private createWebauthnChallengeMethod$(
    webAuthNEnrollment: WebAuthNEnrollment,
    details: WebauthnRegistrationDetails
  ): Observable<MFAChallengeMethod | undefined> {
    const ucm = this.getWebauthnCreateChallengeMethodRequestParams(webAuthNEnrollment, details);
    return this.users.createChallengeMethod(ucm).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.notificationService.error('This device is already enrolled with webauthn.');
        } else {
          this.notificationService.error('Something went wrong. Try again.');
        }
        return of(undefined);
      })
    );
  }

  private enrollExternalHTTP() {
    this.createWebauthnEnrollment$().subscribe((createWebauthnResp) => {
      if (!createWebauthnResp) {
        return;
      }

      const returnURI = new URL(window.location.href);
      returnURI.search = '';
      returnURI.hash = '';
      returnURI.pathname = '/http-webauthn-registration-complete';

      const pkcco = this.getPublicKeyCredentialCreationOptions(undefined, createWebauthnResp);
      const params: RegistrationParams<WebauthnRegistrationState> = {
        publicKeyCredentialCreationOptions: pkcco,
        state: {
          enrollment: createWebauthnResp,
          details: {
            nickname: this.currentWebauthnDeviceName,
            origin: createWebauthnResp.spec.relying_party_id,
          },
        },
        url: this.externalHTTPURL.toString() + '/register.html',
        redirectUri: returnURI.toString(),
      };
      this.httpWebAuthn.register(params);
    });
  }

  private externalHTTPChallenge(challenge: Challenge, endpoint: DeviceDataElement) {
    const encodedCredentialId = this.encodeCredentialIdFromEndpoint(endpoint.endpoint);
    const pkcro: PublicKeyCredentialRequestOptions = {
      rpId: this.getRPIDFromEndpoint(endpoint),
      allowCredentials: [
        {
          type: 'public-key',
          id: encodedCredentialId,
        },
      ],
      challenge: stringToArrayBufferU8(challenge.status.public_challenge),
      timeout: 60000,
      userVerification: 'discouraged',
    };

    const state: WebauthnChallengeState = {
      challenge: challenge,
      details: {
        nickname: endpoint.nickname,
        origin: endpoint.origin,
      },
    };

    const returnURI = new URL(window.location.href);
    returnURI.search = '';
    returnURI.hash = '';
    returnURI.pathname = '/http-webauthn-challenge-complete';

    const params: ChallengeParams<WebauthnChallengeState> = {
      url: this.externalHTTPURL.toString() + '/authenticate.html',
      publicKeyCredentialRequestOptions: pkcro,
      state: state,
      redirectUri: returnURI.toString(),
    };

    this.httpWebAuthn.challenge(params);
  }

  private completeHttpRegistration(routerParamsResp: Params) {
    if (routerParamsResp['error']) {
      this.notificationService.error(`failed http webauthn registration: ${routerParamsResp.error}`);
      return;
    }

    if (!routerParamsResp['state']) {
      this.notificationService.error('missing state in http webauthn registration response');
      return;
    }

    if (!routerParamsResp['pkc']) {
      this.notificationService.error('missing PublicKeyCredential (pkc) in registration response');
      return;
    }

    this.httpWebAuthn
      .completeRegistration<WebauthnRegistrationState>(routerParamsResp.state, routerParamsResp.pkc)
      .pipe(
        concatMap((result: RegistrationResult<WebauthnRegistrationState>) => {
          if (!result.state) {
            throw new Error('webauthn enrollment state missing');
          }
          result.state.details.origin = this.removeProfileFromHostname(window.location.hostname);
          return this.completeWebauthnEnrollment$(result.state.enrollment, result.pkc, result.state.details);
        })
      )
      .subscribe({
        next: (_) => {
          this.router.navigate(['/mfa-enroll'], {
            relativeTo: this.route,
            skipLocationChange: true,
            replaceUrl: true,
            queryParams: {},
          });
        },
        error: (err) => {
          this.notificationService.error(`error completing registration: ${err}`);
        },
      });
  }

  private completeHttpChallenge(routerParamsResp: Params) {
    if (routerParamsResp['error']) {
      this.notificationService.error(`failed http webauthn registration: ${routerParamsResp.error}`);
      return;
    }

    if (!routerParamsResp['state']) {
      this.notificationService.error('missing state in http webauthn registration response');
      return;
    }

    if (!routerParamsResp['pkc']) {
      this.notificationService.error('missing PublicKeyCredential (pkc) in registration response');
      return;
    }

    this.httpWebAuthn
      .completeChallenge<WebauthnChallengeState>(routerParamsResp.state, routerParamsResp.pkc)
      .pipe(
        concatMap((result: ChallengeResult<WebauthnChallengeState>) => {
          if (!result.state) {
            throw new Error('webauthn enrollment state missing');
          }
          return this.getChallengeAnswer$(result.pkc, result.state.challenge);
        })
      )
      .subscribe({
        next: (_) => {
          this.notificationService.info('challenge succeeded');
          setTimeout(() => {
            this.router.navigate(['/mfa-enroll'], {
              relativeTo: this.route,
              skipLocationChange: true,
              replaceUrl: true,
              queryParams: {},
            });
          }, 2000);
        },
        error: (err) => {
          this.notificationService.error(`challenge failed: ${err}`);
        },
      });
  }

  private enrollDeviceWithWebauthn$(): Observable<
    [WebAuthNEnrollment, Credential, WebAuthNEnrollment, MFAChallengeMethod, MFAChallengeMethod[], WebAuthNEnrollment[]]
  > {
    if (this.currentAuthenticatorType === AuthenticatorType.USE_EXTERNAL_HTTP) {
      this.enrollExternalHTTP();
      // We should never get here. Return never to satisfy the type safety.
      return NEVER;
    }

    const authAttachment = this.getAttestationFormatFromAuthenticatorType(this.currentAuthenticatorType);
    return this.createWebauthnEnrollment$().pipe(
      concatMap((createWebauthnResp) => {
        if (createWebauthnResp) {
          const credInterface$ = this.createCredInterface$(authAttachment, createWebauthnResp);
          return forkJoin([of(createWebauthnResp), credInterface$]);
        }
        return forkJoin([of(createWebauthnResp), of(undefined)]);
      }),
      concatMap(([createWebauthnResp, credInterfaceResp]) => {
        const details: WebauthnRegistrationDetails = {
          nickname: this.currentWebauthnDeviceName,
          origin: this.removeProfileFromHostname(window.location.hostname),
        };
        return this.completeWebauthnEnrollment$(createWebauthnResp, credInterfaceResp, details);
      })
    );
  }

  private completeWebauthnEnrollment$(
    createWebauthnResp: WebAuthNEnrollment,
    credInterfaceResp: Credential,
    details: WebauthnRegistrationDetails
  ): Observable<[WebAuthNEnrollment, Credential, WebAuthNEnrollment, MFAChallengeMethod, MFAChallengeMethod[], WebAuthNEnrollment[]]> {
    let update$: Observable<[WebAuthNEnrollment, Credential, WebAuthNEnrollment | undefined]> = forkJoin([
      of(createWebauthnResp),
      of(credInterfaceResp),
      of(undefined),
    ]);
    if (credInterfaceResp) {
      const updatedWebauthnEnrollment$ = this.updateWebauthnEnrollment$(credInterfaceResp, createWebauthnResp);
      update$ = forkJoin([of(createWebauthnResp), of(credInterfaceResp), updatedWebauthnEnrollment$]);
    }

    return update$.pipe(
      concatMap(([createWebauthnResp, credInterfaceResp, updatedWebauthnEnrollmentResp]) => {
        if (!!updatedWebauthnEnrollmentResp?.metadata?.id) {
          const createWebauthnChallengeMethod$ = this.createWebauthnChallengeMethod$(updatedWebauthnEnrollmentResp, details);
          return forkJoin([
            of(createWebauthnResp),
            of(credInterfaceResp),
            of(updatedWebauthnEnrollmentResp),
            createWebauthnChallengeMethod$,
          ]);
        }
        return forkJoin([of(createWebauthnResp), of(credInterfaceResp), of(updatedWebauthnEnrollmentResp), of(undefined)]);
      }),
      concatMap(([createWebauthnResp, credInterfaceResp, updatedWebauthnEnrollmentResp, createWebauthnChallengeMethodResp]) => {
        const webauthnDeviceList$ = this.getDeviceListByType$(this.userId, ChallengeType.webauthn);
        return forkJoin([
          of(createWebauthnResp),
          of(credInterfaceResp),
          of(updatedWebauthnEnrollmentResp),
          of(createWebauthnChallengeMethodResp),
          webauthnDeviceList$,
        ]);
      }),
      concatMap(
        ([
          createWebauthnResp,
          credInterfaceResp,
          updatedWebauthnEnrollmentResp,
          createWebauthnChallengeMethodResp,
          webauthnDeviceListResp,
        ]) => {
          const webauthnEnrollmentsList$ = this.getWebauthnEnrollmentsList$(this.userId);
          return forkJoin([
            of(createWebauthnResp),
            of(credInterfaceResp),
            of(updatedWebauthnEnrollmentResp),
            of(createWebauthnChallengeMethodResp),
            of(webauthnDeviceListResp),
            webauthnEnrollmentsList$,
          ]);
        }
      )
    );
  }

  public enrollDeviceWithWebauthn(): void {
    this.enrollDeviceWithWebauthn$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          createWebauthnResp,
          credInterfaceResp,
          updatedWebauthnEnrollmentResp,
          createWebauthnChallengeMethodResp,
          webauthnDeviceListResp,
          webauthnEnrollmentsListResp,
        ]) => {
          this.redirectIfRedirectUriSet();
          if (!!webauthnDeviceListResp) {
            this.setDeviceLists(ChallengeType.webauthn, webauthnDeviceListResp);
          }
          if (!!webauthnEnrollmentsListResp) {
            this.webauthnEnrollmentList = webauthnEnrollmentsListResp;
          }
        }
      );
  }

  private updateWebauthnDeviceTable(mfaChallengeMethods: Array<MFAChallengeMethod>): void {
    this.resetDeviceTable(this.webauthnDeviceTableData);
    mfaChallengeMethods.forEach((method) => {
      if (method.metadata && method.metadata.created && method.metadata.id) {
        this.webauthnDeviceTableData.push({
          created: this.convertDateToReadableFormat(method.metadata.created),
          id: method.metadata.id,
          endpoint: method?.spec?.endpoint,
          enabled: method?.spec?.enabled,
          nickname: method?.spec?.nickname,
          origin: method?.spec?.origin,
        });
      }
    });
    this.webauthnDataSource = new MatTableDataSource(this.webauthnDeviceTableData);
    this.webauthnDataSource.sort = this.webauthnTableSort;
    this.resetWebauthnData();
  }

  public getActiveWebauthnEnrollButtonText(): string {
    if (!this.isEnrolled(this.webauthnDeviceTableData)) {
      return 'SET UP THIS DEVICE';
    }
    return 'ADD THIS DEVICE';
  }

  public setupWebauthn(): void {
    this.addingWebauthnDevice = true;
  }

  public shouldDisplayAuthenticatorHelpMsg(): boolean {
    if (this.currentAuthenticatorType === AuthenticatorType.USE_EXTERNAL_DEVICE) {
      this.authenticatorHelpContent = 'Please insert your external security key';
      return true;
    }
    if (this.currentAuthenticatorType === AuthenticatorType.USE_THIS_DEVICE) {
      this.authenticatorHelpContent =
        'Older phones and some computers do not have support for a Trusted Platform Module. If your device supports this please continue.';
      return true;
    }
    return false;
  }

  public authenticatorTypeSelection(option: AuthenticatorType): void {
    this.currentAuthenticatorType = option;
  }

  public getAttestationFormatFromAuthenticatorType(type?: AuthenticatorType): string | undefined {
    // This is effectively "no-preference"
    const defaultRetval = undefined;
    if (type === undefined) {
      return defaultRetval;
    }
    if (type === AuthenticatorType.USE_THIS_DEVICE) {
      return 'platform';
    }
    if (type === AuthenticatorType.USE_EXTERNAL_DEVICE) {
      return 'cross-platform';
    }
    return defaultRetval;
  }

  public deleteWebauthnChallengeMethodFromTable(challengeMethodToDelete: DeviceDataElement): void {
    this.deleteChallengeMethodAndGetUpdatedList(challengeMethodToDelete.id, ChallengeType.webauthn);
  }

  private getWebauthnEnrollmentsList$(userId: string): Observable<Array<WebAuthNEnrollment>> {
    const webauthParams: ListWebauthnEnrollmentsRequestParams = {
      user_id: userId,
    };
    return this.challenges.listWebauthnEnrollments(webauthParams).pipe(
      map((resp) => {
        return resp.webauthn;
      })
    );
  }

  public testWebauthnChallengeMethodFromTable(challengeMethodToTest: DeviceDataElement): void {
    this.testMyWebauthn(challengeMethodToTest);
  }

  private createWebauthnChallenge$(endpoint: DeviceDataElement): Observable<Challenge> {
    const rpID = this.getRPIDFromEndpoint(endpoint);
    const challengeParams: CreateChallengeRequestParams = {
      Challenge: {
        spec: {
          challenge_endpoints: [{ endpoint: endpoint.endpoint, type: ChallengeType.webauthn }],
          challenge_types: [ChallengeType.webauthn],
          user_id: this.userId,
          send_now: true,
          origin: rpID ? rpID : endpoint.origin,
        },
      },
    };
    return this.challenges.createChallenge(challengeParams).pipe(
      map((createChallengeResp) => {
        if (
          !createChallengeResp ||
          !createChallengeResp.status ||
          !createChallengeResp.spec ||
          !createChallengeResp.metadata ||
          !createChallengeResp.metadata.id ||
          !createChallengeResp.status.public_challenge
        ) {
          return undefined;
        }
        return createChallengeResp;
      })
    );
  }

  private getCredInterface$(encodedCredentialId: ArrayBuffer, challenge: Challenge): Observable<Credential> {
    const getCredentialDefaultArgs: PublicKeyCredentialRequestOptions = {
      rpId: this.removeProfileFromHostname(window.location.hostname),
      allowCredentials: [
        {
          type: 'public-key',
          id: encodedCredentialId,
        },
      ],
      challenge: stringToArrayBufferU8(challenge.status.public_challenge),
      timeout: 60000,
      userVerification: 'discouraged',
    };

    return from(navigator.credentials.get({ publicKey: getCredentialDefaultArgs }));
  }

  private getChallengeAnswer$(credInterface: Credential, challenge: Challenge): Observable<ChallengeAnswer> {
    const cred = credInterface as PublicKeyCredential;
    const pkResponse = cred.response as AuthenticatorAssertionResponse;
    let userHandle = '';
    if (pkResponse.userHandle) {
      userHandle = arrayBufferToStr(pkResponse.userHandle);
    }
    const val = {
      user_id: this.userId,
      credential_id: cred.id,
      client_data: arrayBufferToStr(pkResponse.clientDataJSON),
      authenticator_data: arrayBufferToStr(pkResponse.authenticatorData),
      signature: arrayBufferToStr(pkResponse.signature),
      user_handle: userHandle,
    };
    const challengeAnswerParams: GetAnswerRequestParams = {
      allowed: true,
      challenge_type: ChallengeType.webauthn,
      challenge_answer: JSON.stringify(val),
      challenge_uid: this.userId,
      challenge_id: challenge.metadata.id,
    };
    return this.challenges.getAnswer(challengeAnswerParams);
  }

  private testMyWebauthn$(endpoint: DeviceDataElement): Observable<[Challenge, Credential, ChallengeAnswer]> {
    return this.createWebauthnChallenge$(endpoint).pipe(
      concatMap((createWebauthnChallengeResp) => {
        if (this.isHTTPWebauthn(endpoint)) {
          this.externalHTTPChallenge(createWebauthnChallengeResp, endpoint);
          // We should never get here since the http challenge will redirect.
          return NEVER;
        }
        const encodedCredentialId = this.encodeCredentialIdFromEndpoint(endpoint.endpoint);
        if (!!createWebauthnChallengeResp && !!encodedCredentialId) {
          const credInterface$ = this.getCredInterface$(encodedCredentialId, createWebauthnChallengeResp);
          return forkJoin([of(createWebauthnChallengeResp), credInterface$]);
        }
        return forkJoin([of(createWebauthnChallengeResp), of(undefined)]);
      }),
      concatMap(([createWebauthnChallengeResp, credInterfaceResp]) => {
        if (!!credInterfaceResp && !!createWebauthnChallengeResp) {
          const challengeAnswer$ = this.getChallengeAnswer$(credInterfaceResp, createWebauthnChallengeResp);
          return forkJoin([of(createWebauthnChallengeResp), of(credInterfaceResp), challengeAnswer$]);
        }
        return forkJoin([of(createWebauthnChallengeResp), of(credInterfaceResp), of(undefined)]);
      })
    );
  }

  private getRPIDFromEndpoint(endpoint: DeviceDataElement): string | undefined {
    const device = this.getDeviceFromEndpoint(endpoint.endpoint);
    if (!device) {
      return undefined;
    }
    return device.spec.relying_party_id;
  }

  private isHTTPWebauthn(endpoint: DeviceDataElement): boolean {
    const device = this.getDeviceFromEndpoint(endpoint.endpoint);
    if (!device) {
      return false;
    }

    if (!this.externalHTTPURL) {
      return false;
    }

    return device.spec.http_endpoint === this.externalHTTPURL.toString();
  }

  private testMyWebauthn(endpoint: DeviceDataElement): void {
    this.testMyWebauthn$(endpoint)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (_) => {},
        (err) => {
          this.notificationService.error(err);
        }
      );
  }

  private getDeviceFromEndpoint(endpointId: string): WebAuthNEnrollment | undefined {
    return this.webauthnEnrollmentList?.find((device) => device.metadata?.id === endpointId);
  }

  private encodeCredentialIdFromEndpoint(endpointId: string): ArrayBuffer | undefined {
    const credentialId = this.getDeviceFromEndpoint(endpointId)?.status?.credential_id;
    if (credentialId) {
      return stringToArrayBufferU8(atob(credentialId.replace(/_/g, '/').replace(/-/g, '+')));
    }
    return undefined;
  }

  private removeProfileFromHostname(hostname: string): string {
    if (hostname.startsWith('profile.')) {
      return hostname.slice(8);
    }
    return hostname;
  }

  private resetWebauthnData(): void {
    this.addingWebauthnDevice = false;
    this.currentWebauthnDeviceName = '';
  }

  private getSupportedMfaMethods$(issuerId: string): Observable<ListWellKnownIssuerInfo> {
    const listWellknownIssuerInfoParams: ListWellknownIssuerInfoRequestParams = {
      issuer_id: issuerId,
    };
    return this.issuersService.listWellknownIssuerInfo(listWellknownIssuerInfoParams);
  }

  public isSupportedMfaMethod(mfaMethod: ChallengeType): boolean {
    return this.supportedMfaMethods.includes(mfaMethod);
  }
}
