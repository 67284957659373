import { Injectable } from '@angular/core';
import { GetApiKeyData, getApiKey, getResourceAccessToken, getLauncherRefreshDataKey } from '@app/utils';
import { AuthService } from './auth-service/auth-service.service';
import { ExtensionMessengerService } from '@agilicus/extension-messenger';
import { NotificationService } from '@app/notifications/notification.service';
import { ResourceAccess } from '@app/resource-access';
import { firstValueFrom } from 'rxjs';
import { isMobileDevice, isSupportedBrowser } from '@app/browser-utils';
import { ResourceType } from '@app/resource-type.enum';
import { ExtensionStateService } from '@app/extension-state/extension-state.service';
import { UserDesktopAccessInfoStatus } from '@agilicus/angular';
import { getDefaultMessageTimeoutOverride } from '@app/message-utils';

interface MessageObject {
  action: string;
  access_token: string;
  id_token: string;
  issuer: string;
  action_data: {
    resource_info: ResourceAccess;
    api_key?: string;
  };
  refresh_token?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MountService {
  public launcherRefreshData: any;

  constructor(
    private authService: AuthService,
    private extensionMessengerService: ExtensionMessengerService,
    private notificationService: NotificationService,
    private extensionStateService: ExtensionStateService
  ) {
    this.setLauncherRefreshDataValueFromLocalStorage();
  }

  private setLauncherRefreshDataValueFromLocalStorage(): void {
    const launcherRefreshDataStoredValue = localStorage.getItem(getLauncherRefreshDataKey());
    if (!launcherRefreshDataStoredValue) {
      this.launcherRefreshData = null;
    } else {
      this.launcherRefreshData = JSON.parse(launcherRefreshDataStoredValue);
    }
  }

  private async getBaseMessageObject(resource: ResourceAccess, action: string): Promise<MessageObject> {
    const issuer = await this.getIssuer();
    const msgObj: MessageObject = {
      action: action,
      access_token: this.authService.getAuth()?.access_token(),
      id_token: this.authService.getAuth()?.id_token(),
      issuer: issuer,
      action_data: {
        resource_info: resource,
      },
    };
    return msgObj;
  }

  private async getMessageObjectAsString(resourceData: GetApiKeyData, resource: ResourceAccess, action: string): Promise<string> {
    const apiKeyResp = await firstValueFrom(getApiKey(resourceData));
    const messageObject = await this.getBaseMessageObject(resource, action);
    messageObject.action_data.api_key = apiKeyResp;
    return JSON.stringify(messageObject);
  }

  private async getResourceMessageObjectAsString(resourceData: GetApiKeyData, resource: ResourceAccess, action: string): Promise<string> {
    const rawToken = await firstValueFrom(getResourceAccessToken(resourceData, resource));
    const messageObject = await this.getBaseMessageObject(resource, action);
    messageObject.access_token = rawToken.token;
    messageObject.refresh_token = rawToken.refresh_token;
    return JSON.stringify(messageObject);
  }

  public async mountDrive(resourceData: GetApiKeyData, resource: ResourceAccess): Promise<void> {
    try {
      const msg = await this.getMessageObjectAsString(resourceData, resource, 'mount');
      const result = await this.extensionMessengerService.sendMessage(msg);
      this.notificationService.success('Share is mounted in :' + result);
    } catch (error) {
      this.notificationService.error(error);
    }
  }

  public async unMountDrive(resourceData: GetApiKeyData, resource: ResourceAccess): Promise<void> {
    try {
      const msg = await this.getMessageObjectAsString(resourceData, resource, 'unmount');
      const result = await this.extensionMessengerService.sendMessage(msg);
      this.notificationService.success('Drive is unmounted');
    } catch (error) {
      this.notificationService.error(error);
    }
  }

  public async getMountInformation(resourceData: GetApiKeyData, resource: ResourceAccess): Promise<string> {
    const msg = await this.getMessageObjectAsString(resourceData, resource, 'mount_info');
    return this.extensionMessengerService.sendMessage(msg);
  }

  public getMountTooltipMessage(isExtensionInstalledResult: boolean, launcherVersion: string, isSupportedBrowser: boolean): string {
    if (!isExtensionInstalledResult) {
      return 'Extension not installed. Please navigate to the "Launcher" tab on the left to install.';
    } else if (!launcherVersion) {
      return 'Launcher not installed. Please navigate to the "Launcher" tab on the left to install.';
    } else if (!isSupportedBrowser) {
      return 'Not supported by this browser';
    }
    return 'Click to mount this share';
  }

  public canUseLauncherWithExtension(isExtensionInstalled: boolean, launcherVersion: string | undefined | null): boolean {
    return isExtensionInstalled && !!launcherVersion && isSupportedBrowser();
  }

  public async launchResource(resourceData: GetApiKeyData, resource: ResourceAccess): Promise<void> {
    let msg = '';
    if (
      resourceData.resourceType === ResourceType.ssh ||
      resourceData.resourceType === ResourceType.launcher ||
      (resource.resource_type === ResourceType.desktop && resource.desktop_type === UserDesktopAccessInfoStatus.DesktopTypeEnum.vnc)
    ) {
      msg = await this.getResourceMessageObjectAsString(resourceData, resource, 'launch');
    } else {
      msg = await this.getMessageObjectAsString(resourceData, resource, 'launch');
    }
    this.sendMessageWithTimeout(msg, 24 * 60 * 60 * 1000); // Sends the message with a timeout of 24 hours
  }

  async sendMessageWithTimeout(msg: string, timeout: number) {
    try {
      const result = await Promise.race([
        this.extensionMessengerService.sendMessage(msg),
        new Promise((resolve, reject) => setTimeout(() => reject('Timeout'), timeout)),
      ]);
      this.notificationService.success(result as string);
    } catch (error) {
      if (!error) {
        return;
      }
      if (typeof error === 'string' && error.toLowerCase() === 'timeout') {
        console.log(`error: "${error}"`);
        return;
      }
      this.notificationService.error(error);
    }
  }

  public async getMountStatus(
    resourceData: GetApiKeyData,
    resource: ResourceAccess
  ): Promise<{ mountPath: string; isAlreadyMounted: boolean }> {
    try {
      const result = await this.getMountInformation(resourceData, resource);
      if (result) {
        const mountPath = JSON.parse(result).mount_path;
        const isAlreadyMounted = this.isMountPathSet(mountPath);
        return { mountPath, isAlreadyMounted };
      } else {
        return { mountPath: '', isAlreadyMounted: false };
      }
    } catch (error) {
      return { mountPath: '', isAlreadyMounted: false };
    }
  }

  private isMountPathSet(mountPath: string): boolean {
    return !!mountPath;
  }

  private refreshButtonStateChange(button: HTMLButtonElement, buttonText: string, refreshButtonState: string): void {
    button.innerHTML = buttonText;
    button.disabled = false;
    refreshButtonState = 'default';
  }

  private getLatestAccessToken(authService: AuthService): string | undefined | null {
    const auth = authService.getAuth();
    if (!auth) {
      return undefined;
    }
    return auth.access_token();
  }

  private getIDToken(authService: AuthService): string | undefined | null {
    const auth = authService.getAuth();
    if (!auth) {
      return undefined;
    }
    return auth.id_token();
  }

  public async getIssuer(): Promise<string | undefined> {
    const issuer = this.authService.getAuth() ? await this.authService.getAuth().oidc_issuer() : undefined;
    return issuer;
  }

  public async logout(): Promise<void> {
    const issuer = await this.getIssuer();
    const access_token = await this.getLatestAccessToken(this.authService);
    const msgObj = {
      action: 'logout',
      access_token: access_token,
      id_token: await this.getIDToken(this.authService),
      issuer: issuer,
    };
    const msg = JSON.stringify(msgObj);

    this.extensionMessengerService
      .sendMessage(msg)
      .then((result) => {})
      .catch((error) => {
        this.notificationService.error('Failed to send logout message to extension.');
      });
  }

  public async refreshLauncher(refreshButtonId: string, refreshButtonState: string): Promise<void> {
    if (isMobileDevice()) {
      // Does not apply on mobile
      return;
    }
    const issuer = await this.getIssuer();
    const access_token = await this.getLatestAccessToken(this.authService);
    const msgObj = {
      action: 'refresh',
      access_token: access_token,
      id_token: await this.getIDToken(this.authService),
      issuer: issuer,
    };
    const msg = JSON.stringify(msgObj);

    const button = document.getElementById(refreshButtonId) as HTMLButtonElement;
    const buttonText = button.innerHTML;
    button.innerHTML = buttonText + '&nbsp;(Pending...)';
    button.disabled = true;
    refreshButtonState = 'pending';

    this.extensionMessengerService
      .sendMessage(msg, getDefaultMessageTimeoutOverride())
      .then((result) => {
        localStorage.setItem(getLauncherRefreshDataKey(), result);
        this.launcherRefreshData = JSON.parse(result);
        this.refreshButtonStateChange(button, buttonText, refreshButtonState);
        this.extensionStateService.update(true);
      })
      .catch((error) => {
        this.refreshButtonStateChange(button, buttonText, refreshButtonState);
        this.notificationService.error('Failed to refresh the desktop integration. Please try again.');
      });
  }

  public getLauncherRefreshData(): any {
    return this.launcherRefreshData;
  }

  public doesResourceExistInLauncherRefreshData(resourceAccess: ResourceAccess): boolean {
    const launcherResources = this.launcherRefreshData?.resources_info?.resources;
    if (!launcherResources || !resourceAccess) {
      return false;
    }
    return launcherResources.some((obj) => obj.id === resourceAccess.id);
  }

  public doesSupportMount(): boolean {
    return this.launcherRefreshData?.resources_info?.supports_mount;
  }
}
