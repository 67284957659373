<mat-toolbar *ngIf="data.mobileType === 'android'" color="primary" class="android-prompt">
  <button mat-button (click)="installPwa()">
    Add to Home screen
  </button>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<div *ngIf="data.mobileType === 'ios'" class="ios-prompt">
  <button class="close-ios-inv" mat-button color="primary" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <div>To install this web app on your device tap the Menu button and then 'Add to Home screen' button</div>
  <div><img src="./assets/ios-menu-btn.png" alt="menu icon">
    <mat-icon>navigate_next</mat-icon> <img src="./assets/ios-add-btn.png" alt="add button">
  </div>
</div>