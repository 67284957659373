<h2 mat-dialog-title>{{data.resourceInfo.name | titlecase}}</h2>
<div mat-dialog-content class="main-content">
  <p>To access your desktop please download your configuration file</p>
</div>
<div mat-dialog-actions align="end">
  <button
    type="button"
    mat-raised-button
    color="warn"
    id="cancel_button"
    [mat-dialog-close]="undefined"
    aria-label="Button that cancels downloading the config file"
  >
   CANCEL
  </button>
  <button
    type="button"
    mat-raised-button
    color="primary"
    id="config_download_button"
    mat-dialog-close="true"
    aria-label="Button that downloads the config file"
  >
   DOWNLOAD CONFIGURATION
  </button>
</div>