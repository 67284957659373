<h1 mat-dialog-title>User Information</h1>
<mat-dialog-content>

  <button
  mat-raised-button
  color="primary"
  class="copy-button"
  matTooltip="Copy ID Token"
  ngxClipboard
  [cbContent]="idToken"
>
Copy
  <mat-icon>content_copy</mat-icon>
  </button>
<hr>

<ngx-json-viewer [json]="getIdToken()"></ngx-json-viewer>
</mat-dialog-content>
