<a 
  mat-list-item 
  (click)="toggleMenu()"
  [ngClass]="{ 'active': isChildRouteSelected() && !showMenu }"
>
  {{ parentName }}
  <mat-icon class="right-icon" *ngIf="!showMenu">chevron_right</mat-icon>
  <mat-icon class="right-icon" *ngIf="showMenu">expand_more</mat-icon>
</a>

<mat-nav-list class="sidenav-submenu" *ngIf="showMenu">
  <a 
    *ngFor="let item of subMenuItems"
    mat-list-item 
    routerLink="{{ item.routerLink }}" 
    class="sub-menu-option"
    (click)="updateCurrentLink(item.routerLink)"
    [queryParams]="getQueryParams(item.queryParams)"
    [ngClass]="{ 'active': isLinkActive(item.routerLink, router) }"
  >
    <span class="sidenav-submenu-item">
        {{ item.name }}
    </span>
  </a>
</mat-nav-list>
