import { UsersService, UserMetadata, UserMetadataSpec, DesktopClientConfigItem, CustomDesktopClientConfig } from '@agilicus/angular';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '@app/notifications/notification.service';
import { createUserMetadata$, getUserMetadata$, updateUserMetadata$ } from '@app/utils/user-metadata.utils';
import { Subject, Subscription, takeUntil } from 'rxjs';

interface RdpOption {
  value: string;
  config_type: string;
  operation: DesktopClientConfigItem.OperationEnum;
}

interface RdpOptions {
  microphoneRedirection?: RdpOption;
  audioOutputLocation?: RdpOption;
  clipboardRedirection?: RdpOption;
  usbDeviceRedirection?: RdpOption;
  multipleDisplays?: RdpOption;
  screenMode?: RdpOption;
  dynamicResolution?: RdpOption;
  desktopHeight?: RdpOption;
  desktopWidth?: RdpOption;
}

interface RdpOptionsBinding {
  [k: string]: keyof RdpOptions;
}

const configItemMap: RdpOptionsBinding = {
  audiocapturemode: 'microphoneRedirection',
  audiomode: 'audioOutputLocation',
  redirectclipboard: 'clipboardRedirection',
  usbdevicestoredirect: 'usbDeviceRedirection',
  'use multimon': 'multipleDisplays',
  'screen mode id': 'screenMode',
  'dynamic resolution': 'dynamicResolution',
  desktopheight: 'desktopHeight',
  desktopwidth: 'desktopWidth',
};

@Component({
  selector: 'app-rdp-options-dialog',
  templateUrl: './rdp-options-dialog.component.html',
  styleUrls: ['./rdp-options-dialog.component.scss'],
})
export class RdpOptionsDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private userId: string;
  private orgId: string;
  private metadataId: string;
  public rdpForm: FormGroup = new FormGroup({});
  private userMetadata: UserMetadata;
  public selectedTabIndex = 0; // set the initial selected tab index to 0
  private selectedFile: File;
  public isUploading = false;
  private rdpOptions: RdpOptions;
  private subscriptions: Subscription[] = [];

  constructor(
    private users: UsersService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<RdpOptionsDialogComponent>,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
    this.userId = data.userId;
    this.orgId = data.orgId;
  }

  public ngOnInit(): void {
    this.rdpForm = this.fb.group({
      microphoneRedirection: [null],
      audioOutputLocation: [null],
      clipboardRedirection: [null],
      usbDeviceRedirection: [null],
      multipleDisplays: [null],
      screenMode: [null],
      dynamicResolution: [null],
      desktopHeight: [null],
      desktopWidth: [null],
    });

    getUserMetadata$(this.users, this.userId, this.orgId).subscribe((resp) => {
      const metadata: UserMetadata = resp.find(
        (obj) => obj.spec.name === 'desktop-config-rdp-override' && (obj.spec.app_id === '' || obj.spec.app_id === undefined)
      );

      this.metadataId = metadata ? metadata.metadata.id : undefined;
      const parsedMetadata: CustomDesktopClientConfig = metadata ? JSON.parse(metadata.spec.data) : undefined;

      if (parsedMetadata?.config_items && parsedMetadata?.config_items.length) {
        const configItems = parsedMetadata.config_items;
        this.rdpOptions = this.configItemsToRdpOptions(configItems);
        this.userMetadata = metadata;
      } else {
        this.userMetadata = {
          spec: {
            user_id: this.userId,
            org_id: this.orgId,
            data_type: UserMetadataSpec.DataTypeEnum.json,
            name: 'desktop-config-rdp-override',
            data: '',
          },
        };
      }
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public onTabSelectionChanged(event: any) {
    this.selectedTabIndex = event.index; // update the selected tab index when the tab selection changes
  }

  public downloadMetadata(): void {
    if (this.userMetadata.spec.data.replace(/\s/g, '').length === 0) {
      let data: CustomDesktopClientConfig = {
        config_items: this.rdpOptionsToConfigItems(),
      };

      this.userMetadata.spec.data = JSON.stringify(data);
    }

    if (this.userMetadata.spec.data) {
      try {
        const parsedData = JSON.parse(this.userMetadata.spec.data);
        if (Object.keys(parsedData).length === 0) {
          this.notificationService.error(`Data is empty`);
        } else {
          // Download data to a file
          const blob = new Blob([this.userMetadata.spec.data], { type: 'application/json' });
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = 'config.json';
          downloadLink.click();
        }
      } catch (error) {
        this.notificationService.error(`ERROR: Failed to parse data: "${error}" `);
      }
    }
  }

  public onReadFile(event: any) {
    this.selectedFile = event.target.files[0];
    this.readFileContents();
  }

  private readFileContents() {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const contents = event.target.result as string;
      this.isUploading = true;
      if (this.selectedFile.type === 'application/json') {
        try {
          // Parse the contents as JSON data
          const parsedData = JSON.parse(contents);
          this.userMetadata.spec.data = JSON.stringify(parsedData);
          this.saveData();
        } catch (error) {
          this.notificationService.error(`ERROR: Failed to parse JSON: "${error}" `);
        }
      }
    };
    fileReader.readAsText(this.selectedFile);
  }

  private configItemsToRdpOptions(configItems: Array<DesktopClientConfigItem>): RdpOptions {
    const result: RdpOptions = {};
    configItems.forEach((obj) => {
      const key: keyof RdpOptions | undefined = configItemMap[obj.key];
      if (!key) {
        return;
      }
      result[key] = {
        value: obj.value,
        operation: obj.operation,
        config_type: obj.config_type,
      };

      const control = this.rdpForm.get(key);
      control.setValue(obj.value);
      control.updateValueAndValidity();
    });

    return result;
  }

  private rdpOptionsToConfigItems(): DesktopClientConfigItem[] {
    const changedValues = {};

    // Get changed form values
    Object.keys(this.rdpForm.controls).forEach((key) => {
      const control = this.rdpForm.get(key);
      if (control.dirty) {
        changedValues[key] = control.value;
      }
    });

    const rdpOptionsBinding: RdpOptionsBinding = {};
    Object.entries(configItemMap).forEach(([key, value]) => {
      rdpOptionsBinding[value] = key as keyof RdpOptions;
    });

    const mergedOptions: DesktopClientConfigItem[] = [];

    Object.entries(changedValues).forEach(([key, value]) => {
      const operation = this.rdpOptions?.[key]?.operation ?? 'set';
      const config_type = key === 'usbDeviceRedirection' ? 's' : 'i';
      if (value !== null) {
        mergedOptions.push({
          key: rdpOptionsBinding[key] ?? key,
          value: value.toString(),
          operation,
          config_type,
        });
      }
    });

    Object.entries(this.rdpOptions ?? {}).forEach(([key, value]) => {
      if (!changedValues.hasOwnProperty(key)) {
        const { operation, config_type } = value;
        if (value !== null) {
          mergedOptions.push({
            key: rdpOptionsBinding[key] ?? key,
            value: value.value.toString(),
            operation,
            config_type,
          });
        }
      }
    });

    return mergedOptions;
  }

  public onSubmit(): void {
    let data: CustomDesktopClientConfig = {
      config_items: this.rdpOptionsToConfigItems(),
    };

    this.userMetadata.spec.data = JSON.stringify(data);
    this.saveData();
  }

  private saveData(): void {
    if (this.metadataId) {
      updateUserMetadata$(this.users, this.metadataId, this.userMetadata)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            this.notificationService.success('Desktop config is successfully updated');
          },
          (err) => {
            this.notificationService.warn('ERROR: Unable to update desktop config');
          }
        );
    } else {
      createUserMetadata$(this.users, this.userMetadata)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            this.notificationService.success('Desktop config is successfully updated');
          },
          (err) => {
            this.notificationService.warn('ERROR: Unable to update desktop config');
          }
        );
    }
    this.closeDialog();
  }

  private closeDialog(): void {
    this.dialogRef.close('refreshDesktops');
  }
}
