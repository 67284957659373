import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule, BrowserAnimationsModule, MatBadgeModule],
  declarations: [],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule, BrowserAnimationsModule, MatBadgeModule],
})
export class SharedModule {
  constructor() {}
}
