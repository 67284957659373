<h2 mat-dialog-title>{{data.resourceInfo.name | titlecase}}</h2>
<div mat-dialog-content class="main-content">
  <p>{{data.resourceInfo.description}}</p>
  <mat-form-field class="details-full-width">
    <mat-label>Details</mat-label>
    <textarea matInput [formControl]="detailsFormControl" rows=4
     placeholder="Tell us more about who you are and why you want access. E.g. your employee id or your team lead." cdkFocusInitial>
     </textarea>
     <mat-error *ngIf="detailsFormControl.invalid">
      Please enter some details.
    </mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button
    type="button"
    mat-raised-button
    color="warn"
    id="cancel_button"
    [mat-dialog-close]="undefined"
    [aria-label]="'Button that cancels the request for access to the ' + data.resourceInfo.resource_type + ' when clicked.'"
  >
   CANCEL
  </button>
  <button
    type="button"
    mat-raised-button
    color="primary"
    id="request_button"
    [mat-dialog-close]="detailsFormControl.value"
    [disabled]="detailsFormControl.invalid"
    [aria-label]="'Button that requests access to the ' + data.resourceInfo.resource_type + ' when clicked.'"
  >
   REQUEST ACCESS
  </button>
</div>
