<mat-dialog-content>
  <p style="margin-top: 0;">Shares use WebDav technology. The URL for this share is <a href="{{ getFileShareUrlForLink() }}" target="_blank">{{ getFileShareUrlForLink() }}</a></p>
  <p>You may access this share by obtaining an access token and following the instructions below. You will enter the access token in your operating system in the field where it asks for a password. Please do not share this access token.</p>

  <div class="api-key-container">
    <button
      type="button"
      mat-raised-button
      color="primary"
      class="api-key-button"
      aria-label="Button to retrieve the access token"
      matTooltip="Click to create a new access token. Access tokens will be set to expire in 36 hours."
      (click)="setApiKey()"
    >
      GET ACCESS TOKEN
    </button>

    <div class="copy-text-container">
      <mat-form-field style=" margin-bottom: -0.75em">
        <mat-label>Access Token</mat-label>
        <input
          matInput
          type="text"
          [value]="apiKey"
          placeholder="Access Token"
          readonly
          #apiKeyInputTarget
        >
        <button
          mat-raised-button
          color="primary"
          class="copy-button"
          matTooltip="Copy '{{ apiKey }}'"
          [ngxClipboard]="apiKeyInputTarget"
        >
          <mat-icon>content_copy</mat-icon>
          </button>
      </mat-form-field>
    </div>

    <div class="refresh-api-key-button-container">
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="refresh-api-key-button"
        aria-label="Button to refresh all active access tokens"
        matTooltip="Click to refresh all active access tokens for another 36 hours"
        (click)="onRefreshAllActiveAPIKeys()"
      >
        REFRESH ACTIVE ACCESS TOKENS
      </button>
    </div>
  </div>

  <div class="user-instructions-text">
    <p>For mount instructions specific by operating system, see below:</p>

    <mat-tab-group mat-stretch-tabs animationDuration="0ms">
      <mat-tab label="Windows">
        <div class="mat-tab-content">
          <div>
            <p class="instructions-text">On Windows you can do this by mapping a network drive from the file explorer. Enter <a href="{{ getFileShareUrlForLink() }}" target="_blank">{{ getFileShareUrlForLink() }}</a> as the location to mount.</p>
          </div>
          <div class="windows-img">
            <img class="bordered-img" src="assets/img/windows-1.png" height=400>
          </div>
          <div>
            <p class="instructions-text">Enter your email as the user name and the access token as the password.</p>
          </div>
          <div class="windows-img">
            <img class="bordered-img" src="assets/img/windows-2.png" height=400>
          </div>        </div>
      </mat-tab>
      <mat-tab label="Mac">
        <div class="mat-tab-content">
          <p>On Mac, click the 'Finder' icon and navigate to Go > Connect to Server. Enter the url in the 'Server Address' field.</p>
        </div>
        </mat-tab>
      <mat-tab label="Linux">
        <div class="mat-tab-content">
          <p>On Linux, mount the url using:</p>
          <pre>
            <code>sudo mkdir -p {{ getSharePath() }}</code>
            <code>{{ getLinuxMountCommand() }}</code>
          </pre>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close tabindex="-1">Close</button>
</mat-dialog-actions>