import { Challenge, SessionChallengeStatus, WebAuthNEnrollment } from '@agilicus/angular';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { getDefaultMfaChallengeDialogConfig } from '@app/dialog-utils';
import { MfaChallengeDialogComponent, MFAChallengeDialogData } from '@app/mfa-challenge-dialog/mfa-challenge-dialog.component';
import { Observable, catchError, concatMap, map, of } from 'rxjs';
import { convertFragmentToChallenge } from '@app/utils/challenge.utils';

interface State {
  challengeSuccess?: boolean;
  error?: string;
  uri?: string;
}

@Component({
  selector: 'app-browser-mfa-challenge',
  templateUrl: './browser-mfa-challenge.component.html',
  styleUrls: ['./browser-mfa-challenge.component.scss'],
})
export class BrowserMfaChallengeComponent implements OnInit {
  public state$: Observable<State>;
  public challenge: Challenge;
  public sessionChallengeStatus: SessionChallengeStatus;
  public challengeDescription: string;
  private webauthnEnrollmentList: Array<WebAuthNEnrollment> | undefined = [];

  constructor(private route: ActivatedRoute, private challengeDialog: MatDialog) {}
  public ngOnInit(): void {
    this.state$ = this.route.fragment.pipe(
      concatMap((fragment: string) => {
        const jsonChallenge = convertFragmentToChallenge(fragment);
        this.sessionChallengeStatus = JSON.parse(jsonChallenge);
        this.challenge = this.sessionChallengeStatus.challenge;
        this.webauthnEnrollmentList = this.sessionChallengeStatus.webauthn_enrollments;
        this.challengeDescription = this.sessionChallengeStatus?.description ? this.sessionChallengeStatus.description : undefined;
        if (!this.challenge?.metadata?.id) {
          throw new Error('challenge missing id');
        }
        if (!this.challenge.spec) {
          throw new Error('challenge missing spec');
        }
        if (!this.challenge.status) {
          throw new Error('challenge missing status');
        }

        const data: MFAChallengeDialogData = {
          challenge: this.challenge,
          webauthnEnrollmentList: this.webauthnEnrollmentList,
          challengeDescription: this.challengeDescription,
        };
        const dialogRef = this.challengeDialog.open(
          MfaChallengeDialogComponent,
          getDefaultMfaChallengeDialogConfig({
            data,
          })
        );
        return dialogRef.afterClosed();
      }),
      map((status: string) => {
        var redirectUri: string;
        if (status === 'success') {
          const params = new URLSearchParams(window.location.search);
          redirectUri = params.get('redirect_uri');
          if (redirectUri === null) {
            throw new Error('challenge succeed but missing uri to redirect');
          }
          window.location.href = decodeURIComponent(redirectUri);
        }
        return {
          challengeSuccess: status === 'success',
          uri: redirectUri,
        };
      }),
      catchError((err) => {
        return of({
          error: err.toString() as string,
        });
      })
    );
  }
}
