import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { isLinkActive } from '@app/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface SubMenuItem {
  name: string;
  routerLink: string;
  queryParams?: Array<SubMenuQueryParam>;
}

export interface SubMenuQueryParam {
  name: string;
  value: string;
}

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
})
export class SubmenuComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  @Input() public parentName = '';
  @Input() public subMenuItems: Array<SubMenuItem> = [];
  @Input() public currentRouterLink: string;
  @Input() public orgId: string;
  @Output() public updateEvent = new EventEmitter<string>();
  public showMenu = false;

  public isLinkActive = isLinkActive;

  constructor(public router: Router, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        // We need to trigger this check manually
        // when using back and forth navigation.
        this.isChildRouteSelected();
        this.changeDetector.detectChanges();
      }
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  /**
   * Checks if the child link of the menu is currently the selected router link.
   */
  public isChildRouteSelected(): boolean {
    if (this.router === undefined) {
      return false;
    }
    for (const item of this.subMenuItems) {
      if (this.router.url.startsWith(item.routerLink)) {
        return true;
      }
    }
    return false;
  }

  public updateCurrentLink(currentRouterLink: string): void {
    this.updateEvent.emit(currentRouterLink);
  }

  public getQueryParams(items: Array<SubMenuQueryParam>): object {
    const queryParams = {
      org_id: this.orgId,
    };
    if (!!items) {
      for (const queryParam of items) {
        queryParams[queryParam.name] = queryParam.value;
      }
    }
    return queryParams;
  }
}
