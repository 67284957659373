import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResourceDialogData } from '@app/resource-dialog-data';
import { TokensService, UserDesktopAccessInfoStatus } from '@agilicus/angular';
import { AuthService } from '@app/services/auth-service/auth-service.service';
import { Subject } from 'rxjs';
import UI from '../../no-vnc/app/ui.js';
import { Router } from '@angular/router';
import { URLGenerator } from '@app/url-generator';
import { ClipboardSyncher, clipboardCopy } from '@app/vnc-utils';
import { VncMfaService } from '@app/services/vnc-mfa/vnc-mfa.service';
import { openVncInNewTab } from '../utils';

@Component({
  selector: 'app-vnc-desktop-dialog',
  templateUrl: './vnc-desktop-dialog.component.html',
  styleUrls: ['./vnc-desktop-dialog.component.scss'],
})
export class VncDesktopDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private clipboardSync = new ClipboardSyncher(UI);

  constructor(
    private tokensService: TokensService,
    public dialogRef: MatDialogRef<VncDesktopDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResourceDialogData,
    private authService: AuthService,
    private router: Router,
    private vncMfaService: VncMfaService
  ) {}

  public ngOnInit(): void {
    UI.clipboardCopy = clipboardCopy.bind(this);
    this.getApiKeyAndStartClient();
  }

  public ngAfterViewInit(): void {
    this.clipboardSync.start();
  }

  public ngOnDestroy(): void {
    this.clipboardSync.stop();
    if (UI.clipboardCopy) {
      delete UI.clipboardCopy;
    }
    if (UI.getURL) {
      delete UI.getURL;
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getApiKeyAndStartClient(): void {
    const desktopInfo: UserDesktopAccessInfoStatus = {
      user_id: this.data.resourceInfo.user_id,
      org_id: this.data.resourceInfo.org_id,
      resource_id: this.data.resourceInfo.id,
      resource_name: this.data.resourceInfo.name,
      resource_type: this.data.resourceInfo.resource_type,
      org_name: this.data.resourceInfo.org_name,
      desktop_type: UserDesktopAccessInfoStatus.DesktopTypeEnum.vnc,
      access_level: UserDesktopAccessInfoStatus.AccessLevelEnum.granted,
      resource_uri: this.data.resourceInfo.resource_url,
    };
    const urlGenerator = new URLGenerator(desktopInfo, this.tokensService, this.authService);
    UI.getURL = async () => await urlGenerator.getURLWithValidToken();
    this.vncMfaService.checkMFA(urlGenerator, UI, this.disconnectVNC.bind(this));
  }

  public disconnectVNC(): void {
    sessionStorage.removeItem('vncDialogState');
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (params.has('vncdialog')) {
      params.delete('vncdialog');
      url.search = params.toString();
      const updatedURL = url.toString();
      history.replaceState(null, '', updatedURL);
    }
    if (UI.rfb) {
      UI.rfb.addEventListener('disconnect', (e) => {
        if (UI.connected) {
          UI.closeControlBar();
          UI.disconnectFinished(e);
        }
        this.closeDialog();
      });
    }
    if (UI.connected) {
      UI.disconnect();
    } else {
      this.closeDialog();
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public openVncInNewTabAndCloseDialog() {
    openVncInNewTab(this.router, this.data.resourceInfo.id);
    this.closeDialog();
  }
}
