// Add data types to window.navigator for use in this file. See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html#-reference-types- for more info.
/// <reference types="user-agent-data-types" />
import * as uuid from 'short-uuid';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CookieService } from 'ngx-cookie-service';
import { environment as env, environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private eventURL = 'https://api.agilicus.com/v1/events/act';
  private os = undefined;
  private device = undefined;
  private deviceInfo;
  private arch = undefined;
  private email = undefined;
  private user_id = undefined;
  private org_id = undefined;
  private session = undefined;

  constructor(private http: HttpClient, private cookieService: CookieService, private deviceService: DeviceDetectorService) {
    this.session = sessionStorage.getItem('e-session');
    if (!this.session) {
      this.session = uuid.generate();
      sessionStorage.setItem('e-session', this.session);
    }

    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.os = this.deviceInfo.os;
    if (this.deviceService.isMobile()) {
      this.device = 'mobile';
    } else if (this.deviceService.isTablet()) {
      this.device = 'tablet';
    } else if (this.deviceService.isDesktop()) {
      this.device = 'desktop';
    }
    if (navigator?.userAgentData) {
      const ep = navigator.userAgentData
        .getHighEntropyValues(['architecture'])
        .then((result) => {
          this.arch = result.architecture;
        })
        .catch((error) => {});
    }
  }
  private sessReadthrough(key: string, val: string): string {
    let v1 = val;
    if (!v1) {
      v1 = sessionStorage.getItem(key);
    }
    if (v1) {
      sessionStorage.setItem(key, v1);
    }
    return v1;
  }
  public Setup(options: { email?: string | undefined; user_id?: string | undefined; org_id?: string | undefined }) {
    this.org_id = this.sessReadthrough('e-org_id', options.org_id);
    this.user_id = this.sessReadthrough('e-user_id', options.user_id);
    this.email = this.sessReadthrough('e-email', options.email);
  }

  public SendEvent(options: {
    event_id: string;
    event_type: string;
    category: string;
    sub_category?: string | undefined;
    email?: string | undefined;
    user_id?: string | undefined;
    org_id?: string | undefined;
    action?: string | undefined;
  }) {
    // this is best-efforts, does not cause an error

    this.org_id = this.sessReadthrough('e-org_id', options.org_id);
    this.user_id = this.sessReadthrough('e-user_id', options.user_id);
    this.email = this.sessReadthrough('e-email', options.email);
    if (!localStorage.email && this.email) {
      localStorage.email = this.email;
    }
    if (!this.email) {
      this.email = localStorage.email;
    }
    let mautic_id = localStorage.getItem('mtc_id');
    if (mautic_id === undefined) {
      mautic_id = this.cookieService.get('mtc_id');
    }
    const body = {
      event_id: options.event_id,
      event_type: options.event_type,
      user_agent: 'profile',
      session: this.session,
      email: this.email,
      mautic_id: mautic_id,
      user_id: this.user_id,
      org_id: this.org_id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      os: this.os,
      device: this.device,
      arch: this.arch,
      category: options.category,
      sub_category: options.sub_category,
      action: options.action,
      result: undefined,
      path: document.location.href,
      utm_source: undefined,
      utm_medium: undefined,
      utm_campaign: undefined,
      utm_term: undefined,
      utm_content: undefined,
    };
    try {
      const headers = { 'Content-type': 'application/json' };
      if (env.production) {
        this.http.post<any>(this.eventURL, body, { headers }).subscribe((data) => {});
      } else {
        console.log('EVENT', body);
      }
    } catch (err) {
      console.log('error sending event ', options.event_id);
    }
  }
}
