<div mat-dialog-content class="main-content">
    <h2>{{data.resourceInfo.name}}</h2>
    <p>{{data.resourceInfo.description}}</p>

    <button type="button" mat-raised-button color="warn" id="cancel_button" (click)="closeDialog()">
        CANCEL
    </button>
    &nbsp;

    <button type="button" mat-raised-button color="primary" id="request_button" (click)="requestAssistance()">
        REQUEST ASSISTANCE
    </button>
</div>

<!--
  [aria-label]="'Button that cancels the request for access to the ' + data.resourceInfo.resource_type + ' when clicked.'"
<button mat-raised-button (click)="closeDialog()">CANCEL</button>
<button mat-raised-button (click)="requestAssistance()">REQUEST ASSISTANCE</button>
-->