import { User } from '@agilicus/angular';
import { ExtensionMessengerService, LauncherInfoResult } from '@agilicus/extension-messenger';
import { Injectable, OnDestroy } from '@angular/core';
import { ensureDateIsDateType } from '@app/date-utils';
import { AuthService } from '@app/services/auth-service/auth-service.service';
import { getLauncherExtensionInstalledKey, getLauncherInfoKey } from '@app/utils';
import { Observable, ReplaySubject, Subject, catchError, concatMap, delay, from, map, of, take, takeUntil, startWith } from 'rxjs';

interface State {
  launcherInfo: LauncherInfoResult;
  isExtensionCurrentInstalled: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ExtensionStateService implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private stateInfo$ = new ReplaySubject<State>(1);
  private initialized: boolean = false;
  private count = 0;
  public isExtensionInstalled: boolean | undefined = undefined;
  private launcherInfo: LauncherInfoResult = {
    version: null,
  };

  constructor(private extensionMessengerService: ExtensionMessengerService, private authService: AuthService) {
    this.setIsExtensionInstalledValueFromLocalStorage();
    this.setLauncherInfoValueFromLocalStorage();
    this.stateInfo$.next({
      launcherInfo: this.defaultLauncherInfo(),
      isExtensionCurrentInstalled: false,
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setIsExtensionInstalledValueFromLocalStorage(): void {
    const launcherExtensionInstalledStoredStringValue = localStorage.getItem(getLauncherExtensionInstalledKey());
    if (!launcherExtensionInstalledStoredStringValue) {
      this.isExtensionInstalled = undefined;
      return;
    }
    if (launcherExtensionInstalledStoredStringValue.toLowerCase() === 'true') {
      this.isExtensionInstalled = true;
    } else if (launcherExtensionInstalledStoredStringValue.toLowerCase() === 'false') {
      this.isExtensionInstalled = false;
    } else {
      this.isExtensionInstalled = undefined;
    }
  }

  private setLauncherInfoValueFromLocalStorage(): void {
    const launcherInfoStoredValue = localStorage.getItem(getLauncherInfoKey());
    if (!launcherInfoStoredValue) {
      this.launcherInfo = {
        version: null,
      };
    } else {
      this.launcherInfo = JSON.parse(launcherInfoStoredValue);
    }
  }

  private getState$(forceRefresh: boolean = false): Observable<State> {
    /* A specific initialize function (handled when invoked on a relevant method)
    is used here instead of just calling in constructor. This is due to the fact that
    a service is an injectable, and will be called before the DOM is actually loaded.
    As a result, the extension (content.js) has not been fully loaded yet and therefore
    the extension has not been detected. But having a separate initializer call here,
    then the callers will be synchronized according to ngOnInit.
    */
    if (this.initialized && !forceRefresh) {
      // If we're not forcing a refresh, return the cache
      return this.stateInfo$;
    }

    return this.authService
      .getAuth()
      .user$()
      .pipe(
        takeUntil(this.unsubscribe$),
        // defer init until user is known. Only run init once.
        concatMap((user: User | undefined) => {
          if (!user) {
            return this.stateInfo$;
          }
          return this.initialize$();
        })
      );
  }

  private getStateOnce$(forceRefresh: boolean = false): Observable<State> {
    return this.getState$(forceRefresh).pipe(take(1));
  }

  private initialize$(): Observable<State> {
    return this.getLauncherInfoRaw$().pipe(
      takeUntil(this.unsubscribe$),
      concatMap((info: LauncherInfoResult | undefined) => {
        const status = this.extensionMessengerService.isExtensionInstalled();
        if (info && status) {
          // Only set initialized once we have the extension *and* launcher info. We need to keep trying
          // otherwise so we can detect the install.
          this.initialized = true;
        } else if (!info) {
          info = this.defaultLauncherInfo();
        }

        const state: State = {
          launcherInfo: info,
          isExtensionCurrentInstalled: status,
        };
        this.stateInfo$.next(state);
        return of(state);
      })
    );
  }

  public getLauncherInfoRaw$(): Observable<LauncherInfoResult> {
    return from(this.extensionMessengerService.isLauncherInstalled()).pipe(
      catchError((err) => {
        return of(0).pipe(
          delay(50),
          concatMap((_) => {
            return from(this.extensionMessengerService.isLauncherInstalled());
          }),
          catchError((err) => {
            console.log('>>>> sendMessage Error: ', err);
            return of(undefined);
          })
        );
      }),
      map((result: LauncherInfoResult | undefined) => {
        if (!result) {
          return undefined;
        }
        const convertedResult: LauncherInfoResult = {
          ...result,
          version: result.version,
          last_refresh: ensureDateIsDateType(result.last_refresh),
        };
        return convertedResult;
      })
    );
  }

  public getExtensionInstalledStatus(): Observable<boolean> {
    return this.getState$().pipe(
      startWith(this.isExtensionInstalled),
      map((state: State) => {
        const result = state?.isExtensionCurrentInstalled;
        localStorage.setItem(getLauncherExtensionInstalledKey(), result === undefined ? null : result.toString());
        this.isExtensionInstalled = result;
        return result;
      })
    );
  }

  public getCachedIsExtensionInstalled(): boolean | undefined {
    return this.isExtensionInstalled;
  }

  public getLauncherInfo(force: boolean | undefined = false): Observable<LauncherInfoResult> {
    return this.getState$(force).pipe(
      startWith(this.launcherInfo),
      map((state: State) => {
        const result = state?.launcherInfo;
        localStorage.setItem(getLauncherInfoKey(), result === undefined ? null : JSON.stringify(result));
        this.launcherInfo = result;
        return result;
      })
    );
  }

  public getCachedLauncherInfo(): LauncherInfoResult {
    return this.launcherInfo;
  }

  public update(force: boolean = false): void {
    this.getStateOnce$().subscribe((state: State) => {
      let isExtensionInstalledResult: boolean | undefined = undefined;
      if (!this.initialized) {
        force = true;
      } else {
        isExtensionInstalledResult = this.extensionMessengerService.isExtensionInstalled();
      }

      if (isExtensionInstalledResult !== state.isExtensionCurrentInstalled || force) {
        this.getStateOnce$(true).subscribe((_) => {});
      }
    });
  }

  private defaultLauncherInfo(): LauncherInfoResult {
    return {
      version: '',
    };
  }
}
