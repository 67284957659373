import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResourceDialogData } from '@app/resource-dialog-data';

@Component({
  selector: 'app-desktop-config-download-dialog',
  templateUrl: './desktop-config-download-dialog.component.html',
  styleUrls: ['./desktop-config-download-dialog.component.scss'],
})
export class DesktopConfigDownloadDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DesktopConfigDownloadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResourceDialogData
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit(): void {}
}
