<div *ngIf="canLoadPageData()" class="launcher-container">

  <h1 mat-title>Desktop Integration</h1>

  <div class="launcher-install-main">
    <p style="margin-top: 0;">
      The Agilicus Desktop Integration allows you to mount a share, run an application, or a remote resource (such as a Desktop or a Share) right from the local start menu. 
      It integrates into the desktop environment and is launched in a single click. 
      For supported browsers, an optional extension allows for a more seamless experience and automatic login/launch directly from your web browser.
    </p>

    <p>
      To learn more, refer to the <a target="_blank" [href]="productGuideLink">Product Guide</a>
    </p>

    <table border="0">
      <tr>
        <td>Install Extension:</td>
        <td>
          <div
            class="button-container launcher-screen-button"
            [matTooltip]="getExtensionInstallTooltip()"
          >
            <button
              type="button"
              mat-raised-button
              color="primary"
              class="inline-button"
              (click)="openExtensionTab()"
              [disabled]="isExtensionInstalledResult || !isSupportedBrowser()"
            >
              <span *ngIf="!isSupportedBrowser()">BROWSER NOT SUPPORTED</span>
              <span *ngIf="!isExtensionInstalledResult && isSupportedBrowser()">INSTALL NOW</span>
              <span *ngIf="isExtensionInstalledResult && isSupportedBrowser()">ALREADY INSTALLED</span>
            </button>
          </div>
        </td>
      </tr>
      
      <tr>
        <td>Install Desktop Integration:</td>
        <td>
          <div
            class="button-container launcher-screen-button"
            [matTooltip]="getLauncherInstallTooltip()"
          >
            <a
              mat-raised-button
              class="link-as-button"
              color="primary"
              (click)="installAndOpenDialog()"
            >
              <span *ngIf="!launcherVersion">INSTALL NOW</span>
              <span *ngIf="!!launcherVersion">REINSTALL NOW</span>
            </a>
          </div>
        </td>
      </tr>

      <tr>
        <td>Update Desktop Integration:</td>
        <td>
          <div
            class="button-container inline-button"
            [matTooltip]="getRefreshLauncherTooltip()"
          >
            <button
              mat-raised-button
              color="primary"
              class="refresh-button launcher-screen-button"
              (click)="refreshLauncher()"
              [disabled]="!launcherVersion"
              id="refreshButton"
            >
              REFRESH NOW
            </button>
          </div>
          (last refreshed on {{ getFormattedLastRefreshDateString() }})
          <span>
            <mat-icon class="help-icon"
              [matTooltip]="getLastRefreshTooltip()">
              help_outline
            </mat-icon>
          </span>
        </td>
      </tr>
    </table>

  </div>

</div>