/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment as env } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
@Injectable()
export class AppInitService {
  public override: object = {};

  constructor(private http: HttpClient) {}

  public setOverride(data: object): void {
    this.override = data;
  }

  public async init(): Promise<any> {
    this.override = {};
    try {
      const result = await firstValueFrom(this.http.get('/assets/app-config.json'));
      this.setOverride(result);
      return result;
    } catch (e) {
      return {};
    }
  }

  public get environment(): any {
    return { ...env, ...this.override };
  }

  public get overrideToken(): string | undefined {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const access_token = urlParams.get('access_token');
    if (access_token) {
      return access_token;
    }
    return undefined;
  }
}
