import { Browser, detect } from 'detect-browser';

export function getBrowserType(): Browser | 'bot' | 'node' | 'react-native' {
  const userAgent = navigator.userAgent;
  const browserInfo = detect(userAgent);
  return browserInfo.name;
}

export function isMobileDevice(): boolean {
  const mobilePattern = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return mobilePattern.test(navigator.userAgent);
}

export function isSupportedBrowser(): boolean {
  const browserType = getBrowserType();
  return browserType !== 'safari' && browserType !== 'firefox';
}

export function downloadToBrowser(url: string, filename: string): void {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
