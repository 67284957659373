import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogData } from '@app/dialog-utils';
import { PageInfoData } from '@app/page-info-utils';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  public messagePrefix = '';
  public message = 'Do you want to continue?';
  public confirmButtonText = 'Yes';
  public cancelButtonText = 'Cancel';
  public showShowMoreButton = false;
  public informationDialog = false;
  public icon = 'warning';
  public productGuideData: PageInfoData;
  public showErrorTextArea = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData, private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
    if (data) {
      this.messagePrefix = data.messagePrefix || this.messagePrefix;
      this.message = data.message || this.message;
      this.icon = !!data.icon ? data.icon : this.icon;
      this.productGuideData = data.productGuideData;
      if (data.buttonText?.confirm.length >= 0) {
        this.confirmButtonText = data.buttonText.confirm;
      }
      if (data.buttonText?.cancel.length >= 0) {
        // the cancel button will be hidden if cancelButtonText.length === 0
        this.cancelButtonText = data.buttonText.cancel;
      }
      if (data.informationDialog) {
        this.informationDialog = true;
      }
      this.showShowMoreButton = !!data.showMoreText;
    }
  }

  public onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  public openShowMore(): void {
    this.showErrorTextArea = true;
  }
}
