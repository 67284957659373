import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { OrgBasicInfo } from '../org-basic-info';
import { ResourceType } from '../resource-type.enum';
import { pluralizeString } from '../utils';

@Component({
  selector: 'app-app-icon-folder',
  templateUrl: './app-icon-folder.component.html',
  styleUrls: ['./app-icon-folder.component.scss'],
})
export class AppIconFolderComponent implements OnInit {
  @Input() public folderList: Array<OrgBasicInfo> = [];
  @Input() public isSmallScreen = false;
  @Input() public resourceType: ResourceType = ResourceType.application;
  @Output() public subOrgClick = new EventEmitter<void>();

  constructor(private router: Router) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit(): void {}

  public routeToOrgResources(orgId: string): void {
    this.subOrgClick.emit();
    let resourceRoute = pluralizeString(this.resourceType);
    if (resourceRoute === 'sshes') {
      resourceRoute = 'sshs';
    }
    if (resourceRoute === 'shares') {
      resourceRoute = 'shares/share-access';
    }
    this.router.navigate([`/${resourceRoute}`], {
      queryParams: { org_id: orgId },
    });
  }
}
