import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  MessagesService,
  ListInboxItemsRequestParams,
  InboxItem,
  GetInboxItemRequestParams,
  ReplaceInboxItemRequestParams,
  User,
} from '@agilicus/angular';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from './services/auth-service/auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class InboxItemsService {
  constructor(private messagesService: MessagesService, private authService: AuthService) {}

  private getProfileMessageClass(): string {
    return 'agilicus-profile';
  }

  public getInboxItemsList$(userId: string, orgId: string): Observable<Array<InboxItem>> {
    const listInboxItemsRequestParams: ListInboxItemsRequestParams = {
      user_id: userId,
      org_id: orgId,
      message_class: this.getProfileMessageClass(),
      expired: false,
    };
    return this.messagesService.listInboxItems(listInboxItemsRequestParams).pipe(
      map((resp) => {
        return resp.inbox_items;
      })
    );
  }

  public getInboxItemsListUsingAuthService$(orgId: string): Observable<Array<InboxItem>> {
    return this.authService.auth.user$().pipe(
      switchMap((userResp: User) => {
        if (!userResp) {
          return of([]);
        }
        return this.getInboxItemsList$(userResp.id, orgId);
      })
    );
  }

  public getUnreadInboxItemsCount$(userId: string, orgId: string | undefined): Observable<number> {
    const listInboxItemsRequestParams: ListInboxItemsRequestParams = {
      user_id: userId,
      org_id: orgId,
      limit: 0,
      message_class: this.getProfileMessageClass(),
      expired: false,
    };
    return this.messagesService.listInboxItems(listInboxItemsRequestParams).pipe(
      map((resp) => {
        return resp.summary.count_unread;
      })
    );
  }

  public deleteInboxItem$(userId: string, inboxItemId: string): Observable<void> {
    return this.messagesService.deleteInboxItem({
      user_id: userId,
      inbox_item_id: inboxItemId,
    });
  }

  public deleteInboxItemUsingAuthService$(inboxItemId: string): Observable<void | undefined> {
    return this.authService.auth.user$().pipe(
      switchMap((userResp: User) => {
        if (!userResp) {
          return of(undefined);
        }
        return this.deleteInboxItem$(userResp.id, inboxItemId);
      })
    );
  }

  public getInboxItemById$(userId: string, inbox_item_id: string | undefined): Observable<InboxItem | undefined> {
    const getInboxItemRequestParams: GetInboxItemRequestParams = {
      user_id: userId,
      inbox_item_id: inbox_item_id,
    };
    return this.messagesService.getInboxItem(getInboxItemRequestParams);
  }

  public getInboxItemByIdUsingAuthService$(authService: AuthService, inboxItemId: string): Observable<InboxItem | undefined> {
    return authService.auth.user$().pipe(
      switchMap((userResp: User) => {
        if (!userResp) {
          return of(undefined);
        }
        return this.getInboxItemById$(userResp.id, inboxItemId);
      })
    );
  }

  public replaceInboxItem$(userId: string, inboxItemId: string | undefined, inboxItem: InboxItem): Observable<InboxItem | undefined> {
    const replaceInboxItemRequestParams: ReplaceInboxItemRequestParams = {
      user_id: userId,
      inbox_item_id: inboxItemId,
      InboxItem: inboxItem,
    };
    return this.messagesService.replaceInboxItem(replaceInboxItemRequestParams);
  }

  public replaceInboxItemUsingAuthService$(inboxItemId: string | undefined, inboxItem: InboxItem): Observable<InboxItem | undefined> {
    return this.authService.auth.user$().pipe(
      switchMap((userResp: User) => {
        if (!userResp) {
          return of(undefined);
        }
        return this.replaceInboxItem$(userResp.id, inboxItemId, inboxItem);
      })
    );
  }
}
