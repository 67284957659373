import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LabelInfo } from '@app/app-launcher/app-launcher.component';

@Component({
  selector: 'app-app-label-folder',
  templateUrl: './app-label-folder.component.html',
  styleUrls: ['./app-label-folder.component.scss'],
})
export class AppLabelFolderComponent implements OnInit {
  @Input() public isSmallScreen = false;
  @Input() public labelsList: Array<LabelInfo> = [];
  @Input() public showStatusIcons = true;
  @Output() public labelClick = new EventEmitter<LabelInfo>(); // Emit when a label is clicked

  constructor() {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit(): void {}

  // Handle the click on a label and emit the event
  public onLabelClick(label: LabelInfo): void {
    this.labelClick.emit(label);
  }
}
