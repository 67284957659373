import { Renderer2 } from '@angular/core';

export function b64toBlob(b64Data: string, contentType: string, sliceSize = 512): Blob {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function downloadBlob(blob: Blob, renderer: Renderer2, extension: string, fileName?: string): void {
  const link = renderer.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName ? `${fileName}.${extension}` : `data.${extension}`;
  link.click();
}
