<body class="theme-body">
  <div class="theme-navbar">
    <div class="theme-navbar__logo-wrap">
      <img class="theme-navbar__logo" [src]="logoImagePath">
    </div>
  </div>

  <div class="dex-container">

    <div class="theme-panel">
    <div id="main-content">
      <h2 class="theme-heading"><p></p>Multi-Factor Authentication</h2>
      <p *ngIf="data.challengeDescription">{{data.challengeDescription}}</p>
      <form [formGroup]="form">

        <input type="text" placeholder="Enter Authentication Code" name="verification-code-input" autocomplete="off" formControlName="totp_auth_code" class="theme-form-input-non-blocking">
        <button tabindex="3" id="submit-verification-code" type="button" class="dex-btn theme-btn--primary" (click)="submitTotpChallenge()">Verify</button>
        <div *ngIf="failed">
          <div class="theme-div-error"><p>The code you entered was incorrect. Please try again</p></div>
        </div>

        <h3 class="theme-heading" id="or-webauthn-id"><p></p>OR</h3>
        
        <button tabindex="3" id="initiate-webauthn-login-btn" type="button" class="dex-btn" [class.theme-btn--primary]="isSupportedWebauthn(challengeType.webauthn)" (click)="handleWebAuthNChallenge()" [disabled]="!isSupportedWebauthn(challengeType.webauthn)">Verify with Biometric/Security Key</button>
        
        <div class="theme-div"><p>See here <a href="https://www.agilicus.com/about-multi-factor-authentication" target="_blank">for more information</a> about multi-factor authentication </p></div>
      </form>
    </div>

    <div id="api-error-content" hidden="">
      <div class="theme-div"><p>An unexpected error has occured. Please try again.</p></div>
    </div>

    <div id="timeout-content" hidden="">
      <div class="theme-div"><p>For your security you must complete the multi-factor authentication process within a short time window. To try logging in again, return to your application's login page.</p></div>
      <div class="theme-div"><p>See here <a href="https://www.agilicus.com/about-multi-factor-authentication" target="_blank">for more information</a> about multi-factor authentication </p></div>
    </div>

    </div>
  </div>

</body>