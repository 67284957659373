<div *ngIf="state$ | async as state; else loading">
    <div *ngIf="state.error; else main">{{state.error}}</div>

    <ng-template #main>
        <div *ngIf="state.challengeSuccess">Challenge succeeded!</div>
        <div *ngIf="!state.challengeSuccess">Challenge failed! Closing in {{state.timeout}}s </div>
    </ng-template>    
</div>
<ng-template #loading>
    <div>Answering challenge... </div>
</ng-template>    
