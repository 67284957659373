<div *ngIf="state$ | async as state; else loading">
    <div *ngIf="state.error; else main">{{state.error}}</div>

    <ng-template #main>
        <div *ngIf="state.challengeSuccess">Challenge succeeded! Redirecting you back to {{state.uri}}</div>
        <div *ngIf="!state.challengeSuccess">Challenge failed! Redirecting you back to {{state.uri}} </div>
    </ng-template>    
</div>
<ng-template #loading>
    <div>Answering challenge... </div>
</ng-template>    
