import { Component, Input } from '@angular/core';

export enum PageInfoWidthEnum {
  standard = 'standard',
  full = 'full',
}

export enum TitleSizeEnum {
  standard = 'standard',
  small = 'small',
}

export interface PageInfoData {
  pageTitle?: string;
  pageDescriptiveText?: string;
  pageSubDescriptiveList?: Array<any>;
  productGuidePreText?: string;
  productGuideLink?: string;
  productGuideLinkText?: string;
  productGuidePostText?: string;
  pageInfoWidth?: PageInfoWidthEnum;
  helpImageFilePath?: string;
  pageDescriptiveTextWithImageWrap?: string;
  pageDescriptiveHelpImageWithTextWrap?: string;
  titleSize?: TitleSizeEnum;
}

@Component({
  selector: 'app-page-info',
  templateUrl: './page-info.component.html',
  styleUrls: ['./page-info.component.scss'],
})
export class PageInfoComponent {
  @Input() public pageTitle = '';
  @Input() public pageDescriptiveText = '';
  @Input() public pageSubDescriptiveList = [];
  @Input() public productGuidePreText = 'For more details, see the ';
  @Input() public productGuideLink = '';
  @Input() public productGuideLinkText = 'Product Guide';
  @Input() public productGuidePostText = '';
  @Input() public pageInfoWidth = PageInfoWidthEnum.standard;
  @Input() public helpImageFilePath = '';
  @Input() public pageDescriptiveTextWithImageWrap = '';
  @Input() public pageDescriptiveHelpImageWithTextWrap = '';
  @Input() public titleSize = TitleSizeEnum.standard;
  public productGuideDeepLink = '';

  // This is required in order to reference the enums in the html template.
  public pageInfoWidthEnum = PageInfoWidthEnum;
  public titleSizeEnum = TitleSizeEnum;
}
