<span *ngIf="!!descriptiveText" class="description">{{ descriptiveText }}</span>
<span
  [matTooltipDisabled]="!disable"
  [matTooltip]="disabledTooltipText"
>
  <button
    mat-raised-button
    color="primary"
    class="download-button"
    (click)="onDownloadFile()"
    [disabled]="disable"
  >
    DOWNLOAD
    <mat-icon class="download-icon">cloud_download</mat-icon>
  </button>
</span>
