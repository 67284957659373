<div class="dialog-container">
  <mat-dialog-content>
    <h2 class="message-prefix">{{ messagePrefix }}</h2>
    <span *ngIf="!informationDialog">
      <div class="icon-container">
        <mat-icon
          class="warning-icon"
          [ngClass]="{ 'notify': icon !== 'warning' }"
        >
          {{ icon }}
        </mat-icon>
      </div>
      <div class="message-container">
        <p [innerHTML]="message"></p>
      </div>
    </span>
    <div
      *ngIf="informationDialog"
      style="width: 100%;"
      class="message-container"
      [innerHTML]="message"
    >
    </div>
    
    <span *ngIf="!!productGuideData">
      <app-page-info
        [pageTitle]="productGuideData.pageTitle"
        [pageDescriptiveText]="productGuideData.pageDescriptiveText"
        [pageSubDescriptiveList]="productGuideData.pageSubDescriptiveList"
        [productGuidePreText]="productGuideData.productGuidePreText"
        [productGuideLink]="productGuideData.productGuideLink"
        [productGuideLinkText]="productGuideData.productGuideLinkText"
        [productGuidePostText]="productGuideData.productGuidePostText"
        [pageInfoWidth]="productGuideData.pageInfoWidth"
        [helpImageFilePath]="productGuideData.helpImageFilePath"
        [pageDescriptiveTextWithImageWrap]="productGuideData.pageDescriptiveTextWithImageWrap"
        [pageDescriptiveHelpImageWithTextWrap]="productGuideData.pageDescriptiveHelpImageWithTextWrap"
        [titleSize]="productGuideData.titleSize"
      >
      </app-page-info>
    </span>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button
      *ngIf="!!showShowMoreButton"
      mat-raised-button color="primary"
      (click)="openShowMore()"
      tabindex="2"
    >
      Show More
    </button>
    <button
      *ngIf="confirmButtonText.length > 0"
      mat-raised-button color="primary"
      (click)="onConfirmClick()"
      tabindex="1"
    >
      {{ confirmButtonText }}
    </button>
    <button
      *ngIf="cancelButtonText.length > 0"
      mat-raised-button
      mat-dialog-close
      tabindex="-1"
    >
      {{ cancelButtonText }}
    </button>
  </mat-dialog-actions>
  <div *ngIf="showErrorTextArea" class="textarea-container">
    <mat-form-field>
      <textarea
        matInput
        style="height: 200px;"
        [value]="data.showMoreText"
        readonly="true"
        #inputTarget
      >
      </textarea>
      <button
        mat-raised-button
        color="primary"
        class="copy-button"
        matTooltip="Copy the error message"
        [ngxClipboard]="inputTarget"
      >
        <mat-icon>content_copy</mat-icon> COPY
      </button>
    </mat-form-field>
  </div>
</div>