import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ChallengeStatus } from '@agilicus/angular';
import { PollingSubscriptionService } from '@app/polling-subscription.service';

export interface LauncherInstructionsDialogData {
  filename: string;
  fileExtension: string;
  productGuideLink: string;
  issuer: string;
}

@Component({
  selector: 'app-launcher-instructions-dialog',
  templateUrl: './launcher-instructions-dialog.component.html',
  styleUrls: ['./launcher-instructions-dialog.component.scss'],
})
export class LauncherInstructionsDialogComponent implements OnInit, OnDestroy {
  public defaultTabIndex: number;
  private unsubscribe$: Subject<void> = new Subject<void>();

  public images = [
    { url: 'assets/img/mac-instructions-1.png', width: '500px', height: 'auto' },
    { url: 'assets/img/mac-instructions-2.png', width: 'auto', height: '300px' },
    { url: 'assets/img/mac-instructions-3.png', width: 'auto', height: '300px' },
    { url: 'assets/img/mac-instructions-4.png', width: 'auto', height: '300px' },
  ];

  public activeImage = this.images[0];

  public setActiveImage(image: any): void {
    this.activeImage = image;
  }

  constructor(
    public dialogRef: MatDialogRef<LauncherInstructionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LauncherInstructionsDialogData,
    private pollingSubscriptionService: PollingSubscriptionService
  ) {}

  public ngOnInit(): void {
    this.setDefaultPlatform();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setDefaultPlatform(): void {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.includes('linux')) {
      this.defaultTabIndex = 0;
    } else if (userAgent.includes('win')) {
      this.defaultTabIndex = 1;
    } else if (userAgent.includes('mac')) {
      this.defaultTabIndex = 2;
    } else {
      this.defaultTabIndex = 1; // Default to Windows platform for unknown user agents
    }
  }

  public downloadFile(): void {
    this.pollingSubscriptionService.downloadFile$(this.data).subscribe((response) => {
      if (response === ChallengeStatus.StateEnum.challenge_passed) {
        this.dialogRef.close();
      }
    });
  }

  public downloadFileToMac(): void {
    this.pollingSubscriptionService.downloadFileToMac();
  }

  public disableDownloadButton(): boolean {
    return this.pollingSubscriptionService.disableDownloadButton();
  }

  public disableMacDownloadButton(): boolean {
    return this.pollingSubscriptionService.disableMacDownloadButton();
  }

  public onCopyToClipboard(): void {
    this.pollingSubscriptionService.onCopyToClipboard$(this.data).subscribe((response) => {
      if (response === ChallengeStatus.StateEnum.challenge_passed) {
        this.dialogRef.close();
      }
    });
  }
}
