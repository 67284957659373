<div class="dialog-container">
    <mat-dialog-content>
      <h2 class="message-prefix">SSH</h2>

      <form 
        [formGroup]="form" 
        spellcheck="false"
        autocomplete="off"
        *ngIf="data.dialogType === 'cred'"
        >
        <mat-form-field class="org-name-form">
            <input
            matInput
            type="text" 
            placeholder="username" 
            formControlName="username" 
            >
        </mat-form-field>
        <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput
                    type="password"
                    placeholder="Password"
                    formControlName="password"
                    (keyup)="handleEnterKeyUp($event)">
        </mat-form-field>
    </form>

    <p *ngIf="data.dialogType === 'reconnect'">
        SSH Connection has been closed because of - {{data.status}} Would you like to re-connect?
    </p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-raised-button color="warn" mat-dialog-close tabindex="-1" (click)="closeDialog()">CANCEL</button>
        <button *ngIf="data.dialogType === 'cred'" mat-raised-button color="primary" tabindex="1" (click)="save()">CONNECT</button>
        <button *ngIf="data.dialogType === 'reconnect'" mat-raised-button color="primary" tabindex="1" (click)="reconnect()">RECONNECT</button>
    </mat-dialog-actions>
</div>
