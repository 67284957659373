import { Auth } from '@agilicus/angular';
import { LoginArgs, LoginCallbackArgs, OptionalAuthArgs } from '@agilicus/angular/src/auth';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppInitService } from '@app/app.init';
import { getAllFileSharesScope } from '@app/utils';
import { CookieService } from 'ngx-cookie-service';

/* Please keep with list in sync with the one in sdk/py/agilicus/main.py */
export const defaultScopes: Array<string> = [
  'urn:agilicus:api:users:self',
  'urn:agilicus:api:messages:self',
  'urn:agilicus:api:credentials:self',
  'urn:agilicus:api:challenges:self',
  'urn:agilicus:api:traffic-tokens:user',
  'urn:agilicus:api:applications:self?',
  'urn:agilicus:api:labels:self?',
  'urn:agilicus:token_payload:multiorg:true',
  getAllFileSharesScope(),
];

const lastLoginKey = 'lastLoginAttempt';

export function constructScopes(scopeList: string[]): string {
  return scopeList.join(' ');
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public auth: Auth;

  constructor(private appInitService: AppInitService, private cookieService: CookieService, private router: Router) {
    const args: OptionalAuthArgs = {
      explicitLogin: true,
      postLoginCallback: this.loginCallback.bind(this),
    };
    let issuer = appInitService.environment.issuer;
    if (!issuer) {
      const hostname = window.location.hostname;
      let path = hostname;
      if (hostname.startsWith('profile')) {
        path = hostname.substr(hostname.indexOf('.') + 1);
      }
      issuer = 'https://auth.' + path + '/';
    }
    this.auth = new Auth(appInitService.environment.client_id, issuer, undefined, constructScopes(defaultScopes), args);
  }
  /**
   * This gets added to the cookie when a user had previously selected the
   * "Keep me signed in on this device?" option.
   */
  private getRememberMeCookieKey(): string {
    return 'Agilicus-SSO-rememberme-Cookie';
  }

  public login(): Promise<void> {
    sessionStorage.setItem('loginRedirectURI', this.router.url);
    sessionStorage.setItem(lastLoginKey, new Date().toISOString());
    return this.auth.login({
      redirectURI: this.router.url,
    });
  }

  public lastLoginAttempt(): Date | undefined {
    try {
      const strDate = sessionStorage.getItem(lastLoginKey);
      if (!strDate) {
        return undefined;
      }

      return new Date(strDate);
    } catch (e) {
      return undefined;
    }
  }

  public isRememberMeCookieSet(): boolean {
    const rememberMeCookieValAsString = this.cookieService.get(this.getRememberMeCookieKey());
    if (!rememberMeCookieValAsString) {
      return false;
    }
    try {
      const parsedRememberMeCookieVal: { rememberme: boolean } = JSON.parse(rememberMeCookieValAsString);
      return parsedRememberMeCookieVal.rememberme;
    } catch (e) {
      return false;
    }
  }

  private loginCallback(callbackArgs: LoginCallbackArgs) {
    this.router.navigateByUrl(callbackArgs.defaultRedirect);
  }

  public getAuth(): Auth {
    return this.auth;
  }
}
