import { Component, OnInit } from '@angular/core';
import {
  UsersService,
  ListUserApplicationAccessInfoRequestParams,
  LabelsService,
  ListLabelledObjectsRequestParams,
  ResourceTypeEnum,
  LabelledObject,
  UserFileShareAccessInfo,
  Resource,
} from '@agilicus/angular';
import { Observable, combineLatest, of } from 'rxjs';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { concatMap, map, catchError, switchMap, shareReplay } from 'rxjs/operators';
import { getEmptyResourceComponentState } from '../utils';
import { ResourceType } from '../resource-type.enum';
import { ResourceComponentState } from '../resource-component-state';
import { ResourceAccessInfo } from '@app/resource-access-info-type';

export interface LabelInfo {
  name: string;
  objects: LabelledObject[];
  selected?: boolean;
}

export interface LabelsByAccess {
  granted: LabelInfo[];
  requested: LabelInfo[];
  all: LabelInfo[];
}

@Component({
  selector: 'app-app-launcher',
  templateUrl: './app-launcher.component.html',
  styleUrls: ['./app-launcher.component.scss'],
})
export class AppLauncherComponent implements OnInit {
  public appAccessInfo$: Observable<ResourceComponentState>;
  public labelsInfo$: Observable<LabelsByAccess>;
  private userId = '';
  private userRootOrgId = '';
  private orgIdParam = '';
  public resourceType = ResourceType.application;
  private routerParams$: Observable<Params>;

  constructor(private route: ActivatedRoute, private users: UsersService, private router: Router, private labelsService: LabelsService) {
    this.routerParams$ = this.route.queryParams;
    this.appAccessInfo$ = this.getAppAccessInfo$().pipe(shareReplay(1));
    this.labelsInfo$ = this.appAccessInfo$.pipe(
      switchMap(({ orgIdParam, resourceAccessInfo }) => this.getLabelsInfo$(orgIdParam, resourceAccessInfo)),
      shareReplay(1)
    );
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit(): void {}

  private getLabelsInfo$(orgId: string, resourceAccessInfo: ResourceAccessInfo[]): Observable<LabelsByAccess> {
    const listLabelledObjectsRequestParams: ListLabelledObjectsRequestParams = {
      org_id: orgId,
    };
    const resourceTypesList = Object.values(ResourceTypeEnum);
    if (resourceTypesList.length !== 0) {
      listLabelledObjectsRequestParams.object_types = resourceTypesList;
    }

    return this.labelsService.listLabelledObjects(listLabelledObjectsRequestParams).pipe(
      map((resourceAccessResp) => {
        const labelToObjectMap = new Map<string, LabelledObject[]>();
        for (const object of resourceAccessResp.labelled_objects) {
          for (const label of object.labels) {
            if (!label.status?.navigation?.enabled) {
              continue;
            }
            if (!labelToObjectMap.has(label.label_name)) {
              labelToObjectMap.set(label.label_name, []);
            }
            labelToObjectMap.get(label.label_name).push(object);
          }
        }

        const result = buildLabelAccessMap(labelToObjectMap, resourceAccessInfo);

        result.granted.sort((a, b) => b.objects.length - a.objects.length);
        result.requested.sort((a, b) => b.objects.length - a.objects.length);
        result.all.sort((a, b) => b.objects.length - a.objects.length);
        return result;
      })
    );
  }

  private getAppAccessInfo$(): Observable<ResourceComponentState> {
    return this.routerParams$.pipe(
      concatMap((routerParamsResp) => {
        this.orgIdParam = routerParamsResp.org_id;
        this.userId = routerParamsResp.user_id || localStorage.getItem('user_id');
        const storedOrgId = localStorage.getItem('org_id');
        this.userRootOrgId = storedOrgId !== null ? storedOrgId : '';
        const currentOrgId = this.orgIdParam || this.userRootOrgId;
        const queryParams = { org_id: currentOrgId };

        if (routerParamsResp.vncdialog === 'true') {
          queryParams['vncdialog'] = 'true';
        }

        this.router.navigate(['/applications'], {
          queryParams,
        });
        this.router.navigate([`/${this.resourceType}s`], {
          queryParams,
        });

        const params: ListUserApplicationAccessInfoRequestParams = {
          user_id: this.userId,
          org_id: this.userRootOrgId,
        };

        const appAccess$ = this.users.listUserApplicationAccessInfo(params).pipe(
          map((appAccessresp) => {
            const resourceComponentState: ResourceComponentState = {
              resourceAccessInfo: appAccessresp.user_application_access_info,
              userRootOrgId: this.userRootOrgId,
              userId: this.userId,
              orgIdParam: this.orgIdParam,
              title: '',
            };
            return resourceComponentState;
          })
        );

        const resourceAccess$ = this.users.listUserResourceAccessInfo(params).pipe(
          map((resourceAccessResp) => {
            const resourceComponentState: ResourceComponentState = {
              resourceAccessInfo: resourceAccessResp.user_resource_access_info,
              userRootOrgId: this.userRootOrgId,
              userId: this.userId,
              orgIdParam: this.orgIdParam || localStorage.getItem('org_id'),
              title: '',
            };
            return resourceComponentState;
          })
        );

        const combined$ = combineLatest([appAccess$, resourceAccess$]).pipe(
          map(([appAccess, resourceAccess]) => ({
            resourceAccessInfo: [...appAccess.resourceAccessInfo, ...resourceAccess.resourceAccessInfo],
            userRootOrgId: this.userRootOrgId,
            userId: this.userId,
            orgIdParam: this.orgIdParam || localStorage.getItem('org_id'),
            title: 'Applications',
          }))
        );

        return combined$;
      }),
      catchError((_) => {
        return of(getEmptyResourceComponentState());
      })
    );
  }
}

function isShareAccess(info: any): info is UserFileShareAccessInfo {
  return info?.status?.share_id !== undefined;
}

function getObjectsOfAccessLevel(objects: LabelledObject[], resourceAccessInfo: ResourceAccessInfo[], accessLevel: string) {
  return objects.filter((obj: LabelledObject) =>
    resourceAccessInfo.some((info: ResourceAccessInfo) => {
      const obj_id = isShareAccess(info) ? info.status.share_id : info.status.resource_id;
      return info.status.access_level == accessLevel && obj.org_id === info.status.org_id && obj.object_id === obj_id;
    })
  );
}

function buildLabelAccessMap(labelToObjectMap: Map<string, LabelledObject[]>, resourceAccessInfo: ResourceAccessInfo[]): LabelsByAccess {
  const result: LabelsByAccess = {
    granted: [],
    requested: [],
    all: [],
  };

  for (const [key, val] of labelToObjectMap) {
    const grantedObjects: LabelledObject[] = getObjectsOfAccessLevel(val, resourceAccessInfo, 'granted');
    const requestedObjects: LabelledObject[] = getObjectsOfAccessLevel(val, resourceAccessInfo, 'requested');
    const allObjects: LabelledObject[] = [
      ...grantedObjects,
      ...requestedObjects,
      ...getObjectsOfAccessLevel(val, resourceAccessInfo, 'none'),
    ];

    // We only care about labels of a given type which have resources. Filter them out.
    if (grantedObjects.length > 0) {
      result.granted.push({
        name: key,
        objects: grantedObjects,
      });
    }
    if (requestedObjects.length > 0) {
      result.requested.push({
        name: key,
        objects: requestedObjects,
      });
    }
    if (allObjects.length > 0) {
      result.all.push({
        name: key,
        objects: allObjects,
      });
    }
  }

  return result;
}
