import { catchError, concatMap, from, interval, of, Subject, takeUntil, timeout } from 'rxjs';

export function clipboardCopy(text: string): void {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log('Text copied to local clipboard!');
    })
    .catch((err) => {
      if (!isFocusError(err)) {
        console.error('Failed to copy text: ', err);
      }
    });
}

export class ClipboardSyncher {
  private syncFunc?: any;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private debug: boolean;
  constructor(public UI: any) {
    this.debug = window.localStorage.getItem('debug_vnc_clipboard') === 'true';
  }

  public start() {
    // Do one sync to start so we ensure it's up to date to begin with
    pasteFromLocalToVnc(document.getElementById('noVNC_clipboard_text'), this.UI, this.debug);
    this.syncFunc = this.sync.bind(this);
    window.addEventListener('focus', this.syncFunc);
    if (this.debug) {
      this.debugPoll();
    }
  }

  private debugPoll() {
    console.log('Starting debug poller');
    interval(500)
      .pipe(
        takeUntil(this.unsubscribe$),
        concatMap((_) => {
          return from(navigator.clipboard.readText()).pipe(
            catchError((err: Error) => {
              if (!isFocusError(err)) {
                console.log('error reading clipboard in poller', err);
              }
              return of(undefined);
            })
          );
        })
      )
      .subscribe((text: string | undefined) => {
        if (text === undefined) {
          return;
        }
        const now = new Date().toISOString();
        console.log(`${now}: clipboard is "${text}"`);
      });
  }

  public stop() {
    window.removeEventListener('focus', this.syncFunc);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private async sync(event: FocusEvent): Promise<void> {
    await pasteFromLocalToVnc(document.getElementById('noVNC_clipboard_text'), this.UI, this.debug);
  }
}

function isFocusError(err: Error) {
  return err.toString().includes('Document is not focused');
}

async function pasteFromLocalToVnc(clipboardBuffer, UI, debug: boolean): Promise<void> {
  try {
    const text1 = await navigator.clipboard.readText();
    const readAt = new Date().toISOString();

    await new Promise((resolve) => setTimeout(resolve, 10));
    const text2 = await navigator.clipboard.readText();
    clipboardBuffer.value = text2;

    if (debug) {
      const now = new Date().toISOString();
      console.log(`${now}: Sync remote to "${text2} compare to ${text1} read at ${readAt}"`);
    }
    if (UI.connected) {
      UI.clipboardSend();
    }
  } catch (err) {
    console.error('Failed to read from local clipboard:', err);
  }
}
