import { UserApplicationAccessInfo, UserDesktopAccessInfo, UserResourceAccessInfo } from '@agilicus/angular';
import { ResourceAccess } from '@app/resource-access';
import { ResourceAccessInfo } from '@app/resource-access-info-type';
import { ResourceType } from '@app/resource-type.enum';
import { getDesktopDefaultRole, getFileShareDefaultRole, getIconURIFromResourceDisplayInfo, getLauncherDefaultRole } from '@app/utils';

export interface ResourceAccessFactory {
  makeResourceAccess(info: ResourceAccessInfo): ResourceAccess;
}

export function MakeResourceAccessFactory(): ResourceAccessFactory {
  return new ResourceAccessFactoryDispatcher();
}

abstract class ResourceAccessDecorator {
  abstract decorate(toUpdate: ResourceAccess, baseInfo: ResourceAccessInfo): void;
}

class ResourceAccessFactoryDispatcher implements ResourceAccessFactory {
  private concrete: ResourceAccessDecorator;
  constructor() {
    this.concrete = new ResourceFactory();
  }
  public makeResourceAccess(resourceAccess: ResourceAccessInfo): ResourceAccess {
    const base = this.makeBaseResource(resourceAccess);
    this.concrete.decorate(base, resourceAccess);
    return base;
  }

  private makeBaseResource(resourceAccess: ResourceAccessInfo): ResourceAccess {
    const resourceAccessAsDesktopAccess = resourceAccess as UserDesktopAccessInfo;
    const result: ResourceAccess = {
      user_id: resourceAccess.status.user_id,
      org_id: resourceAccess.status.org_id,
      org_name: resourceAccess.status.org_name,
      parent_org_id: resourceAccess.status.parent_org_id,
      parent_org_name: resourceAccess.status.parent_org_name,
      access_level: resourceAccess.status.access_level,
      id: '',
      name: '',
      resource_url: '',
      resource_type: undefined,
      is_remote_app: !!resourceAccessAsDesktopAccess.status.remote_app?.is_remote_app,
    };

    return result;
  }
}

class ResourceFactory implements ResourceAccessDecorator {
  public decorate(resourceAccessElement: ResourceAccess, resourceAccess: ResourceAccessInfo): void {
    const resourceAccessCopy = resourceAccess as UserApplicationAccessInfo;
    if (resourceAccessCopy.status.application_name) {
      const resourceAccessCopy = resourceAccess as UserApplicationAccessInfo;
      resourceAccessElement.id = resourceAccessCopy.status.resource_id;
      resourceAccessElement.name = resourceAccessCopy.status.application_name;
      resourceAccessElement.resource_url = resourceAccessCopy.status.application_url;
      resourceAccessElement.resource_type = ResourceType.application;
      resourceAccessElement.default_role_name = resourceAccessCopy.status.application_default_role_name;
      const iconUri = resourceAccessCopy.status.display_info
        ? getIconURIFromResourceDisplayInfo(resourceAccessCopy.status.display_info)
        : resourceAccessCopy.status.icon_url;
      resourceAccessElement.icon_url = iconUri !== 'https://storage.googleapis.com/agilicus/logo.svg' ? iconUri : undefined;
      resourceAccessElement.description = resourceAccessCopy.status.application_description;
    } else {
      const resourceAccessCopy = resourceAccess as UserResourceAccessInfo;
      resourceAccessElement.id = resourceAccessCopy.status.resource_id;
      resourceAccessElement.name = resourceAccessCopy.status.resource_name;
      resourceAccessElement.resource_type = resourceAccessCopy.status.resource_type as ResourceType;
      resourceAccessElement.icon_url = getIconURIFromResourceDisplayInfo(resourceAccessCopy.status.display_info) || undefined;
      if (resourceAccessCopy.status.resource_type === 'fileshare') {
        resourceAccessElement.resource_type = ResourceType.share;
      }

      switch (resourceAccessElement.resource_type) {
        case ResourceType.launcher:
          resourceAccessElement.default_role_name = getLauncherDefaultRole();
          break;
        case ResourceType.share:
          resourceAccessElement.default_role_name = getFileShareDefaultRole();
          break;
        case ResourceType.desktop:
        case ResourceType.ssh:
          resourceAccessElement.default_role_name = getDesktopDefaultRole();
          break;
      }
    }
  }
}
