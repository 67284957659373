import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-progress-spinner-dialog',
  templateUrl: './progress-spinner-dialog.component.html',
  styleUrls: ['./progress-spinner-dialog.component.scss'],
})
export class ProgressSpinnerDialogComponent {
  constructor() {}

  @Output() public closeSpinnerEvent = new EventEmitter();

  onClick() {
    this.closeSpinnerEvent.emit();
  }
}
