import { Component, OnInit, ViewChild, Inject, OnDestroy, HostListener } from '@angular/core';
import { NgTerminal } from 'ng-terminal';
import { Terminal } from 'xterm';
import { WebLinksAddon } from 'xterm-addon-web-links';
import { AttachAddon } from 'xterm-addon-attach';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResourceDialogData } from '@app/resource-dialog-data';
import { TokensService } from '@agilicus/angular';
import { AuthService } from '@app/services/auth-service/auth-service.service';
import { Router } from '@angular/router';
import { concatMap, Subject, of, forkJoin, EMPTY, tap, catchError } from 'rxjs';
import { SshCredDialogComponent } from '@app/ssh-cred-dialog/ssh-cred-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { getDefaultDialogConfig } from '@app/dialog-utils';
import { baseTheme, getPath, openWebSsh, authenticate$, AuthResult, performMFA } from '@app/web-ssh-utils';
import { CredData, SSHInfo, SshInfoService } from '@app/services/ssh-info/ssh-info.service';

@Component({
  selector: 'app-web-ssh',
  templateUrl: './web-ssh.component.html',
  styleUrls: ['./web-ssh.component.scss'],
})
export class WebSshComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private socket: WebSocket;
  private underlying: Terminal;
  private messageCount = 0;

  @ViewChild('term', { static: false }) public child: NgTerminal;

  constructor(
    private http: HttpClient,
    private tokensService: TokensService,
    public dialogRef: MatDialogRef<WebSshComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResourceDialogData,
    private authService: AuthService,
    private router: Router,
    private sshCredDialog: MatDialog,
    private dialog: MatDialog,
    private sshInfoService: SshInfoService,
    private challengeDialog: MatDialog
  ) {}

  public ngOnInit() {
    this.startSSH();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private startSSH(credData?: CredData): void {
    let info: SSHInfo;
    this.sshInfoService
      .getSshInformation$(this.data.resourceInfo, credData)
      .pipe(
        concatMap((receivedInfo) => {
          info = receivedInfo;
          if (!info) {
            this.disconnectSsh();
            return EMPTY;
          }
          return of(info);
        }),
        concatMap((info) => {
          const username = this.authService.getAuth().email();
          return forkJoin([of(info), authenticate$(info.apiKey, info.info, username, info.credData, this.http)]);
        }),
        tap(([info, authResult]: [SSHInfo, AuthResult]) => {
          if (authResult.id) {
            this.startSsh(authResult.id, info.apiKey);
          } else {
            this.openWebSshReconnectDialog(authResult.status);
          }
        }),
        catchError((error) => {
          if (error.status === 401) {
            const authResult = error.error;
            if (authResult.do_mfa) {
              performMFA(info.apiKey, this.authService.getAuth().email(), this.tokensService, this.challengeDialog).subscribe(() => {
                this.startSSH(info.credData);
              });
            }
          }
          return EMPTY;
        })
      )
      .subscribe();
  }

  private openWebSshReconnectDialog(status: string): void {
    const data: ResourceDialogData = {
      resourceInfo: this.data.resourceInfo,
      dialogType: 'reconnect',
      status: status,
    };
    const dialogRef = this.sshCredDialog.open(
      SshCredDialogComponent,
      getDefaultDialogConfig({
        data,
        disableClose: true,
      })
    );

    this.disconnectSsh();

    dialogRef.afterClosed().subscribe((status: string) => {
      if (status == 'cancelled') {
        this.disconnectSsh();
      } else {
        openWebSsh(this.data.resourceInfo, this.dialog);
      }
    });
  }

  private startSsh(id: string, apiKey: string) {
    this.underlying = this.child.underlying;
    const auth = this.authService.getAuth();
    this.socket = new WebSocket(getPath(id, apiKey, this.data.resourceInfo.gateway_uri, auth.email()));
    let attachAddon = new AttachAddon(this.socket, { bidirectional: true });
    this.underlying.loadAddon(attachAddon);

    this.socket.onmessage = (msg) => {
      if (this.messageCount < 1 && this.child) {
        this.socket.send(JSON.stringify({ resize: [this.underlying.cols, this.underlying.rows] }));
      }
      this.messageCount++;
    };

    this.socket.onclose = (event) => {
      if (event.wasClean) {
        this.openWebSshReconnectDialog(event.reason);
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        this.openWebSshReconnectDialog('[close] Connection died');
      }
    };

    this.socket.onerror = (error) => {
      this.openWebSshReconnectDialog('ERROR: ' + error);
    };

    this.underlying.options.fontSize = 20;
    this.underlying.loadAddon(new WebLinksAddon());
    this.child.setXtermOptions({
      fontFamily: '"Cascadia Code", Menlo, monospace',
      theme: baseTheme,
      cursorBlink: true,
    });
    this.child.onData().subscribe((input) => {
      this.socket.send(JSON.stringify({ data: input }));
    });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    if (this.child && this.socket) {
      this.socket.send(JSON.stringify({ resize: [this.underlying.cols, this.underlying.rows] }));
    }
  }

  public openSshInNewTab() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/web-ssh/' + this.data.resourceInfo.id]));
    window.open(url, '_blank');
  }

  public disconnectSsh(): void {
    this.dialogRef.close();
  }
}
