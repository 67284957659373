<div *ngIf="state" class="app-launch-container">
  <div *ngIf="!!orgIdParam && isInitialized">
    <div *ngIf="userId" class="row header">
      
      <div style="display: inline-block;">
        <div *ngIf="orgIdParam === getCurrentParentOrgId(state)">
          <div
            *ngIf="appIconFolderStructureRef?.labelClicked; else homeButton"
            class="return-to-parent-org-container"
          >
            <span 
              style="text-decoration: underline;cursor: pointer;"
              type="button"
              mat-raised-button
              color="primary"
              class="return-to-parent-org-button"
              aria-label="Button to return one level up the label hierarchy"
              (click)="appIconFolderStructureRef?.goBack()"
            >
              Home({{ parentOrgName$ | async }})
            </span>
            <span>
              / {{ appIconFolderStructureRef?.selectedLabel }}
            </span>
          </div>
          <ng-template #homeButton>
            <div class="return-to-parent-org-container">
              <span
                type="button"
                mat-raised-button
                color="primary"
                class="return-to-parent-org-button">
                Home({{ parentOrgName$ | async }})
              </span>
            </div>
          </ng-template>
        </div>
        
        <div *ngIf="orgIdParam !== getCurrentParentOrgId(state)">
          <div class="return-to-parent-org-container">
            <span
              style="text-decoration: underline;cursor: pointer;"
              type="button"
              mat-raised-button
              color="primary"
              class="return-to-parent-org-button"
              aria-label="Button to return one level up the organizational hierarchy"
              (click)="returnToParentOrg(resourceType, state)">
              Home({{ parentOrgName$ | async }}) /
            </span>
            <span *ngIf="appIconFolderStructureRef?.labelClicked; else orgName">
              <span 
                style="text-decoration: underline;cursor: pointer;"
                type="button"
                mat-raised-button
                color="primary"
                class="return-to-parent-org-button"
                aria-label="Button to return one level up the label hierarchy"
                (click)="appIconFolderStructureRef?.goBack()"
              >
                {{ capitalizeFirstLetter(getOrgNameFromOrgId(orgIdParam)) }}
              </span>
              <span>
                / {{ appIconFolderStructureRef?.selectedLabel }}
              </span>
            </span>
            <ng-template #orgName>
              <span>
                {{ capitalizeFirstLetter(getOrgNameFromOrgId(orgIdParam)) }}
              </span>
            </ng-template>
          </div>
        </div>  
      </div>      

      <div class="filter-container">
        <button
          style="vertical-align: middle;"
          mat-icon-button
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="filterMenu"
          aria-label="Icon-button that displays a filter options menu"
          matTooltip="Click to view filter options"
        >
          <mat-icon class="filter-list-icon">filter_list</mat-icon>
        </button>
      
        <mat-menu #filterMenu="matMenu">
          <button mat-menu-item disabled>
            <span>Filter by:</span>
          </button>
          <ng-container>
            <ng-container class="resource-type-filter">
              <button
                mat-menu-item
                [matMenuTriggerFor]="sub_menu1"
              >
                <mat-icon>people</mat-icon>
                <span>Resource Type</span>
              </button>
              <mat-menu #sub_menu1="matMenu">
                <button
                  *ngFor="let type of types"
                  mat-menu-item
                  (keydown.arrowleft)="$event.stopPropagation()"
                >
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    [(ngModel)]="selectedTypes[type]"
                    (ngModelChange)="onSelectedTypesChange()"
                  >
                    {{ capitalizeFirstLetter(type) }}
                  </mat-checkbox>
                </button>
              </mat-menu>
            </ng-container>
            <ng-container
              *ngIf="getLabelsForFilter(state).length > 0 && !appIconFolderStructureRef?.labelClicked"
              class="label-filter"
            >
              <button
                mat-menu-item
                [matMenuTriggerFor]="sub_menu2"
              >
                <mat-icon>label_important</mat-icon>
                <span>Label</span>
              </button>
              <mat-menu #sub_menu2="matMenu">
                <button
                  *ngFor="let option of getLabelsForFilter(state)"
                  mat-menu-item
                  (keydown.arrowleft)="$event.stopPropagation()"
                >
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    [checked]="option.selected"
                    (change)="onLabelCheckboxChange(option, state)"
                  >
                    {{ capitalizeFirstLetter(option.name) }}
                  </mat-checkbox>
                </button>
              </mat-menu>
            </ng-container>
          </ng-container>
        </mat-menu>
        <mat-form-field class="filter-field">
          <mat-icon
            matPrefix
            class="search-icon"
          >
            search
          </mat-icon>
          <input
            #tableSearchInput
            matInput
            class="filter-input"
            [(ngModel)]="searchTerm"
            (ngModelChange)="onSearchTermChange()"
            placeholder="Search..."
          >
          <button
            *ngIf="searchTerm"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearSearch()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>        
    </div>

    <div *ngIf="userId" class="row content">
      <mat-tab-group
        [headerPosition]="getTabHeaderPositionFromScreenSize()"
        mat-stretch-tabs
        animationDuration="0ms"
        class="app-group-tabs"
        [selectedIndex]="selectedTab"
        (selectedTabChange)="onTabChange($event.index)"
      >
        <!-- Mine Tab -->
        <mat-tab>
          <ng-template mat-tab-label>
            <span [ngClass]="{ 'small-label': isSmallScreen }">Mine</span>
            <mat-icon
              *ngIf="!isSmallScreen"
              class="help-icon"
              [matTooltip]="'These are the ' + resourceType + 's you have access to'"
            >
              help_outline
            </mat-icon>
          </ng-template>

          <app-app-icon-folder-structure
            *ngIf="selectedTab === 0"
            #appIconFolderStructureRef
            [folderList]="getOrgFolders(state.grantedResources)"
            [resourceList]="getOrgResources(state.grantedResources)"
            [labelsList]="getSelectedLabels(grantedLabels)"
            [resourcesNotInLabels]="getResourcesNotInLabels(getOrgResources(state.grantedResources))"
            [isSmallScreen]="isSmallScreen"
            [resourceType]="resourceType"
            [selectedTypes]="selectedTypes"
            [showStatusIcons]="showMineTabStatusIcons"
            [parentOrg]="parentOrgName$ | async"
            [searchFilterTextValue]="searchFilterTextValue"
            (updateRequestIcons)="refreshResourceState()"
          >
          </app-app-icon-folder-structure>

          <div class="empty-view" *ngIf="!doesUserHaveResourcesInAnyOrg(state.grantedResources)">
            <h2>{{ getNoAssignedResourcesMessageText() }}</h2>
          </div>
        </mat-tab>

        <!-- Requested Tab -->
        <mat-tab [disabled]="state.requestFlowDisabled">
          <ng-template mat-tab-label>
            <span [ngClass]="{ 'small-label': isSmallScreen }">Requested</span>
            <mat-icon
              *ngIf="!isSmallScreen"
              class="help-icon"
              [matTooltip]="'These are the ' + resourceType + 's you have requested access to'"
            >
              help_outline
            </mat-icon>
          </ng-template>

          <app-app-icon-folder-structure
            *ngIf="selectedTab === 1"
            #appIconFolderStructureRef
            [folderList]="getOrgFolders(state.requestedResources)"
            [resourceList]="getOrgResources(state.requestedResources)"
            [labelsList]="getSelectedLabels(requestedLabels)"
            [resourcesNotInLabels]="getResourcesNotInLabels(getOrgResources(state.requestedResources))"
            [isSmallScreen]="isSmallScreen"
            [resourceType]="resourceType"
            [selectedTypes]="selectedTypes"
            [parentOrg]="parentOrgName$ | async"
            [searchFilterTextValue]="searchFilterTextValue"
            (updateRequestIcons)="refreshResourceState()"
          >
          </app-app-icon-folder-structure>
        </mat-tab>

        <!-- All Tab -->
        <mat-tab [disabled]="state.requestFlowDisabled">
          <ng-template mat-tab-label>
            <span [ngClass]="{ 'small-label': isSmallScreen }">All</span>
            <mat-icon
              *ngIf="!isSmallScreen"
              class="help-icon"
              [matTooltip]="'All existing ' + resourceType + 's'"
            >
              help_outline
            </mat-icon>
          </ng-template>

          <app-app-icon-folder-structure
            *ngIf="selectedTab === 2"
            #appIconFolderStructureRef
            [folderList]="getOrgFolders(state.allResources)"
            [resourceList]="getOrgResources(state.allResources)"
            [labelsList]="getSelectedLabels(allLabels)"
            [resourcesNotInLabels]="getResourcesNotInLabels(getOrgResources(state.allResources))"
            [isSmallScreen]="isSmallScreen"
            [resourceType]="resourceType"
            [selectedTypes]="selectedTypes"
            [parentOrg]="parentOrgName$ | async"
            [searchFilterTextValue]="searchFilterTextValue"
            (updateRequestIcons)="refreshResourceState()"
          >
          </app-app-icon-folder-structure>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
