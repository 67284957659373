import { Organisation, OrganisationsService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { arrayBufferToStr, stringToArrayBufferU8 } from '@app/utils';
import { Observable, catchError, map, of } from 'rxjs';

export interface BaseResult<T> {
  state?: T;
  pkc: PublicKeyCredential;
}
export interface RegistrationResult<T> extends BaseResult<T> {}

export interface ChallengeResult<T> extends BaseResult<T> {}
export interface RegistrationParams<T> {
  url: string;
  publicKeyCredentialCreationOptions: PublicKeyCredentialCreationOptions;
  redirectUri: string;
  state?: T;
}

export interface ChallengeParams<T> {
  url: string;
  publicKeyCredentialRequestOptions: PublicKeyCredentialRequestOptions;
  redirectUri: string;
  state?: T;
}

function bufferToStringReplacer(key: string, value: any): any {
  if (value instanceof ArrayBuffer) {
    return arrayBufferToStr(value);
  }
  return value;
}

function bufferToStringReplacerURLSafe(key: string, value: any): any {
  if (value instanceof ArrayBuffer) {
    return arrayBufferToStr(value).replace(/\+/g, '-').replace(/_/g, '/').replace(/=*$/g, '');
  }
  return value;
}

const bufferKeys = {
  attestationObject: true,
  authenticatorData: true,
  signature: true,
};

function pkcDeserializer(key: string, value: any): any {
  if (bufferKeys[key] === true) {
    return stringToArrayBufferU8(window.atob((value as string).replace(/-/g, '+').replace(/_/g, '/')));
    // return stringToArrayBufferU8(window.atob(value))
  } else if (key === 'clientDataJSON') {
    // The clientDataJSON is a base64 encoded json object. Convert it into a json string then conver that to an
    // array buffer (which appears to be what our infrastructure expects)
    return stringToArrayBufferU8(window.atob(value));
  }
  return value;
}

@Injectable({
  providedIn: 'root',
})
export class HttpWebauthnService {
  private registrationKey: string = 'webauth-registration';
  private challengeKey: string = 'webauth-challenge';
  private urlFeatureFlag: string = 'http-webauthn-url';
  constructor(private orgsService: OrganisationsService) {}

  public register<T>(params: RegistrationParams<T>): void {
    const url = new URL(params.url);
    const asJSON = JSON.stringify(params.publicKeyCredentialCreationOptions, bufferToStringReplacer);
    url.searchParams.set('pkcco', btoa(asJSON));
    url.searchParams.set('redirect_uri', params.redirectUri);

    if (params.state !== undefined) {
      const stateKey = this.setState(this.registrationKey, params.state);
      url.searchParams.set('state', stateKey);
    }

    window.location.href = url.toString();
  }

  private setState<T>(purpose: string, state: T): string {
    const stateVarDate = (Date.now() / 1000).toFixed(0).toString();
    const stateVar = `${purpose}-${stateVarDate}`;
    localStorage.setItem(stateVar, JSON.stringify(state));
    return stateVarDate;
  }

  private getState<T>(purpose: string, stateKey: string): T | undefined {
    const stateVar = `${purpose}-${stateKey}`;
    const raw = localStorage.getItem(stateVar);
    if (raw === undefined) {
      return undefined;
    }

    localStorage.removeItem(stateVar);

    try {
      return JSON.parse(raw);
    } catch (e) {
      console.log(`invalid state var ${stateVar}: ${e}`);
      return undefined;
    }
  }

  private completeCallback<T>(keyPrefix: string, stateKey: string, pkcStr: string): BaseResult<T> {
    const state = this.getState<T>(keyPrefix, stateKey);

    const pkc: PublicKeyCredential = JSON.parse(atob(pkcStr), pkcDeserializer);
    const result: RegistrationResult<T> = {
      state: state,
      pkc: pkc,
    };

    return result;
  }

  public completeRegistration<T>(stateKey: string, pkcStr: string): Observable<RegistrationResult<T>> {
    return of(this.completeCallback<T>(this.registrationKey, stateKey, pkcStr));
  }

  public challenge<T>(params: ChallengeParams<T>): void {
    const url = new URL(params.url);
    const asJSON = JSON.stringify(params.publicKeyCredentialRequestOptions, bufferToStringReplacerURLSafe);
    url.searchParams.set('pkcro', btoa(asJSON));
    url.searchParams.set('redirect_uri', params.redirectUri);

    if (params.state !== undefined) {
      const stateKey = this.setState(this.challengeKey, params.state);
      url.searchParams.set('state', stateKey);
    }

    window.location.href = url.toString();
  }

  public completeChallenge<T>(stateKey: string, pkcStr: string): Observable<ChallengeResult<T>> {
    return of(this.completeCallback<T>(this.challengeKey, stateKey, pkcStr));
  }

  public getURL$(orgId: string | undefined): Observable<undefined | string> {
    if (!orgId) {
      return of(undefined);
    }
    return this.orgsService.getOrg({ org_id: orgId }).pipe(
      catchError((err: any) => {
        console.log(`failed to fetch user organisation: ${err}`);
        return undefined;
      }),
      map((org: Organisation | undefined) => {
        const features = org.feature_flags;
        if (!features) {
          return undefined;
        }

        const matching = features.find((flag) => {
          if (!flag.enabled) {
            return false;
          }

          return flag.feature === this.urlFeatureFlag && flag.setting && flag.setting.length > 0;
        });
        return matching?.setting;
      })
    );
  }
}
