export function getCurrentDate(): Date {
  return new Date();
}

export function addSecondsToDate(seconds: number, startDate: Date): Date {
  startDate.setSeconds(startDate.getSeconds() + seconds);
  return startDate;
}

/**
 * May not actually be a "Date" from the sdk, so do conversion here
 */
export function ensureDateIsDateType(date: Date | string): Date {
  return new Date(date);
}

export function subtractSecondsFromDate(seconds: number, date: Date): Date {
  const resultAsDate = ensureDateIsDateType(date);
  resultAsDate.setSeconds(date.getSeconds() - seconds);
  return resultAsDate;
}

export function convertDaysToSeconds(days: number): number {
  return days * 86400;
}

export function convertSecondsToDays(seconds: number): number {
  return seconds / 86400;
}

export function getFutureDateFromSeconds(seconds: number): Date {
  const date = getCurrentDate();
  return addSecondsToDate(seconds, date);
}

export function isDateExpired(expiryDate: Date): boolean {
  // Need to convert the UTC date to local time for comparison:
  return new Date(expiryDate.toString()) < new Date();
}

export function convertDateToReadableFormat(targetDate: Date): string {
  const convertedDate = new Date(targetDate);
  return convertedDate.toLocaleString('sv', { timeZoneName: 'short' });
}

/**
 * Converts to date in format yyyy/mm/dd
 */
export function convertDateToShortReadableFormat(targetDate: Date): string {
  const convertedDate = new Date(targetDate);
  return convertedDate.toLocaleDateString('sv');
}

/**
 * Returns true if the given date is less than or equal to N seconds ago.
 */
export function dateIsWithinDurationAgo(date: Date, durationSeconds: number): boolean {
  const dateAsDate = ensureDateIsDateType(date);
  const deltaMilliseconds = new Date().getTime() - dateAsDate.getTime();
  return deltaMilliseconds <= durationSeconds * 1000;
}
