import {
  Challenge,
  ChallengesService,
  GetAnswerRequestParams,
  ChallengeAnswer,
  WebAuthNEnrollment,
  GetChallengeRequestParams,
  ChallengeStatus,
} from '@agilicus/angular';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getIgnoreErrorsHeader, getIgnoreErrorsKey, getIgnoreErrorsValue } from '@app/http-interceptors/http-interceptor-utils';
import {
  Observable,
  firstValueFrom,
  from,
  forkJoin,
  of,
  takeUntil,
  concatMap,
  Subject,
  map,
  interval,
  catchError,
  retry,
  throwError,
} from 'rxjs';
import { ChallengeType } from '@app/mfa-enroll/mfa-enroll.component';
import { getMfaBrandLogoImagePath, getTestEnvironmentProfileUrlOriginValue } from '@app/utils';
import { AppInitService } from '@app/app.init';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

export interface MFAChallengeDialogData {
  challenge: Challenge;
  webauthnEnrollmentList: Array<WebAuthNEnrollment>;
  challengeDescription?: string;
}

interface MFAChallengeForm {
  totp_auth_code: FormControl<string>;
}
@Component({
  selector: 'app-mfa-challenge-dialog',
  templateUrl: './mfa-challenge-dialog.component.html',
  styleUrls: ['./mfa-challenge-dialog.component.scss'],
})
export class MfaChallengeDialogComponent implements OnInit, OnDestroy {
  public form: FormGroup<MFAChallengeForm>;
  public failed: boolean;
  public challengeType = ChallengeType;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public logoImagePath = getMfaBrandLogoImagePath(this.appInitService);
  private customThemeStyleCssId = 'myCustomThemeStyleCss';

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MfaChallengeDialogComponent>,
    private challenges: ChallengesService,
    @Inject(MAT_DIALOG_DATA) public data: MFAChallengeDialogData,
    private appInitService: AppInitService,
    private http: HttpClient
  ) {}

  public ngOnInit(): void {
    this.setCustomThemeCss();
    this.initializeFormGroup();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setCustomThemeCss(): void {
    let headers = new HttpHeaders();
    headers = headers.append(getIgnoreErrorsKey(), getIgnoreErrorsValue());

    this.http
      .get(`${window.location.origin}/.well-known/themes.zip/theme/styles.css`, { headers, responseType: 'text' })
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError((err: HttpErrorResponse) => {
          if (err instanceof HttpErrorResponse && err.status === 404 && !!err.statusText && err.statusText.toLowerCase() === 'not found') {
            return of(undefined);
          }
          return throwError(() => err);
        })
      )
      .subscribe(
        (data) => {
          this.appendCustomThemeCssToHtmlHead(data);
        },
        (err) => {
          // Apply default style:
          this.appendCustomThemeCssToHtmlHead(undefined);
        }
      );
  }

  private appendCustomThemeCssToHtmlHead(cssFileAsString: string | undefined): void {
    let customCss = cssFileAsString;
    if (!cssFileAsString) {
      customCss = this.getDefaultThemeCss();
    }
    if (!!document.getElementById(this.customThemeStyleCssId)) {
      // Style already applied
      return;
    }
    const head = document.head || document.getElementsByTagName('head')[0];
    const style: HTMLStyleElement = document.createElement('style');
    head.appendChild(style);
    style.id = this.customThemeStyleCssId;
    style.type = 'text/css';
    let updatedCss = this.addFullUrlPathsToCss(customCss);
    // Fix to resolve the default navbar height = 100%:
    updatedCss = `${updatedCss} .theme-navbar {height: auto !important;}`;
    style.textContent = updatedCss;
  }

  /**
   * We need to convert the url references in the css file to the full path.
   */
  private addFullUrlPathsToCss(cssString: string): string {
    // This is so the css links work when serving locally:
    let urlOrigin = getTestEnvironmentProfileUrlOriginValue(this.appInitService);
    if (!urlOrigin) {
      urlOrigin = window.location.origin;
    }
    let updatedCss = cssString;

    // Replace instances of `"/static/font`... with the full url path
    const staticFontRegexOne = /url\(\"\/static\/font/g;
    const staticFontOneReplacementString = `url("${urlOrigin}/.well-known/themes.zip/static/font`;
    updatedCss = updatedCss.replace(staticFontRegexOne, staticFontOneReplacementString);

    // Replace instances of `'/static/font`... with the full url path
    const staticFontRegexTwo = /url\(\'\/static\/font/g;
    const staticFontTwoReplacementString = `url('${urlOrigin}/.well-known/themes.zip/static/font`;
    updatedCss = updatedCss.replace(staticFontRegexTwo, staticFontTwoReplacementString);

    // Replace instances of the theme images with the full url path.
    // The regex matches when `url(` is not followed by a `" or '`.
    const myCustomThemeUrlWithoutQuotesRegex = /url\((?!\"|\')/g;
    const myCustomThemeUrlWithoutQuotesReplacementString = `url(${urlOrigin}/.well-known/themes.zip/theme/`;
    updatedCss = updatedCss.replace(myCustomThemeUrlWithoutQuotesRegex, myCustomThemeUrlWithoutQuotesReplacementString);

    // Replace instances of the theme images with the full url path.
    // The regex matches when `url(` is followed by a `"`, but not `"http`.
    const myCustomThemeUrlWithDoubleQuoteRegex = /url\(\"(?!http)/g;
    const myCustomThemeUrlWithDoubleQuoteReplacementString = `url("${urlOrigin}/.well-known/themes.zip/theme/`;
    updatedCss = updatedCss.replace(myCustomThemeUrlWithDoubleQuoteRegex, myCustomThemeUrlWithDoubleQuoteReplacementString);

    // Replace instances of the theme images with the full url path.
    // The regex matches when `url(` is followed by a `'`, but not `'http`.
    const myCustomThemeUrlWithSingleQuoteRegex = /url\(\'(?!http)/g;
    const myCustomThemeUrlWithSingleQuoteReplacementString = `url('${urlOrigin}/.well-known/themes.zip/theme/`;
    updatedCss = updatedCss.replace(myCustomThemeUrlWithSingleQuoteRegex, myCustomThemeUrlWithSingleQuoteReplacementString);

    return updatedCss;
  }

  private initializeFormGroup(): void {
    const defaults: MFAChallengeForm = {
      totp_auth_code: new FormControl(''),
    };
    this.form = this.fb.group(defaults);
    interval(5000)
      .pipe(
        concatMap((_) => {
          const getChallengeParams: GetChallengeRequestParams = {
            challenge_id: this.data.challenge.metadata.id,
          };
          return this.challenges.getChallenge(getChallengeParams);
        }),
        catchError((err) => {
          return of(undefined);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((result) => {
        if (!result) {
          this.dialogRef.close('error');
        }

        if (result.status?.state === ChallengeStatus.StateEnum.timed_out) {
          this.dialogRef.close('timedout');
        }
      });
  }

  public async submitTotpChallenge(): Promise<void> {
    this.failed = false;
    const code = this.form.get('totp_auth_code').value;
    const challengeAnswerParams: GetAnswerRequestParams = {
      challenge_id: this.data.challenge.metadata.id,
      challenge_answer: code,
      challenge_uid: this.data.challenge.spec.user_id,
      challenge_type: 'totp',
      allowed: true,
    };

    try {
      const answer = await firstValueFrom(this.challenges.getAnswer(challengeAnswerParams, 'body', getIgnoreErrorsHeader()));
      this.dialogRef.close('success');
      return;
    } catch (error) {
      console.log(error);
      this.failed = true;
      return;
    }
  }

  public closeDialog(): void {
    this.dialogRef.close('cancelled');
  }

  public isSupportedWebauthn(mfaMethod: ChallengeType): boolean {
    return this.data.challenge.spec.challenge_types.includes(mfaMethod) && this.data.webauthnEnrollmentList.length > 0;
  }

  private encodeCredentialIdFromEndpoint(endpointId: string): ArrayBuffer | undefined {
    const credentialId = this.getDeviceFromEndpoint(endpointId)?.status?.credential_id;
    if (credentialId) {
      return this.stringToArrayBufferU8(atob(credentialId.replace(/_/g, '/').replace(/-/g, '+')));
    }
    return undefined;
  }

  private stringToBufBase(buf: ArrayBuffer, bufView: Uint8Array | Uint16Array, str: string): ArrayBuffer {
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  private stringToArrayBufferU8(str: string): ArrayBuffer {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    return this.stringToBufBase(buf, bufView, str);
  }

  private arrayBufferToStr(buf: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buf);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  private removeProfileFromHostname(hostname: string): string {
    if (hostname.startsWith('profile.')) {
      return hostname.slice(8);
    }
    return hostname;
  }

  private getDeviceFromEndpoint(endpointId: string): WebAuthNEnrollment | undefined {
    return this.data.webauthnEnrollmentList?.find((device) => device.metadata?.id === endpointId);
  }

  private getCredInterface$(encodedCredentialIds: ArrayBuffer[]): Observable<Credential> {
    const allowCredentials: PublicKeyCredentialDescriptor[] = encodedCredentialIds.map((encodedCredentialId) => {
      return {
        type: 'public-key',
        id: encodedCredentialId,
      };
    });
    const getCredentialDefaultArgs: PublicKeyCredentialRequestOptions = {
      rpId: this.removeProfileFromHostname(window.location.hostname),
      allowCredentials: allowCredentials,
      challenge: this.stringToArrayBufferU8(this.data.challenge.status.public_challenge),
      timeout: 60000,
      userVerification: 'discouraged',
    };

    return from(navigator.credentials.get({ publicKey: getCredentialDefaultArgs }));
  }

  private getChallengeAnswer$(credInterface: Credential): Observable<ChallengeAnswer> {
    const cred = credInterface as PublicKeyCredential;
    const pkResponse = cred.response as AuthenticatorAssertionResponse;
    let userHandle = '';
    if (pkResponse.userHandle) {
      userHandle = this.arrayBufferToStr(pkResponse.userHandle);
    }
    const val = {
      user_id: this.data.challenge.spec.user_id,
      credential_id: cred.id,
      client_data: this.arrayBufferToStr(pkResponse.clientDataJSON),
      authenticator_data: this.arrayBufferToStr(pkResponse.authenticatorData),
      signature: this.arrayBufferToStr(pkResponse.signature),
      user_handle: userHandle,
    };
    const challengeAnswerParams: GetAnswerRequestParams = {
      allowed: true,
      challenge_type: ChallengeType.webauthn,
      challenge_answer: JSON.stringify(val),
      challenge_uid: this.data.challenge.spec.user_id,
      challenge_id: this.data.challenge.metadata.id,
    };
    return this.challenges.getAnswer(challengeAnswerParams);
  }

  private invokeWebAuthn$(endpointIds: string[]): Observable<[Credential, ChallengeAnswer]> {
    const encodedCredentialIds = endpointIds.map((endpointId) => {
      return this.encodeCredentialIdFromEndpoint(endpointId);
    });

    if (encodedCredentialIds.length === 0) {
      return forkJoin([of(undefined), of(undefined)]);
    }

    return this.getCredInterface$(encodedCredentialIds).pipe(
      concatMap((credInterfaceResp) => {
        if (!credInterfaceResp) {
          return forkJoin([of(undefined), of(undefined)]);
        }

        return this.getChallengeAnswer$(credInterfaceResp).pipe(
          map((challengeAnswer) => [credInterfaceResp, challengeAnswer] as [Credential, ChallengeAnswer])
        );
      })
    );
  }

  public handleWebAuthNChallenge(): void {
    const endpoints = this.data.webauthnEnrollmentList.map((item) => item.metadata.id);
    this.invokeWebAuthn$(endpoints)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (ChallengeAnswer) => {
          this.dialogRef.close('success');
          return;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  private getDefaultThemeCss(): string {
    return `
    .alert {
      color: red;
    }
    
    .theme-body {
      background-color: #efefef;
      color: #333;
      text-align: left;
      position: fixed;
      width: 100%;
      top: 0;
    }
    
    .theme-div {
      color: #333;
      text-align: left;
    }
    
    .theme-div-error {
      color: red;
      text-align: left;
    }
    
    .theme-navbar {
      background-color: #ffffff;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      color: #333;
      font-size: 13px;
      font-weight: 100;
      // height: 100%;
      overflow: hidden;
      padding: 10px 10px;
    }
    
    .theme-navbar__logo-wrap {
      display: inline-block;
      height: 100%;
      overflow: hidden;
      width: 300px;
    }
    
    .theme-navbar__logo {
      height: 100%;
      width: 200px;
    }
    
    .theme-heading {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
      margin-top: 0;
      text-align: center;
    }
    
    .theme-panel {
      background-color: #fff;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      padding: 30px;
    }
    
    .theme-btn-provider {
      background-color: #fff;
      color: #333;
      min-width: 250px;
    }
    
    .theme-btn-provider:disabled {
      background-color: #fff;
      color: grey;
      min-width: 250px;
    }
    
    .theme-btn-provider:hover:enabled {
      color: #999;
    }
    
    .theme-btn--primary {
      background-color: #0057b8;
      border: none;
      color: #fff;
      min-width: 75px;
      padding: 6px 12px;
      border-radius: 4px;
      margin-left: 10px;
      cursor: pointer;
    }
    
    .theme-btn--primary:hover {
      background-color: #0057b8;
      color: #fff;
    }
    
    .theme-btn--disabled {
      background-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
      border: none;
      min-width: 75px;
      padding: 6px 12px;
    }
    
    .theme-btn--success {
      background-color: #a3ff8a;
      color: #fff;
      width: 250px;
    }
    
    .theme-btn--success:hover {
      background-color: #a3ff8a;
    }
    
    .theme-form-row {
      display: block;
      margin: 20px auto;
    }
    
    .theme-form-input {
      border-radius: 4px;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      color: #666;
      display: block;
      font-size: 14px;
      // height: 36px;
      line-height: 1.42857143;
      margin: auto;
      padding: 6px 12px;
      width: 250px;
    }
    
    .theme-form-input:focus,
    .theme-form-input:active {
      border-color: #66afe9;
      outline: none;
    }
    
    .theme-form-input-non-blocking {
      border-radius: 4px;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      color: #666;
      font-size: 14px;
      // height: 36px;
      line-height: 1.42857143;
      padding: 6px;
      width: 180px;
    }
    
    .theme-form-input-non-blocking:focus,
    .theme-form-input-non-blocking:active {
      border-color: #66afe9;
      outline: none;
    }
    
    .theme-form-label {
      font-size: 13px;
      font-weight: 600;
      margin: 4px auto;
      position: relative;
      text-align: left;
      width: 250px;
    }
    
    .theme-link-back {
      margin-top: 4px;
    }
    
    input#rememberMeCheckbox {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      transform: scale(1);
      animation: pulse 2s infinite;
    }
    
    input#selectAccountCheckbox {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      transform: scale(1);
    }
    
    @keyframes pulse {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
      }
    
      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      }
    
      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }
    
    /* Tooltip container */
    .tooltip {
      position: relative;
    }
    
    /* Tooltip text */
    .tooltip .tooltiptext {
      visibility: hidden;
      width: 200px;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
    
      /* Position the tooltip text */
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      margin-left: -120px;
    
      /* Fade in tooltip */
      opacity: 0;
      transition: opacity 0.3s;
    }
    
    /* Tooltip arrow */
    .tooltip .tooltiptext::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
    
    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
    `;
  }
}
