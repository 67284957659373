import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APIKey, ListApiKeysRequestParams, ReplaceApiKeyRequestParams, TokensService } from '@agilicus/angular';
import { ResourceDialogData } from '../resource-dialog-data';
import { Router } from '@angular/router';
import { getApiKey, getAPIKeyLabel, getAPIKeyName, getDefaultApiKeyDurationInSeconds } from '@app/utils';
import { getFileShareUrlForLink } from '@app/utils/resource.utils';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, map, takeUntil } from 'rxjs/operators';
import { NotificationService } from '@app/notifications/notification.service';
import { ResourceType } from '@app/resource-type.enum';
import { getFutureDateFromSeconds, isDateExpired } from '@app/date-utils';

@Component({
  selector: 'app-share-mount-instructions-dialog',
  templateUrl: './share-mount-instructions-dialog.component.html',
  styleUrls: ['./share-mount-instructions-dialog.component.scss'],
})
export class ShareMountInstructionsDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public apiKey = '';

  constructor(
    private tokensService: TokensService,
    private changeDetector: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ShareMountInstructionsDialogComponent>,
    private router: Router,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: ResourceDialogData
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getFileShareUrlForLink(): string {
    const url = this.data.resourceInfo.resource_url;
    return getFileShareUrlForLink(url);
  }

  public setApiKey(): void {
    getApiKey({
      userId: this.data.resourceInfo.user_id,
      orgId: this.data.resourceInfo.org_id,
      resourceId: this.data.resourceInfo.id,
      resourceName: this.data.resourceInfo.name,
      tokensService: this.tokensService,
    }).subscribe((apiKeyResp) => {
      this.apiKey = apiKeyResp;
      this.changeDetector.detectChanges();
    });
  }

  private getAPIKeysByNameAndLabel$(): Observable<Array<APIKey>> {
    const listApiKeysRequestParams: ListApiKeysRequestParams = {
      user_id: this.data.resourceInfo.user_id,
      org_id: this.data.resourceInfo.org_id,
      name: getAPIKeyName(this.data.resourceInfo.name),
      label: getAPIKeyLabel(ResourceType.share),
      valid_at: 'now',
    };
    return this.tokensService.listApiKeys(listApiKeysRequestParams).pipe(
      map((apiKeysResp) => {
        return apiKeysResp?.api_keys;
      })
    );
  }

  private refreshAPIKey$(apiKey: APIKey): Observable<APIKey> {
    apiKey.spec.expiry = getFutureDateFromSeconds(getDefaultApiKeyDurationInSeconds());
    const replaceApiKeysRequestParams: ReplaceApiKeyRequestParams = {
      api_key_id: apiKey.metadata.id,
      APIKey: apiKey,
    };
    return this.tokensService.replaceApiKey(replaceApiKeysRequestParams);
  }

  private refreshAPIKeys$(apiKeys: Array<APIKey>): Observable<Array<APIKey>> {
    const observablesArray: Array<Observable<APIKey>> = [];
    for (const apiKey of apiKeys) {
      if (!!apiKey.spec.expiry && isDateExpired(apiKey.spec.expiry)) {
        continue;
      }
      observablesArray.push(this.refreshAPIKey$(apiKey));
    }
    return forkJoin(observablesArray);
  }

  private refreshAllActiveAPIKeys$(): Observable<Array<APIKey>> {
    return this.getAPIKeysByNameAndLabel$().pipe(
      concatMap((apiKeysResp) => {
        if (!apiKeysResp) {
          return of(undefined);
        }
        if (apiKeysResp.length === 0) {
          return of([]);
        }
        return this.refreshAPIKeys$(apiKeysResp);
      })
    );
  }

  public onRefreshAllActiveAPIKeys(): void {
    this.refreshAllActiveAPIKeys$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          if (!resp) {
            this.notificationService.error(
              `Failed to retrieve the access tokens for share "${this.data.resourceInfo.name}". Please try again.`
            );
          }
          if (resp.length === 0) {
            this.notificationService.error(`Share "${this.data.resourceInfo.name}" has no active access tokens. Please create a new one.`);
          } else {
            this.notificationService.success('Access tokens were successfully refreshed');
          }
        },
        (errorResp) => {
          this.notificationService.error('Failed to refresh all selected access tokens');
        }
      );
  }

  public getLinuxMountCommand(): string {
    return `sudo mount -t davfs -o noexec,uid=$(id -u),gid=$(id -g) ${this.getFileShareUrlForLink()} ${this.getSharePath()}`;
  }

  public getSharePath(): string {
    return `/mnt/${this.data.resourceInfo.name}`;
  }
}
