import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateUserMessageRequestParams, MessagesService } from '@agilicus/angular';
import { ResourceDialogData } from '../resource-dialog-data';
import { take } from 'rxjs/operators';
import { GeolocationService, WA_GEOLOCATION_SUPPORT } from '@ng-web-apis/geolocation';
import { NotificationService } from '@app/notifications/notification.service';
import { Subject } from 'rxjs';
import { AppInitService } from '@app/app.init';

@Component({
  selector: 'app-internal-application-dialog',
  templateUrl: './internal-application-dialog.component.html',
  styleUrls: ['./internal-application-dialog.component.scss'],
})
export class InternalApplicationDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public userName: string;
  public userId: string;
  public orgId: string;
  public myPhoneNumber: string;
  public emergencyContacts: string[];

  static uniq(v: string, i: number, s: string[]): boolean {
    return s.indexOf(v) === i;
  }

  constructor(
    private messagesService: MessagesService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<InternalApplicationDialogComponent>,
    private readonly geolocation$: GeolocationService,
    private appInitService: AppInitService,
    @Inject(MAT_DIALOG_DATA) public data: ResourceDialogData,
    @Inject(WA_GEOLOCATION_SUPPORT) private readonly geolocationSupport: boolean
  ) {}

  ngOnInit(): void {
    this.userName = localStorage.getItem('user_name');
    this.userId = localStorage.getItem('user_id');
    this.orgId = localStorage.getItem('org_id');
    this.myPhoneNumber = localStorage.getItem('myPhoneNumber');
    this.emergencyContacts = JSON.parse(localStorage.getItem('emergencyContacts'));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeDialog(): void {
    this.dialogRef.close('Launched');
  }

  templateExpand(message: string, variables: object): string {
    return message.replace(/\${(.*?)}/g, (_, g) => variables[g]);
  }

  sendMessage(position: GeolocationPosition, known: boolean, message: string, name: string): void {
    if (!known) {
      // We have a fake location, remove a link if present
      const os = 'https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}';
      message = message.replace(os, 'NO LOCATION AVAIL');
    }
    const expMessage = this.templateExpand(message, {
      user: this.userName,
      myPhoneNumber: this.myPhoneNumber,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });

    const phoneNumbers = this.emergencyContacts.filter(InternalApplicationDialogComponent.uniq);

    const r: CreateUserMessageRequestParams = {
      user_id: this.userId,
      message_endpoint_type: 'sms',
      Message: {
        title: name,
        text: expMessage,
      },
    };

    for (const phoneNumber of phoneNumbers) {
      r.address = phoneNumber;
      this.messagesService.createUserMessage(r).subscribe((x) => {
        // no action
      });
    }
    this.notificationService.success('SENT EMERGENCY ALERT TO ' + phoneNumbers);
  }

  requestAssistance(): void {
    let message = `ALERT: \${user}\n\${myPhoneNumber}\nhttps://www.google.com/maps/search/?api=1&query=\${latitude},\${longitude}\n`;
    const ia = this.appInitService.environment.internalApplications.alerts;
    for (const alert of ia.alertTypes) {
      if (alert.id === this.data.resourceInfo.id) {
        message = alert.message;
        break;
      }
    }

    if (this.geolocationSupport) {
      this.geolocation$.pipe(take(1)).subscribe(
        (position) => this.sendMessage(position, true, message, this.data.resourceInfo.name),
        (err) => {
          this.notificationService.warn('WARNING: YOU HAVE NOT ALLOWED LOCATION SUPPORT');
          const x: GeolocationPosition = {
            coords: {
              latitude: 0,
              longitude: 0,
              accuracy: 0,
              altitude: 0,
              altitudeAccuracy: 0,
              heading: 0,
              speed: 0,
            },
            timestamp: 0,
          };
          this.sendMessage(x, false, message, this.data.resourceInfo.name);
        }
      );
    } else {
      // Hmm, no location
      this.notificationService.warn('WARNING: YOU HAVE DISABLED LOCATION SUPPORT');
      const x: GeolocationPosition = {
        coords: {
          latitude: 0,
          longitude: 0,
          accuracy: 0,
          altitude: 0,
          altitudeAccuracy: 0,
          heading: 0,
          speed: 0,
        },
        timestamp: 0,
      };
      this.sendMessage(x, false, message, this.data.resourceInfo.name);
    }
    this.dialogRef.close();
  }
}
