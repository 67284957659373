import { AuthzErrorInterceptor } from '@agilicus/angular';
import { HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth-service/auth-service.service';

@Injectable()
export class AuthzErrorService extends AuthzErrorInterceptor implements HttpInterceptor {
  constructor(authService: AuthService) {
    super(authService.getAuth(), onLogout);
  }
}

function onLogout() {
  console.log('logging user out due to invalid session');
  window.location.reload();
}
