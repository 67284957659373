<h2 mat-dialog-title>Desktop Configuration</h2>
<div mat-dialog-content class="main-content rdp-options-config">
    <mat-tab-group (selectedTabChange)="onTabSelectionChanged($event)" [animationDuration]="0">
        <mat-tab label="Edit Form" id="tab1">
            <form class="rdp-options-form" [formGroup]="rdpForm">

                <label id="microphone-redirection-radio-group-label">Microphone Redirection</label>
                <mat-radio-group aria-labelledby="microphoneRedirection-radio-group-label" class="rdp-radio-group" formControlName="microphoneRedirection" ngDefaultControl>
                    <mat-radio-button class="rdp-radio-button" [value]="null">Unset</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="1">On</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="0">Off</mat-radio-button>
                </mat-radio-group>
            
                <label id="audio-output-location-radio-group-label">Audio output location:</label>
                <mat-radio-group aria-labelledby="audio-output-location-radio-group-label" class="rdp-radio-group"  formControlName="audioOutputLocation" ngDefaultControl>
                    <mat-radio-button class="rdp-radio-button" [value]="null">Unset</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="0">Local</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="1">Remote</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="2">Off</mat-radio-button>
                </mat-radio-group>
            
                <label id="clipboard-redirection-radio-group-label">Clipboard redirection:</label>
                <mat-radio-group aria-labelledby="clipboard-redirection-radio-group-label" class="rdp-radio-group"  formControlName="clipboardRedirection" ngDefaultControl>
                    <mat-radio-button class="rdp-radio-button" [value]="null">Unset</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="1">On</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="0">Off</mat-radio-button>
                </mat-radio-group>
            
                <label id="usb-device-redirection-radio-group-label">USB device redirection:</label>
                <mat-radio-group aria-labelledby="usb-device-redirection-radio-group-label" class="rdp-radio-group"  formControlName="usbDeviceRedirection" ngDefaultControl>
                    <mat-radio-button class="rdp-radio-button" [value]="null">Unset</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="*">On</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="">Off</mat-radio-button>
                </mat-radio-group>
        
                <label id="multiple-displays-radio-group-label4">Multiple Displays:</label>
                <mat-radio-group aria-labelledby="multiple-displays-radio-group-label" class="rdp-radio-group"  formControlName="multipleDisplays" ngDefaultControl>
                    <mat-radio-button class="rdp-radio-button" [value]="null">Unset</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="1">On</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="0">Off</mat-radio-button>
                </mat-radio-group>
                
                <label id="screen-mode-radio-group-label">Screen Mode:</label>
                <mat-radio-group aria-labelledby="screen-mode-radio-group-label" class="rdp-radio-group"  formControlName="screenMode" ngDefaultControl>
                    <mat-radio-button class="rdp-radio-button" [value]="null">Unset</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="1">Window</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="2">Full</mat-radio-button>
                </mat-radio-group>
                
                <label id="dynamic-resolution-radio-group-label">Dynamic Resolution:</label>
                <mat-radio-group aria-labelledby="dynamic-resolution-radio-group-label" class="rdp-radio-group"  formControlName="dynamicResolution" ngDefaultControl>
                    <mat-radio-button class="rdp-radio-button" [value]="null">Unset</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="1">On</mat-radio-button>
                    <mat-radio-button class="rdp-radio-button" value="0">Off</mat-radio-button>
                </mat-radio-group>
        
                <label>Desktop Dimensions:</label>
        
                <mat-form-field style="margin-top: 10px;" class="rdp-input-form-field">
                    <mat-label>Height</mat-label>
                    <input matInput type="number" formControlName="desktopHeight" placeholder="Height" min="200" max="8192">
                </mat-form-field>
        
                <mat-form-field class="rdp-input-form-field">
                    <mat-label>Width</mat-label>
                    <input matInput type="number" formControlName="desktopWidth" placeholder="Width" min="200" max="8192">
                </mat-form-field>
        
            </form>
        </mat-tab>
        <mat-tab [disabled]="true" label="Upload File" id="tab2">
            <p>Please click this button to download the existing configuration. Edit the file, then upload it to make changes.</p>
            <button
                mat-raised-button
                color="primary"
                class="download-button"
                (click)="downloadMetadata()"
                >
                DOWNLOAD DESKTOP CONFIG  <mat-icon class="download-icon">cloud_download</mat-icon>
            </button>

            <div class="upload-button-wrapper">
                <button
                  mat-raised-button
                  color="primary"
                  class="upload-button"
                  [disabled]="isUploading"
                >
                  UPLOAD DESKTOP METADATA <mat-icon class="upload-icon">cloud_upload</mat-icon>
                </button>
                <input
                  type="file"
                  accept="application/json"
                  name="upload-file"
                  id="input-file"
                  title=" "
                  (change)="onReadFile($event)"
                  aria-label="Input that uploads a file"
                  [disabled]="isUploading"
                  portalDisableKeyTab
                />
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<div *ngIf="selectedTabIndex === 0" mat-dialog-actions align="end">
  <button
    type="button"
    mat-raised-button
    color="warn"
    [mat-dialog-close]="undefined"
  >
   CANCEL
  </button>
  <button
    type="button"
    mat-raised-button
    color="primary"
    mat-dialog-close="true"
    (click)="onSubmit()"
    [disabled]="!rdpForm.dirty"
  >
   SAVE
  </button>
</div>