import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MfaEnrollComponent } from './mfa-enroll/mfa-enroll.component';
import { AppLauncherComponent } from './app-launcher/app-launcher.component';
import { FileManangerComponent } from './file-mananger/file-mananger.component';
import { SubmenuComponent } from './submenu/submenu/submenu.component';
import { LauncherInstallComponent } from './launcher-install/launcher-install.component';
import { VncDesktopComponent } from './vnc-desktop/vnc-desktop.component';
import { WebSshTabViewComponent } from './web-ssh-tab-view/web-ssh-tab-view.component';
import { LauncherMfaChallengeComponent } from './launcher-mfa-challenge/launcher-mfa-challenge.component';
import { BrowserMfaChallengeComponent } from './browser-mfa-challenge/browser-mfa-challenge.component';
import { WebPushMfaChallengeComponent } from './webpush-mfa-challenge/webpush-mfa-challenge.component';

const routes: Routes = [
  { path: 'applications', component: AppLauncherComponent },
  { path: 'mfa-enroll', component: MfaEnrollComponent },
  { path: 'http-webauthn-registration-complete', component: MfaEnrollComponent, data: { http_registration: true } },
  { path: 'http-webauthn-challenge-complete', component: MfaEnrollComponent, data: { http_challenge: true } },
  { path: 'launcher', component: LauncherInstallComponent },
  { path: 'file-manager', component: FileManangerComponent },
  { path: 'sub', component: SubmenuComponent },
  { path: '', redirectTo: '/applications', pathMatch: 'full' },
  { path: 'logout-bounce', redirectTo: '/applications?logout=true', pathMatch: 'full' },
  {
    path: 'shares/:shareId/file-manager',
    component: FileManangerComponent,
  },
  {
    path: 'vnc-desktop/:desktopId',
    component: VncDesktopComponent,
  },
  {
    path: 'web-ssh/:sshId',
    component: WebSshTabViewComponent,
  },
  {
    path: 'handle_launcher_mfa',
    component: LauncherMfaChallengeComponent,
  },
  {
    path: 'handle_browser_mfa',
    component: BrowserMfaChallengeComponent,
  },
  {
    path: 'handle_mfa_webpush',
    component: WebPushMfaChallengeComponent,
  },
  {
    path: '**',
    redirectTo: '/applications',
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
