import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UsersService,
  ListUserResourceAccessInfoRequestParams,
  UserSSHAccessInfoStatus,
  ListUserSSHAccessInfoResponse,
  UserSSHAccessInfo,
  TokensService,
} from '@agilicus/angular';
import { AuthService } from '@app/services/auth-service/auth-service.service';
import { map, concatMap, Subject, Observable, of, forkJoin, EMPTY, tap, catchError } from 'rxjs';
import { ResourceType } from '../resource-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { NgTerminal } from 'ng-terminal';
import { Terminal } from 'xterm';
import { WebLinksAddon } from 'xterm-addon-web-links';
import { AttachAddon } from 'xterm-addon-attach';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { SshCredDialogComponent } from '@app/ssh-cred-dialog/ssh-cred-dialog.component';
import { getDefaultDialogConfig } from '@app/dialog-utils';
import { NotificationService } from '@app/notifications/notification.service';
import { baseTheme, getPath, authenticate$, AuthResult, updateSshResourceAccess, performMFA } from '@app/web-ssh-utils';
import { Title } from '@angular/platform-browser';
import { CredData, SSHInfo, SshInfoService } from '@app/services/ssh-info/ssh-info.service';
import { ResourceAccess } from '@app/resource-access';
import { MakeResourceAccessFactory, ResourceAccessFactory } from '@app/resource-launcher/resource-access-factory';

@Component({
  selector: 'app-web-ssh-tab-view',
  templateUrl: './web-ssh-tab-view.component.html',
  styleUrls: ['./web-ssh-tab-view.component.scss'],
})
export class WebSshTabViewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private sshInfo: UserSSHAccessInfoStatus;
  private sshId: string;
  private socket: WebSocket;
  private underlying: Terminal;
  private messageCount = 0;
  private accessFactory: ResourceAccessFactory;

  @ViewChild('term', { static: false }) public child: NgTerminal;

  constructor(
    private authService: AuthService,
    private routeParams: ActivatedRoute,
    private users: UsersService,
    private router: Router,
    private http: HttpClient,
    private sshCredDialog: MatDialog,
    private notificationService: NotificationService,
    private titleService: Title,
    private sshInfoService: SshInfoService,
    private tokensService: TokensService,
    private challengeDialog: MatDialog
  ) {
    if (this.routeParams.params) {
      this.routeParams.params.subscribe((params) => {
        this.sshId = params['sshId'];
      });
      this.accessFactory = MakeResourceAccessFactory();
    }

    this.titleService.setTitle('SSH');
  }

  public ngOnInit(): void {
    if (!this.sshId) {
      this.router.navigate(['']);
    }
    this.startSSH();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private startSSH(credData?: CredData): void {
    let info: SSHInfo;
    this.getSshInformation$()
      .pipe(
        concatMap((info) => {
          if (!info) {
            this.notificationService.error(`SSH ${this.sshId} not found`);
            this.disconnectSsh();
            return EMPTY;
          }
          return this.sshInfoService.getSshInformation$(info, credData);
        }),
        concatMap((receivedInfo) => {
          info = receivedInfo;
          if (!info) {
            this.disconnectSsh();
            return EMPTY;
          }
          return of(info);
        }),
        concatMap((info: SSHInfo) => {
          const username = this.authService.getAuth().email();
          return forkJoin([of(info), authenticate$(info.apiKey, info.info, username, info.credData, this.http)]);
        }),
        tap(([info, authResult]: [SSHInfo, AuthResult]) => {
          if (authResult.id) {
            this.startSsh(authResult.id, info.apiKey);
          } else {
            this.openWebSshReconnectDialog(authResult.status);
          }
        }),
        catchError((error) => {
          if (error.status === 401) {
            const authResult = error.error;
            if (authResult.do_mfa) {
              performMFA(info.apiKey, this.authService.getAuth().email(), this.tokensService, this.challengeDialog).subscribe(() => {
                this.startSSH(info.credData);
              });
            }
          }
          return EMPTY;
        })
      )
      .subscribe();
  }

  private openWebSshReconnectDialog(status?: string): void {
    const data = {
      resourceInfo: 'SSH',
      dialogType: 'reconnect',
      status: status,
    };
    const dialogRef = this.sshCredDialog.open(
      SshCredDialogComponent,
      getDefaultDialogConfig({
        data,
        disableClose: true,
      })
    );

    dialogRef.afterClosed().subscribe((status: string) => {
      if (status == 'cancelled') {
        this.disconnectSsh();
      } else {
        window.location.reload();
      }
    });
  }

  private getSshInformation$(): Observable<ResourceAccess | undefined> {
    const userId = localStorage.getItem('user_id') || '';
    const orgId = localStorage.getItem('org_id') || '';
    const params: ListUserResourceAccessInfoRequestParams = {
      user_id: userId,
      org_id: orgId,
      resource_type: ResourceType.ssh,
    };

    return this.users.listSshAccessInfo(params).pipe(
      map((info) => {
        const sshInfo = this.filterSsh(info);
        if (!sshInfo) {
          return undefined;
        }

        const updatedResourceAccess = updateSshResourceAccess(this.accessFactory.makeResourceAccess(sshInfo), sshInfo);
        return updatedResourceAccess;
      })
    );
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    if (this.child && this.socket) {
      this.socket.send(JSON.stringify({ resize: [this.underlying.cols, this.underlying.rows] }));
    }
  }

  private startSsh(id: string, apiKey: string) {
    this.underlying = this.child.underlying;
    const auth = this.authService.getAuth();
    this.socket = new WebSocket(getPath(id, apiKey, this.sshInfo.gateway_uri, auth.email()));
    let attachAddon = new AttachAddon(this.socket, { bidirectional: true });
    this.underlying.loadAddon(attachAddon);

    this.socket.onmessage = (msg) => {
      if (this.messageCount < 1 && this.child) {
        this.socket.send(JSON.stringify({ resize: [this.underlying.cols, this.underlying.rows] }));
        const xtermTextarea = document.getElementsByClassName('xterm-helper-textarea')[0];
        xtermTextarea.setAttribute('autocomplete', 'new-username');
        xtermTextarea.setAttribute('type', 'password');
        xtermTextarea.setAttribute('autocorrect', 'off');
        xtermTextarea.setAttribute('autocapitalize', 'off');
        xtermTextarea.setAttribute('spellcheck', 'false');
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
          this.notificationService.error('WARNING: Please disable Predictive text setting on your android phone to avoid keyboard errors.');
      }
      this.messageCount++;
    };

    this.socket.onclose = (event) => {
      if (event.wasClean) {
        this.openWebSshReconnectDialog(event.reason);
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        this.openWebSshReconnectDialog('[close] Connection died');
      }
    };

    this.socket.onerror = (error) => {
      this.openWebSshReconnectDialog('ERROR: ' + error);
    };

    this.underlying.options.fontSize = 20;
    this.underlying.loadAddon(new WebLinksAddon());
    this.child.setXtermOptions({
      fontFamily: '"Cascadia Code", Menlo, monospace',
      theme: baseTheme,
      cursorBlink: false,
    });
    this.child.onData().subscribe((input) => {
      this.socket.send(JSON.stringify({ data: input }));
    });
  }

  private filterSsh(info: ListUserSSHAccessInfoResponse): UserSSHAccessInfo | undefined {
    for (let ssh of info.user_ssh_access_info) {
      if (ssh.status.resource_id === this.sshId) {
        this.sshInfo = ssh.status;
        this.titleService.setTitle('SSH - ' + this.sshInfo.resource_name);
        return ssh;
      }
    }
    return undefined;
  }

  private disconnectSsh(): void {
    this.router.navigate(['']);
  }
}
