import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ResourceDialogData } from '../resource-dialog-data';

@Component({
  selector: 'app-request-dialog',
  templateUrl: 'app-request-dialog.component.html',
  styleUrls: ['./app-request-dialog.component.scss'],
})
export class AppRequestDialogComponent {
  public detailsFormControl = new UntypedFormControl('', [Validators.required]);
  constructor(public dialogRef: MatDialogRef<AppRequestDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ResourceDialogData) {}
}
