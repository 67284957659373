import packageInfo from '../../package.json';

export const environment = {
  production: true,
  issuer: undefined,
  client_id: 'profile',
  apiDomain: 'https://api.agilicus.com',
  tawk_key: 'f798a2dfc6b846a7a84df42c87c1f944cce3f82f',
  versions: {
    app: packageInfo.version,
  },
  internalApplications: {
    alerts: {
      enabled: false,
    },
  },
};
