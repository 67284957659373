import {
  APIKey,
  Challenge,
  ChallengeStatus,
  ChallengesService,
  CreateApiKeyRequestParams,
  CreateChallengeRequestParams,
  GetChallengeRequestParams,
  TokensService,
  User,
} from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Observable, catchError, concatMap, delay, expand, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AuthService } from './services/auth-service/auth-service.service';
import { addSecondsToDate } from './date-utils';
import { downloadToBrowser } from '@app/browser-utils';
import { MountService } from './services/mount.service';
import { NotificationService } from './notifications/notification.service';
import { LauncherInstructionsDialogData } from './launcher-instructions-dialog/launcher-instructions-dialog.component';

interface ChallengeDetails {
  challengeID: string;
  challengeCode: string;
  failed?: boolean;
}

interface DownloadInfo {
  failed?: boolean;
  challengeID?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PollingSubscriptionService {
  public user$: Observable<User> | undefined = undefined;
  public installScopes = [
    'urn:agilicus:api:users:self',
    'urn:agilicus:api:traffic-tokens:user',
    'urn:agilicus:app:?',
    'urn:agilicus:api:resources:owner?',
    'urn:agilicus:application_service::owner?',
    'urn:agilicus:ssh:*:owner?',
    'urn:agilicus:api:applications:reader?',
    'urn:agilicus:api:applications:self?',
    'urn:agilicus:api:applications:owner?',
    'urn:agilicus:token_payload:multiorg:true',
  ];
  private apiKeyDurationSeconds = 20 * 60;
  public output_filename: string;
  private launcher_url: string;
  private challengePollPeriodMilliseconds = 5000;
  public macInstallCode = '';

  constructor(
    private authService: AuthService,
    private tokens: TokensService,
    private challenges: ChallengesService,
    private mountService: MountService,
    private notificationService: NotificationService
  ) {
    this.user$ = this.authService.getAuth().user$();
  }

  public downloadFile$(data: LauncherInstructionsDialogData): Observable<ChallengeStatus.StateEnum> {
    return this.buildBootstrapChallenge$(data).pipe(
      switchMap((details: ChallengeDetails) => {
        this.output_filename = this.getOutputFilename(details, data);
        this.launcher_url = this.getLauncherUrl(data);
        downloadToBrowser(this.launcher_url, this.output_filename);
        return this.pollForChallengeCompletion$(this.challengePollPeriodMilliseconds, details);
      }),
      tap((state: ChallengeStatus.StateEnum) => {
        this.checkChallengeState(state);
      }),
      take(1)
    );
  }

  public onCopyToClipboard$(data: LauncherInstructionsDialogData): Observable<ChallengeStatus.StateEnum> {
    return this.buildBootstrapChallenge$(data).pipe(
      switchMap((details: ChallengeDetails) => {
        this.output_filename = this.getOutputFilename(details, data);
        this.macInstallCode = this.getChallengeInfoString(details);
        this.launcher_url = this.getLauncherUrl(data);
        navigator.clipboard
          .writeText(this.macInstallCode)
          .catch((error) => {
            console.log('ERROR: cannot write value ', this.macInstallCode, ' to clipboard: ', error);
            this.notificationService.error(`ERROR: cannot write value to clipboard: ${error}`);
          })
          .then(() => {
            this.notificationService.success('Copied to clipboard!');
          });
        return this.pollForChallengeCompletion$(this.challengePollPeriodMilliseconds, details);
      }),
      tap((state: ChallengeStatus.StateEnum) => {
        this.checkChallengeState(state);
      }),
      take(1)
    );
  }

  private pollForChallengeCompletion$(delayMilliseconds: number, details: ChallengeDetails): Observable<ChallengeStatus.StateEnum> {
    return of(undefined).pipe(
      expand((challengeState?: ChallengeStatus.StateEnum) => {
        if (this.challengeComplete(challengeState)) {
          return of(challengeState);
        }
        return this.getChallengeState$({
          failed: details.failed,
          challengeID: details.challengeID,
        }).pipe(take(1), delay(delayMilliseconds));
      }),
      filter(
        (state?: ChallengeStatus.StateEnum) =>
          state && state !== ChallengeStatus.StateEnum.issued && state !== ChallengeStatus.StateEnum.pending
      ),
      take(1)
    );
  }

  private getChallengeState$(info: DownloadInfo): Observable<ChallengeStatus.StateEnum | undefined> {
    if (!info.challengeID) {
      return of(undefined);
    }
    const params: GetChallengeRequestParams = {
      challenge_id: info.challengeID,
    };
    return this.challenges.getChallenge(params, 'body').pipe(
      catchError((err) => {
        // Just keep trying if thre's an error
        console.log('failed to fetch challenge: ', err);
        return of(undefined);
      }),
      map((challenge?: Challenge) => {
        if (!challenge) {
          return undefined;
        }
        return challenge.status.state;
      })
    );
  }

  private challengeComplete(state?: ChallengeStatus.StateEnum): boolean {
    if (!state) {
      return false;
    }

    return state !== ChallengeStatus.StateEnum.issued && state !== ChallengeStatus.StateEnum.pending;
  }

  private checkChallengeState(state: ChallengeStatus.StateEnum): void {
    if (state === ChallengeStatus.StateEnum.challenge_passed) {
      this.mountService.refreshLauncher('launcherRefreshButton', 'default');
    }
  }

  private buildBootstrapChallenge$(data: LauncherInstructionsDialogData): Observable<ChallengeDetails> {
    return this.user$.pipe(
      concatMap((user: User) => {
        return this.buildBootstrapChallengeFromUser$(user, data);
      }),
      catchError((_) => {
        const result: ChallengeDetails = {
          challengeCode: '',
          challengeID: '',
          failed: true,
        };
        return of(result);
      })
    );
  }

  private buildBootstrapChallengeFromUser$(user: User, data: LauncherInstructionsDialogData): Observable<ChallengeDetails> {
    const apiKeyReq: CreateApiKeyRequestParams = {
      APIKey: {
        spec: {
          user_id: user.id,
          org_id: user.org_id,
          scopes: this.installScopes,
          expiry: addSecondsToDate(this.apiKeyDurationSeconds, new Date()),
          label: 'agilicus-portal-connector-install',
        },
      },
    };
    return this.tokens.createApiKey(apiKeyReq).pipe(
      map((resp: APIKey) => {
        const bootstrap = {
          api_key: resp.status?.api_key,
          org_id: resp.spec.org_id,
          api_key_user: user.email,
          issuer: data.issuer,
        };

        const challengeReq: CreateChallengeRequestParams = {
          Challenge: {
            spec: {
              user_id: user.id,
              challenge_types: ['code'],
              answer_data: bootstrap,
            },
          },
        };
        return challengeReq;
      }),
      concatMap((req: CreateChallengeRequestParams) => {
        return this.challenges.createChallenge(req);
      }),
      map((challenge: Challenge) => {
        return {
          challengeID: challenge.metadata?.id,
          challengeCode: challenge.status?.code,
        };
      })
    );
  }

  private getOutputFilename(details: ChallengeDetails, data: LauncherInstructionsDialogData): string {
    return `agilicus-launcher-` + this.getChallengeInfoString(details) + data.fileExtension;
  }

  private getChallengeInfoString(details: ChallengeDetails): string {
    return btoa(`--challenge-id ${details.challengeID} --challenge-code ${details.challengeCode}`);
  }

  private getLauncherUrl(data: LauncherInstructionsDialogData): string {
    return `https://www.agilicus.com/www/releases/secure-agent/stable/${data.filename}${data.fileExtension}?file=${this.output_filename}`;
  }

  public disableDownloadButton(): boolean {
    return !this.launcher_url || !this.output_filename;
  }

  public disableMacDownloadButton(): boolean {
    return !this.output_filename || !this.macInstallCode;
  }

  public downloadFileToMac(): void {
    downloadToBrowser(this.getMacLauncherUrl(), this.output_filename);
  }

  private getMacLauncherUrl(): string {
    return 'https://agilicus.com/www/releases/secure-agent/stable/agilicus-launcher.pkg';
  }
}
