<div class="title-container">
  <!-- <h1 class="title">File Manager</h1> -->
  <mat-icon
    class="back-arrow"
    matTooltip="Return to Resources"
    (click)="returnToAllResources()"
  >
    arrow_back
  </mat-icon>
  <h1 class="title">File Manager ({{ this.currentShare?.status?.share_name ?  this.currentShare.status.share_name : ''}})</h1>
  <div
    class="button-container"
    [matTooltip]="getMountButtonTooltip()"
  >
    <button
      mat-raised-button
      color="primary"
      class="share-mount-button"
      (click)="mountDrive()"
      [disabled]="isMountDisabled()"
      *ngIf="!isAlreadyMounted"
    >
      Mount Local Desktop
    </button>
  </div>

  <button
    mat-raised-button
    color="primary"
    class="share-mount-button"
    (click)="unMountDrive()"
    [disabled]="isMountDisabled()"
    *ngIf="isAlreadyMounted"
    matTooltip="Click to unmount this share"
  >
    Unmount Local Desktop
  </button>

  <span class="share-mount-button" *ngIf="isAlreadyMounted"> ( Share is mounted in path : {{this.mountPath}} )</span>

  <div matTooltip="Click to view instructions for mounting this share" (click)="openShareMountDialog()" class="link-style">Manual Mount</div>

</div>

<div *ngFor="let shareData of shareManagerData">
  <div *ngIf="shareData.share.status.share_id === this.currentShareId">
    <div *ngIf="!shareData.webdavFileSystem" class="loading-share-info-container">
      <h2>Loading share information...</h2>
    </div>

    <div *ngIf="!!shareData.webdavFileSystem" class="file-explorer-container">
      <ngx-filemanager [config]="config" [fileSystem]="shareData.webdavFileSystem">
      </ngx-filemanager>
    </div>
  </div>
</div>