<ng-container>
  <div class="main-container flex-xl-100">
    <div class="font_preload" style="opacity: 0">
      <span style="font-family: 'Canada 1500'; font-style: 'bold';"></span>
    </div>

    <div class="main-toolbar">
      <mat-toolbar color="primary">
        <a routerLink="">
          <img class="branding" alt="logo" [src]="imagePath" appCheckImage />
        </a>

        <span class="fill-space"></span>

        <ng-container *ngIf="(user$ | async) as user; else clickToLoginCircle">
          <button
            *ngIf="!isMobileDevice()"
            mat-menu-item class="header-button refresh-button"
            type="button" 
            (click)="refreshLauncher()"
            [disabled]="!launcherVersion"
            id="launcherRefreshButton"
            matTooltip="Update Desktop Integration"
          >
            <span>Update Desktop Integration</span>
          </button>

          <button
            *ngIf="!isMobileDevice()"
            mat-menu-item
            class="header-button"
            type="button"
            routerLink="launcher" 
            matTooltip="Install Desktop Integration"
          >
            <span>Install Desktop Integration</span>
          </button>

          <app-notifications
            [user]="user"
          >
          </app-notifications>

          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            class="account-button content-page"
            aria-label="Account icon-button with a menu"
            matTooltip="{{ user.email }}"
          >
            <img class="account-icon" ngxGravatar email="{{ user.email }}" alt="user-gravatar">
          </button>
        </ng-container>

        <ng-template #clickToLoginCircle>
          <button
            style="margin-right: 0;"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            class="account-button"
            aria-label="Account icon-button with a menu"
            matTooltip="Click to login"
          >
            <mat-icon class="account-icon outside-circle">account_circle</mat-icon>
          </button>
        </ng-template>

        <mat-menu #menu="matMenu" class="account-menu">

          <ng-container *ngIf="(user$ | async) as user; else loginButton">
            <div
              class="row account-container"
              (click)="$event.stopPropagation()"
            >
              <div class="col icon-container">
                <img ngxGravatar email="{{ user.email }}" alt="user-gravatar" [size]="getGravatarSize()">
              </div>
              <div class="col profile-container">
                <span *ngIf="!!user.first_name">
                  <span>{{ user.first_name }}</span>
                  <br />
                </span>
                <span>{{ user.email }}</span>
              </div>
            </div>

            <button mat-menu-item class="account-menu-button" [routerLink]="['']"
              (click)="onUserInfoClick()">
              <span>User Info</span>
            </button>

            <button mat-menu-item class="account-menu-button" type="button" [routerLink]="[]"
              (click)="onRdpOptionsClick()">
              <span>Desktop Configuration</span>
            </button>

            <button mat-menu-item class="account-menu-button" type="button"
              (click)="onMultifactorScreenClick()">
              <span>Multifactor Authentication</span>
            </button>

            <button mat-menu-item class="account-menu-button" type="button"
              (click)="onSettingsOptionsClick()">
              <span>Settings</span>
            </button>

            <button mat-menu-item class="account-menu-button" [routerLink]="['']"
              (click)="onLogoutClick()">
              <span>Logout</span>
            </button>
          </ng-container>

          <ng-template #loginButton>
            <button mat-menu-item class="login-button" [routerLink]="[]"
              (click)="onLoginClick()">
              <span>Login</span>
            </button>
          </ng-template>

          <hr>

          <button mat-menu-item class="account-menu-button" *ngIf="currentProfileVersion !== profileVersion.ALPHA"
            routerLink="['']" routerLinkActive="active" (click)="onVerClickAlpha()">
            <span>Use Alpha Version</span>
          </button>

          <button mat-menu-item class="account-menu-button" *ngIf="currentProfileVersion !== profileVersion.BETA"
            routerLink="['']" routerLinkActive="active" (click)="onVerClickBeta()">
            <span>Use Beta Version</span>
          </button>

          <button mat-menu-item class="account-menu-button" *ngIf="currentProfileVersion !== profileVersion.STABLE"
            routerLink="['']" routerLinkActive="active" (click)="onVerClickStable()">
            <span>Use Stable Version</span>
          </button>

          <span mat-menu-item>Version: {{ version }} ({{ profileVersionString }}) </span>
        </mat-menu>
      </mat-toolbar>
    </div>

    <mat-sidenav-container class="sidenav" fixedInViewport="(isHandset$ | async)">
      <mat-sidenav-content class="page-wrap">

        <main class="content">
          <div *ngIf="showLoginHint; else elseBlock" class="welcome-container">
            <img class="bordered-img margined-img desktop-image"
              src="assets/img/applications.png"
              height=280
            >
            <div *ngIf="showUnwelcomeMessage">
              <h1>INTERNAL USE ONLY</h1>
              This specific URL is for internal-up use only.
              <p>
                If you have already gone through the sign-up process for Agilicus AnyX, you chose a custom domain name at that time.
                You will have received a personalised email with your domain name information at that time.</p>
                <br>
                In this email you will see 2 URL:
                <ol>
                  <li>https://admin.MYDOMAIN/ -- used by administrators of the system for configuration.</li>
                  <li>https://profile.MYDOMAIN/ -- used by end-users of the system for their personal profile and launchpad.</li>
                </ol>
                <p>
                If you are looking to make administrative changes to the system, refer to that email and
                nagivate to the URL as shown in it.</p><br>
                <p>
                If you cannot recall your custom domain name,
                please open the chat icon in the lower right on <a href="https://www.agilicus.com/">www.agilicus.com</a>, our
                support team would be happy to look this up for you.
                </p><br>

            </div>
            <div *ngIf="!showUnwelcomeMessage">
              <h1>Welcome!</h1>
              <p>Your account has already been created by your employer. Simply sign in with your work or personal account as directed by your employer.</p>
              <div class="login-button-container">
                <button
                  mat-raised-button
                  color="primary"
                  aria-label="Signup/Login"
                  (click)="onLoginClick()"
                >
                  SIGN IN >>
                </button>
              </div>
              <img class="bordered-img mobile-image"
                src="assets/img/applications.png"
                height=280
              >
              <p>After clicking the sign in button you will be redirected to a sign in page that allows you to select or input your work account details to gain access to your work applications.</p>
              <p>Depending on your organisation, this might include "Corporate Email" (which would be how you sign-in to other systems like Office 365 or a desktop PC), or it might include a social login ("Sign in with Google").</p>
              <p> For more details, see the <a target="_blank" href="https://www.agilicus.com/product-guide/">Product Guide</a>  </p>
            </div>
          </div>

          <ng-template #elseBlock>
            <router-outlet> </router-outlet>
          </ng-template>
        </main>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</ng-container>
