import { TokensService, UserFileShareAccessInfo, UserFileShareAccessInfoStatus, UsersService } from '@agilicus/angular';
import { FileManagerConfig, WebdavFileSystem, WebdavFileSystemFactory } from '@agilicus/ngx-filemanager-client';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getDefaultDialogConfig } from '@app/dialog-utils';
import { ResourceAccess } from '@app/resource-access';
import { ResourceDialogData } from '@app/resource-dialog-data';
import { ResourceType } from '@app/resource-type.enum';
import { AuthService } from '@app/services/auth-service/auth-service.service';
import { ShareMountInstructionsDialogComponent } from '@app/share-mount-instructions-dialog/share-mount-instructions-dialog.component';
import { GetApiKeyData, getOrgIdFromRouter, getShareAccessInfo } from '@app/utils';
import { getFileShareUrlForLink } from '@app/utils/resource.utils';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { MountService } from '@app/services/mount.service';
import { isSupportedBrowser } from '@app/browser-utils';
import { ExtensionStateService } from '@app/extension-state/extension-state.service';
import { performMFA } from '@app/web-ssh-utils';
export interface ShareManagerData {
  share: UserFileShareAccessInfo;
  webdavFileSystem: WebdavFileSystem;
}

@Component({
  selector: 'app-file-mananger',
  templateUrl: './file-mananger.component.html',
  styleUrls: ['./file-mananger.component.scss'],
})
export class FileManangerComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public config: FileManagerConfig = {
    virtualRoot: '/',
    bucketName: '',
    isAdmin: true,
    initialPath: '/',
  };
  public shareAccessInfo: Array<UserFileShareAccessInfo>;
  private routerParams$: Observable<Params>;
  private routerQueryParams$: Observable<Params>;
  public orgId: string;
  public userId: string;
  public currentShareId: string;
  public currentShare: UserFileShareAccessInfo;
  public shareManagerData: Array<ShareManagerData>;
  public launcherVersion: string | undefined | null = null;
  public isExtensionInstalledResult: boolean | undefined;
  public isAlreadyMounted: boolean = false;
  public mountPath: string;
  private currentOrgId: string;

  constructor(
    private route: ActivatedRoute,
    private users: UsersService,
    public router: Router,
    private shareMountDialog: MatDialog,
    private webdavFileSystemFactory: WebdavFileSystemFactory,
    private authService: AuthService,
    private tokensService: TokensService,
    private mountService: MountService,
    private extensionStateService: ExtensionStateService,
    private challengeDialog: MatDialog
  ) {
    this.orgId = localStorage.getItem('org_id') || '';
    this.userId = localStorage.getItem('user_id') || '';
    this.routerQueryParams$ = this.route.queryParams;
  }

  public ngOnInit(): void {
    const isExtensionInstalled$ = this.extensionStateService.getExtensionInstalledStatus();
    const launcherInfo$ = this.extensionStateService.getLauncherInfo();
    this.routerParams$ = this.route.params;
    combineLatest([
      isExtensionInstalled$,
      launcherInfo$,
      this.routerParams$.pipe(
        concatMap((routerParamsResp) => {
          this.currentShareId = routerParamsResp.shareId;
          return this.getShareAccessInfo$();
        })
      ),
      this.routerQueryParams$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([isExtensionInstalledResult, launcherInfoResult, shareAccessResp, routerQueryParamsResp]) => {
        this.currentOrgId = getOrgIdFromRouter(routerQueryParamsResp);
        this.setShareAccessInfo(shareAccessResp);
        this.setCurrentShareFromShareId();
        this.shareManagerData = this.getShareManagerData();
        this.isExtensionInstalledResult = isExtensionInstalledResult;
        this.launcherVersion = launcherInfoResult?.version;
        if (this.mountService.canUseLauncherWithExtension(this.isExtensionInstalledResult, this.launcherVersion)) {
          this.getMountInformation();
        }
      });
  }

  public returnToAllResources(): void {
    this.router.navigate(['/applications'], {
      queryParams: { org_id: this.currentOrgId },
    });
  }

  private async getMountInformation(): Promise<void> {
    const { mountPath, isAlreadyMounted } = await this.mountService.getMountStatus(this.getResourceData(), this.getResourceAccess());
    this.mountPath = mountPath;
    this.isAlreadyMounted = isAlreadyMounted;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setCurrentShareFromShareId(): void {
    for (const share of this.shareAccessInfo) {
      if (share.status.share_id === this.currentShareId) {
        this.currentShare = share;
      }
    }
  }

  private setShareAccessInfo(shareAccessResp: Array<UserFileShareAccessInfo>): void {
    if (!!shareAccessResp) {
      this.shareAccessInfo = shareAccessResp.filter(
        (share) => share.status.access_level === UserFileShareAccessInfoStatus.AccessLevelEnum.granted
      );
    }
  }

  private getShareAccessInfo$(): Observable<Array<UserFileShareAccessInfo>> {
    if (!this.shareAccessInfo) {
      return getShareAccessInfo(this.userId, this.orgId, this.users);
    } else {
      return of(this.shareAccessInfo);
    }
  }

  private getShareManagerData(): Array<ShareManagerData> {
    const shareManagerData = [];
    for (const share of this.shareAccessInfo) {
      const data: ShareManagerData = {
        share,
        webdavFileSystem: this.webdavFileSystemFactory.makeWebdavFileSystem(
          this.getFileShareUrlForLink(share),
          this.getLatestAccessToken.bind(this),
          this.handleError.bind(this)
        ),
      };
      shareManagerData.push(data);
    }
    return shareManagerData;
  }

  public openShareMountDialog(): void {
    const resource: ResourceAccess = {
      id: this.currentShare.status.share_id,
      user_id: this.currentShare.status.user_id,
      org_id: this.currentShare.status.org_id,
      org_name: this.currentShare.status.org_name,
      name: this.currentShare.status.share_name,
      access_level: this.currentShare.status.access_level,
      resource_url: this.getShareUrl(this.currentShare.status),
      resource_type: ResourceType.share,
      parent_org_id: this.currentShare.status.parent_org_id,
      parent_org_name: this.currentShare.status.parent_org_name,
      is_remote_app: false,
    };
    const data: ResourceDialogData = { resourceInfo: resource };
    const dialogRef = this.shareMountDialog.open(ShareMountInstructionsDialogComponent, getDefaultDialogConfig({ data }));
  }

  public getFileShareUrlForLink(share?: UserFileShareAccessInfo): string {
    return getFileShareUrlForLink(
      this.currentShare.status?.per_host_share_url ? this.currentShare.status.per_host_share_url : this.currentShare.status.share_url
    );
  }

  private getShareUrl(shareStatus: UserFileShareAccessInfoStatus): string {
    return shareStatus?.per_host_share_url || shareStatus.share_url;
  }

  private getLatestAccessToken(): string | undefined | null {
    const auth = this.authService.getAuth();
    if (!auth) {
      return undefined;
    }
    return auth.access_token();
  }

  private getResourceData(): GetApiKeyData {
    const resourceData: GetApiKeyData = {
      userId: this.currentShare.status.user_id,
      orgId: this.currentShare.status.org_id,
      resourceId: this.currentShare.status.share_id,
      resourceName: this.currentShare.status.share_name,
      tokensService: this.tokensService,
    };
    return resourceData;
  }

  private getResourceAccess(): ResourceAccess {
    const resource: ResourceAccess = {
      id: this.currentShare.status.share_id,
      user_id: this.currentShare.status.user_id,
      org_id: this.currentShare.status.org_id,
      org_name: this.currentShare.status.org_name,
      name: this.currentShare.status.share_name,
      access_level: this.currentShare.status.access_level,
      resource_url: this.getShareUrl(this.currentShare.status),
      resource_type: ResourceType.share,
      parent_org_id: this.currentShare.status.parent_org_id,
      parent_org_name: this.currentShare.status.parent_org_name,
      is_remote_app: false,
    };
    return resource;
  }

  public async mountDrive(): Promise<void> {
    await this.mountService.mountDrive(this.getResourceData(), this.getResourceAccess());
    this.getMountInformation();
  }

  public async unMountDrive(): Promise<void> {
    await this.mountService.unMountDrive(this.getResourceData(), this.getResourceAccess());
    this.getMountInformation();
  }

  public isMountDisabled(): boolean {
    return this.mountService.getLauncherRefreshData()
      ? !this.mountService.canUseLauncherWithExtension(this.isExtensionInstalledResult, this.launcherVersion) ||
          !this.mountService.doesSupportMount()
      : !this.mountService.canUseLauncherWithExtension(this.isExtensionInstalledResult, this.launcherVersion);
  }

  public getMountButtonTooltip(): string {
    return this.mountService.getMountTooltipMessage(this.isExtensionInstalledResult, this.launcherVersion, isSupportedBrowser());
  }

  public async handleError(event): Promise<boolean> {
    try {
      const result = await performMFA(
        this.getLatestAccessToken(),
        this.authService.getAuth().email(),
        this.tokensService,
        this.challengeDialog,
        'bearer'
      ).toPromise();
      return result !== undefined;
    } catch (err) {
      return false;
    }
  }
}
