import { CredentialsService, ListObjectCredentialExistenceInfoRequestParams, TokensService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Observable, catchError, concatMap, map, of } from 'rxjs';
import { ResourceType } from '../../resource-type.enum';
import { getApiKey } from '../../utils';
import { getFutureDateFromSeconds } from '../../date-utils';
import { MatDialog } from '@angular/material/dialog';
import { ResourceAccess } from '../../resource-access';
import { ResourceDialogData } from '../../resource-dialog-data';
import { SshCredDialogComponent } from '../../ssh-cred-dialog/ssh-cred-dialog.component';
import { getDefaultDialogConfig } from '../../dialog-utils';
import { NotificationService } from '../../notifications/notification.service';

export interface CredData {
  provideCredentials?: ProvidedCredentials;
  shouldStuff: boolean;
}

export interface ProvidedCredentials {
  username: string;
  password: string;
}

export interface SSHResourceInfo {
  resource_url: string;
  name: string;
  gateway_uri: string;
  resourceId: string;
  orgId: string;
}

export interface SSHInfo {
  apiKey: string;
  info: SSHResourceInfo;
  credData: CredData;
}
@Injectable({
  providedIn: 'root',
})
export class SshInfoService {
  constructor(
    private credentialsService: CredentialsService,
    private tokensService: TokensService,
    private sshCredDialog: MatDialog,
    private notificationService: NotificationService
  ) {}

  public getSshInformation$(accessInfo: ResourceAccess, credData?: CredData): Observable<SSHInfo | undefined> {
    let credsObservable: Observable<CredData | undefined>;
    if (credData) {
      credsObservable = of(credData);
    } else {
      credsObservable = this.getShouldStuffCreds$(accessInfo.id, accessInfo.org_id).pipe(
        concatMap((shouldStuff: boolean) => {
          if (shouldStuff) {
            const creds: CredData = {
              shouldStuff: true,
            };
            return of(creds);
          }
          return this.getCredsFromUser$(accessInfo);
        })
      );
    }

    return credsObservable.pipe(
      concatMap((creds: CredData | undefined) => {
        if (!creds) {
          return of(undefined);
        }
        return this.getSshApiKey$(accessInfo).pipe(
          map((apiKey: string | undefined) => {
            if (!apiKey) {
              return undefined;
            }

            const result: SSHInfo = {
              info: {
                resource_url: accessInfo.resource_url,
                name: accessInfo.name,
                gateway_uri: accessInfo.gateway_uri,
                resourceId: accessInfo.id,
                orgId: accessInfo.org_id,
              },
              apiKey: apiKey,
              credData: creds,
            };
            return result;
          })
        );
      }),
      catchError((err) => {
        this.notificationService.error('Error retrieving SSH information for ' + accessInfo.name + '". ' + err.message);
        return of(undefined);
      })
    );
  }

  private getSshApiKey$(info: ResourceAccess): Observable<string> {
    return getApiKey({
      userId: info.user_id,
      orgId: info.org_id,
      resourceId: info.id,
      resourceName: info.name,
      resourceType: info.resource_type,
      tokensService: this.tokensService,
      expiry: getFutureDateFromSeconds(600),
    });
  }

  private getShouldStuffCreds$(resourceId: string, orgId: string): Observable<boolean> {
    const params: ListObjectCredentialExistenceInfoRequestParams = {
      org_id: orgId,
      object_id: resourceId,
      object_type: ResourceType.ssh,
      purpose: 'stuffing',
    };

    return this.credentialsService
      .listObjectCredentialExistenceInfo(params)
      .pipe(map((result) => result.object_credential_existence_info.length > 0));
  }

  private getCredsFromUser$(resourceInfo: ResourceAccess, status?: string): Observable<CredData | undefined> {
    const data: ResourceDialogData = {
      resourceInfo: resourceInfo,
      dialogType: 'cred',
    };
    const dialogRef = this.sshCredDialog.open(
      SshCredDialogComponent,
      getDefaultDialogConfig({
        data,
        disableClose: true,
      })
    );

    return dialogRef.afterClosed().pipe(
      map((dialogResult: string | ProvidedCredentials) => {
        const credData = dialogResult as ProvidedCredentials;
        if (!credData.username) {
          return undefined;
        }
        const result: CredData = {
          provideCredentials: credData,
          shouldStuff: false,
        };
        return result;
      })
    );
  }
}
