import { Challenge, SessionChallengeStatus, WebAuthNEnrollment } from '@agilicus/angular';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { getDefaultMfaChallengeDialogConfig } from '@app/dialog-utils';
import { MfaChallengeDialogComponent, MFAChallengeDialogData } from '@app/mfa-challenge-dialog/mfa-challenge-dialog.component';
import { Observable, catchError, concatMap, delay, expand, map, of, timeout, timer } from 'rxjs';
import { convertFragmentToChallenge } from '@app/utils/challenge.utils';

interface State {
  challengeSuccess?: boolean;
  error?: string;
  timeout?: number;
}

const maxWait = 5;

@Component({
  selector: 'app-launcher-mfa-challenge',
  templateUrl: './launcher-mfa-challenge.component.html',
  styleUrls: ['./launcher-mfa-challenge.component.scss'],
})
export class LauncherMfaChallengeComponent implements OnInit {
  public state$: Observable<State>;
  public challenge: Challenge;
  public sessionChallengeStatus: SessionChallengeStatus;
  public challengeDescription: string;
  private webauthnEnrollmentList: Array<WebAuthNEnrollment> | undefined = [];

  constructor(private route: ActivatedRoute, private challengeDialog: MatDialog) {}

  public ngOnInit(): void {
    this.state$ = this.route.fragment.pipe(
      concatMap((fragment: string) => {
        const jsonChallenge = convertFragmentToChallenge(fragment);
        this.sessionChallengeStatus = JSON.parse(jsonChallenge);
        this.challenge = this.sessionChallengeStatus.challenge;
        this.webauthnEnrollmentList = this.sessionChallengeStatus.webauthn_enrollments;
        this.challengeDescription = this.sessionChallengeStatus?.description ? this.sessionChallengeStatus.description : undefined;
        if (!this.challenge?.metadata?.id) {
          throw new Error('challenge missing id');
        }
        if (!this.challenge.spec) {
          throw new Error('challenge missing spec');
        }
        if (!this.challenge.status) {
          throw new Error('challenge missing status');
        }

        const data: MFAChallengeDialogData = {
          challenge: this.challenge,
          webauthnEnrollmentList: this.webauthnEnrollmentList,
          challengeDescription: this.challengeDescription,
        };
        const dialogRef = this.challengeDialog.open(
          MfaChallengeDialogComponent,
          getDefaultMfaChallengeDialogConfig({
            data,
          })
        );
        return dialogRef.afterClosed();
      }),
      map((status: string) => {
        if (status === 'success') {
          window.close();
        }
        return {
          challengeSuccess: status === 'success',
        };
      }),
      concatMap((result: State) => {
        if (result.challengeSuccess) {
          return of(result);
        }
        result.timeout = maxWait;
        return of(result).pipe(
          expand((result) => {
            if (result.timeout <= 0) {
              window.close();
            }
            const returnResult = {
              ...result,
              timeout: result.timeout - 1,
            };
            return of(returnResult).pipe(delay(result.timeout === maxWait ? 0 : 1000));
          })
        );
      }),
      catchError((err) => {
        return of({
          error: err.toString() as string,
        });
      })
    );
  }
}
