import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { UsersService, UserMetadata, UserMetadataSpec } from '@agilicus/angular';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NotificationService } from '@app/notifications/notification.service';
import { Validators } from '@angular/forms';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneNumberValidator } from '@app/validators';
import { createUserMetadata$, getUserMetadata$, updateUserMetadata$ } from '@app/utils/user-metadata.utils';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export interface ContactInfo {
  metadata_id: string;
  myPhoneNumber: string;
  myEmergencyContact: string;
  emergencyContacts: string[];
}

@Component({
  selector: 'app-emergency-contact-info',
  templateUrl: './emergency-contact-info.component.html',
  styleUrls: ['./emergency-contact-info.component.scss'],
})
export class EmergencyContactInfoComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private userId: string;
  private orgId: string;
  private metadataId: string;
  public contactForm = new UntypedFormGroup({
    myPhoneNumber: new UntypedFormControl('', [Validators.required, PhoneNumberValidator('CA')]),
    myEmergencyContact: new UntypedFormControl('', [Validators.required, PhoneNumberValidator('CA')]),
  });
  private userMetadata: UserMetadata;

  constructor(
    private users: UsersService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<EmergencyContactInfoComponent>
  ) {
    this.userId = data.userId;
    this.orgId = data.orgId;
    this.userMetadata = {
      spec: {
        user_id: data.userId,
        org_id: data.orgId,
        data_type: UserMetadataSpec.DataTypeEnum.json,
        app_id: 'internalalerts',
        data: '{}',
      },
    };
  }

  public contactInfo$: Observable<object>;

  public static fixNumbers(data: UserMetadata): ContactInfo {
    const ci: ContactInfo = {
      metadata_id: data.metadata.id,
      myPhoneNumber: '',
      myEmergencyContact: '',
      emergencyContacts: [],
    };
    const ecs: string[] = [];
    const json = JSON.parse(data.spec.data);
    if (json && 'myPhoneNumber' in json) {
      ci.myPhoneNumber = phoneNumberUtil.format(phoneNumberUtil.parseAndKeepRawInput(json.myPhoneNumber, 'CA'), PhoneNumberFormat.E164);
      localStorage.setItem('myPhoneNumber', ci.myPhoneNumber);
    }
    if (json && 'myEmergencyContact' in json) {
      ci.myEmergencyContact = phoneNumberUtil.format(
        phoneNumberUtil.parseAndKeepRawInput(json.myEmergencyContact, 'CA'),
        PhoneNumberFormat.E164
      );
      ecs.push(ci.myEmergencyContact);
    }
    if (json && 'emergencyContacts' in json) {
      for (const ec of json.emergencyContacts) {
        ecs.push(phoneNumberUtil.format(phoneNumberUtil.parseAndKeepRawInput(ec, 'CA'), PhoneNumberFormat.E164));
      }
    }
    ci.emergencyContacts = ecs;
    return ci;
  }

  public static init(users: UsersService, userId: string, orgId: string): void {
    getUserMetadata$(users, userId, orgId, { app_id: 'internalalerts', recursive: true }).subscribe((resp) => {
      if (resp.length) {
        const ci = EmergencyContactInfoComponent.fixNumbers(resp[0]);
        localStorage.setItem('myPhoneNumber', ci.myPhoneNumber);
        localStorage.setItem('emergencyContacts', JSON.stringify(ci.emergencyContacts));
      }
    });
  }

  private fetchData(): void {
    getUserMetadata$(this.users, this.userId, this.orgId, { app_id: 'internalalerts', recursive: false })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp.length) {
          const ci = EmergencyContactInfoComponent.fixNumbers(resp[0]);
          this.metadataId = ci.metadata_id;
          this.contactForm.setValue({
            myPhoneNumber: ci.myPhoneNumber,
            myEmergencyContact: ci.myEmergencyContact,
          });
          localStorage.setItem('myPhoneNumber', ci.myPhoneNumber);
        }
      });

    getUserMetadata$(this.users, this.userId, this.orgId, { app_id: 'internalalerts', recursive: true })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp.length) {
          const ci = EmergencyContactInfoComponent.fixNumbers(resp[0]);
          this.contactForm.setValue({
            myPhoneNumber: ci.myPhoneNumber,
            myEmergencyContact: ci.myEmergencyContact,
          });
          localStorage.setItem('myPhoneNumber', ci.myPhoneNumber);
          localStorage.setItem('emergencyContacts', JSON.stringify(ci.emergencyContacts));
        }
      });
  }

  public ngOnInit(): void {
    this.fetchData();
  }

  public onSubmit(): void {
    // If it already exists we must use PUT.
    const val = this.contactForm.getRawValue();
    const myEC = phoneNumberUtil.format(phoneNumberUtil.parseAndKeepRawInput(val.myEmergencyContact, 'CA'), PhoneNumberFormat.E164);
    const contactInfo = {
      myPhoneNumber: phoneNumberUtil.format(phoneNumberUtil.parseAndKeepRawInput(val.myPhoneNumber, 'CA'), PhoneNumberFormat.E164),
      myEmergencyContact: myEC,
      emergencyContacts: [myEC],
    };
    localStorage.setItem('myPhoneNumber', contactInfo.myPhoneNumber);
    this.userMetadata.spec.data = JSON.stringify(contactInfo);

    if (this.metadataId) {
      updateUserMetadata$(this.users, this.metadataId, this.userMetadata)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            // No action
          },
          (err) => {
            this.notificationService.warn('ERROR: Unable to update emergency contact info');
          }
        );
    } else {
      createUserMetadata$(this.users, this.userMetadata)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {},
          (err) => {
            if (err.status === 409) {
              // This is a race condition. Another actor must
              // have created the entry. On init of this component
              // there was 0 metadata entries. But now there is.
              // We have 2 choices: find the metadata_id,
              // and then replace it (since we must have been later)
              // or ignore the error (since the other actor put
              // some value in). Use the latter strategy, warn
              // user, but drop their changes.
              this.notificationService.warn('WARNING: another instance updated contact info in parallel');
              this.fetchData();
            }
          }
        );
    }
    this.closeDialog();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
