import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  userId: string;
  orgId: string;
  idToken: string;

  public getIdToken(): object {
    if (this.idToken) {
      return jwt_decode(this.idToken);
    } else {
      return {};
    }
  }
  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<UserInfoComponent>) {
    this.userId = data.userId;
    this.orgId = data.orgId;
    this.idToken = data.idToken;
    console.log(this.idToken);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}
}
